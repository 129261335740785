/* -----------Assets---------------- */
import { orderBy } from "@progress/kendo-data-query";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import {
  GridColumn as Column,
  Grid,
  GridToolbar,
} from "@progress/kendo-react-grid";
import {
  IntlProvider,
  LocalizationProvider,
  load,
} from "@progress/kendo-react-intl";
import Spin from "antd/lib/spin";
import date from "cldr-dates-full/main/fr/ca-gregorian.json";
import numbers from "cldr-numbers-full/main/fr/numbers.json";
import React, { useEffect, useState } from "react";
/* -----------Style Sheet------------ */
import { MyCommandCell } from "./commandCell";
import "./styles.scss";
import Checkbox from "./Checkbox";

load(numbers, date);
const TableComponent = ({
  handleRowDoubleClick,
  gridlist,
  columnNames,
  loader,
  gridToolBar,
  takestep,
  rowRender,
  editField,
  handleRowClick,
  selectedID,
  setExportCsv,
  columnExport,
  excelFilename,
  cellRender,
  notpageable,
  height,
  onItemChange,
  className,
  isCommandCell,
  dispatchModif,
  addRow,
  removeRow,
  updateRow,
  rowHasCheckbox = false,
  handleCheckboxChange,
  selectedProperties = []
}) => {
  /* -----------State Hook------------ */
  const [dataGrid, setDataGrid] = useState([]);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);
  const [sort, setSort] = useState([]);
  const [dy_pageSizes, setDy_pageSizes] = useState([]);
  const [editID, setEditID] = useState(null);
  /* -----------Effect Hook------------ */
  useEffect(
    function () {
      setDataGrid([...gridlist]);
      setSkip(skip);
      setTake(take);
      dyFCT_pageSizes();
    },
    [gridlist]
  );
  /* -----------Vars------------ */
  /* -----------Functions------------ */
  let dyFCT_pageSizes = () => {
    let dy_pageSize = gridlist.length < 20 ? [10, 20]
      :
      (
        gridlist.length < 50 ? [10, 20, gridlist.length]
          :
          (
            gridlist.length < 100 ? [10, 20, 50, gridlist.length]
              :
              [10, 20, 50, 100, gridlist.length]
          )
      )
    setDy_pageSizes(dy_pageSize)
  }
  const pageChange = (event) => {
    setTake(event.page.take);
    setSkip(event.page.skip);
  };
  const handleOnRowClick = (event) => {
    if (editID === event.dataItem.ID) {
      setEditID(null);
    } else {
      setEditID(event.dataItem.ID);
    }
    handleRowClick(event.dataItem, editID);
  };
  const prepareDataGrid = (dataGrid) => {
    return orderBy(
      dataGrid.map((item) => {
        return {
          ...item,
        };
      }),
      sort
    )
      .slice(skip, skip + take)
      .map((item) => {
        return {
          ...item,
          selected: item.ID === (selectedID && selectedID.ID),
        };
      });
  };
  const onCellChange = (e) => {
    setEditID(e.dataItem.ID);
    onItemChange(e)
  }
  const enterEdit = (dataItem) => {
    dataItem.inEdit = true;
    dispatchModif(dataItem)
  }
  const remove = (dataItem) => {
    removeRow(dataItem)
  }
  const add = (dataItem) => {
    dataItem.inEdit = false;
    addRow(dataItem)
  }
  const discard = (dataItem) => {
    dataItem.inEdit = false;
    dispatchModif(dataItem)
  }
  const update = (dataItem) => {
    updateRow(dataItem)
  }
  const cancel = (dataItem) => {
    dataItem.inEdit = false;
    dispatchModif(dataItem)
  }
  const CommandCell = props =>
    <MyCommandCell {...props}
      edit={enterEdit} remove={remove} add={add} discard={discard}
      update={update} cancel={cancel} editField={editField ? editField : "false"}

    />;

  const _handleCheckboxChange = (checkbox, property) => {
    if (property.IDProperty) {
      handleCheckboxChange(checkbox.value, property)
    } else {
      dataGrid.forEach(prop => handleCheckboxChange(checkbox.value, prop))
    }
  }
  /* -----------render------------ */
  return (
		<div className="genericGridComponent">
			<Spin spinning={loader} size="large">
				{setExportCsv ? (
					<ExcelExport
						data={selectedProperties}
						ref={setExportCsv}
						fileName={excelFilename}
					>
						{(columnExport ? columnExport : columnNames).map(
							(column, i) => {
								const { field, title, label, name } = column;
								return (
									<ExcelExportColumn
										key={i}
										title={title ? title : label}
										field={field ? field : name}
									/>
								);
							}
						)}
					</ExcelExport>
				) : null}
				<LocalizationProvider language="fr-FR">
					<IntlProvider locale="fr">
						<Grid
							style={{
								height: height ? height : "67vh",
								width: "100%",
							}}
							className={className}
							resizable
							reorderable
							data={prepareDataGrid(dataGrid)}
							skip={skip}
							//take={take}
							pageSize={take}
							pageable={
								notpageable
									? null
									: {
											buttonCount: 2,
											info: true,
											type: "numeric",
											pageSizes: dy_pageSizes,
											previousNext: true,
											input: true,
											messages: {
												itemsPerPage:
													"Affichage par page",
											},
									  }
							}
							total={dataGrid.length}
							onPageChange={pageChange}
							sortable
							sort={sort}
							onSortChange={(e) => {
								setSort(e.sort);
							}}
							selectedField="selected"
							onRowClick={(e) => handleOnRowClick(e)}
							onRowDoubleClick={(e) =>
								handleRowDoubleClick
									? handleRowDoubleClick(e)
									: null
							}
							rowRender={rowRender ? rowRender : null}
							editField={editField ? editField : "false"}
							cellRender={cellRender}
							onItemChange={onCellChange}
						>
							{gridToolBar ? (
								<GridToolbar>{gridToolBar}</GridToolbar>
							) : null}
							{rowHasCheckbox && (
								<Column
									headerCell={(event) => (
										<Checkbox
											handleCheckboxChange={
												_handleCheckboxChange
											}
											checkedRows={selectedProperties}
											property={event}
                      amountOfRows={dataGrid.length}
										/>
									)}
									cell={(event) => (
										<Checkbox
											handleCheckboxChange={
												_handleCheckboxChange
											}
											checkedRows={selectedProperties}
											property={event.dataItem}
										/>
									)}
									width="15px"
								/>
							)}
							{columnNames.map((column, i) => {
								const {
									field,
									title,
									format,
									editor,
									editable,
									width,
									isNumeric,
								} = column;
								return (
									<Column
										key={i}
										title={title}
										field={field}
										format={format}
										width={width ? width : "100px"}
										editor={editor}
										editable={editable}
										className={
											isNumeric ? "rightAlign" : "shit"
										}
									/>
								);
							})}
							{isCommandCell ? (
								<Column cell={CommandCell} width="30px" />
							) : null}
						</Grid>
					</IntlProvider>
				</LocalizationProvider>
			</Spin>
		</div>
  );
};

export default TableComponent;
