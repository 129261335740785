import { Col, Row } from "antd";
import TypicalSentence from "./TypicalSentence";

const TypicalSentencesList = ({ sentences, onClick }) => {
	return (
		<div>
			<Row gutter={[16, 16]}>
				{sentences?.map((typicalSentence) => (
					<Col span={6}>
						<TypicalSentence
							key={typicalSentence.id}
							sentence={typicalSentence}
							onClick={onClick}
						/>
					</Col>
				))}
			</Row>
		</div>
	);
};

export default TypicalSentencesList;
