/* -----------Components------------ */
import ToolbarButtonComponent from '../button_toolbar/toolbar_button.component';
/* -----------Assets------------ */
import React, { useState, useEffect } from "react";
import Transfer from "antd/lib/transfer";
import { useDispatch } from "react-redux";
import _ from "lodash";
/* -----------Style Sheet------------ */
import "./styles.scss";


/*
 ** Target key are the set of key in the right col
 */
const SettingsFormComponent = ({
  selectedkeys,
  targetkeys,
  setSelectedCols
}) => {

  /* -----------Vars------------ */
  /*
   **Update entries for filter component
   */
  //the transfer component compare objects with the key "key" and the value should be a string
  const updatedSelected = selectedkeys.map((item, i) =>
    Object.assign({}, item, { key: item.IDColumn })
  );
  const targetArrayId = targetkeys.map((item) => item.IDColumn);
  const updatedSelectedRank = updatedSelected.map((item, i) => {
    if (targetArrayId.includes(item.IDColumn)) {
      let index = targetArrayId.indexOf(item.IDColumn);
      return Object.assign({}, item, { rank: index * 100 });
    } else {
      return Object.assign({}, item, { rank: 0 });
    }
  });
  const updatedTarget = updatedSelectedRank.filter((item) =>
    targetArrayId.includes(item.IDColumn)
  );
  //Selection Sort algo
  const SortedUpdatedTarget = (arr) => {
    let min;
    for (let i = 0; i < arr.length; i++) {
      // Assume a minimum value
      min = i;
      for (let j = i + 1; j < arr.length; j++) {
        if (arr[j].rank < arr[min].rank) {
          min = j;
        }
      }
      // Swap if new minimun value found
      if (min !== i) {
        [arr[i], arr[min]] = [arr[min], arr[i]];
      }
    }
    return arr;
  };
  //the intial values should be an array of strings
  let updatedTargetArray = SortedUpdatedTarget(updatedTarget).map(
    (item) => item.key
  );
  const dispatch = useDispatch();
  /* -----------State Hook------------ */
  const [targetKeys, setTargetKeys] = useState(updatedTargetArray);
  const [canMoveUpDown, setCanMoveUpDown] = useState(false);
  const [moveUpDownKeys, setMoveUpDownKeys] = useState();
  const [selectedKeys, setSelectedKeys] = useState([]);
  /* -----------Use Effect------------ */
  useEffect(() => {
    return () => {
      onSelectChange([], []);
    };
  }, []);

  /*------------Functions------------- */
  const dynamicSelectedCol = (val) => {
    dispatch(setSelectedCols(val));
  };
  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    //trigger when click on option
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    setMoveUpDownKeys(targetSelectedKeys);
    if (targetSelectedKeys.length >= 1) {
      setCanMoveUpDown(true);
    } else {
      setCanMoveUpDown(false);
    }
  };
  const onChange = (nextTargetKeys, direction, moveKeys) => {
    //trigger when moving the option to other side
    setTargetKeys(nextTargetKeys);
    dynamicSelectedCol(nextTargetKeys);
  };
  const rankup = () => {
    const rankTargetKeys = _.cloneDeep(targetKeys);
    const indexof = targetKeys.indexOf(moveUpDownKeys[0]);
    if (moveUpDownKeys.length === 1 && indexof > 0) {
      [rankTargetKeys[indexof], rankTargetKeys[indexof - 1]] = [
        rankTargetKeys[indexof - 1],
        rankTargetKeys[indexof],
      ];
      setTargetKeys(rankTargetKeys);
      dynamicSelectedCol(rankTargetKeys);
    }
  };
  const rankdown = () => {
    const rankTargetKeys = _.cloneDeep(targetKeys);
    const indexof = targetKeys.indexOf(moveUpDownKeys[0]);
    if (moveUpDownKeys.length === 1 && indexof < targetKeys.length - 1) {
      [rankTargetKeys[indexof], rankTargetKeys[indexof + 1]] = [
        rankTargetKeys[indexof + 1],
        rankTargetKeys[indexof],
      ];
      setTargetKeys(rankTargetKeys);
      dynamicSelectedCol(rankTargetKeys);
    }
  };
  return (
    <>
      <div className="row_rank">
        <ToolbarButtonComponent
          icon="chevron-up"
          title="Rank up"
          className="k-button-outline-secondary"
          onclick={rankup}
        />
        <ToolbarButtonComponent
          icon="chevron-down"
          title="Rank down"
          className="k-button-outline-secondary"
          onclick={rankdown}
        />
      </div>
      <Transfer
        className="transferlist"
        dataSource={updatedSelected}
        titles={["All Columns", "Selected Columns"]}
        targetKeys={targetKeys}
        selectedKeys={selectedKeys}
        render={(item) => {
          return item.title;
        }}
        onSelectChange={onSelectChange}
        onChange={onChange}
      ></Transfer>
    </>
  );
};
export default SettingsFormComponent;
