/* -----------Components------------ */
import SelectFormComponent from "../select_input_form/antdselect.component";
/* -----------Assets------------ */
import React, { useEffect, useState } from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Radio from "antd/lib/radio";
import { useDispatch, useSelector } from "react-redux";
/* -----------Selectors------------ */
import { countriesListSelector } from "../../redux/Authentification/authentication.selector";
import { tiersFicheSelector } from "../../redux/contacts/contacts.selector";
/* -----------Style Sheet------------ */
import "./styles.scss";







const AddTiersFormComponent = ({ form, onTiersForm_change }) => {
    /* -----------State Hook------------ */
    /* -----------Selector------------ */
    const countriesList = useSelector(countriesListSelector);
    const tiersForm = useSelector(tiersFicheSelector)
    useEffect(() => {
        form.setFieldsValue(tiersForm)
    }, [tiersForm])
    /* -----------Vars------------ */
    const dispatch = useDispatch();
    const { TextArea } = Input;
    const optionsWithDisabled = [
        { label: 'Entreprise', value: 'ENTREPRISE' },
        { label: 'Particulier', value: 'PARTICULIER' },
    ];
    /* -----------Functions------------ */

    return (
        <div className="addTiersForm">
            <Form form={form} onValuesChange={onTiersForm_change}>
                <Row style={{ justifyContent: "space-between", marginBottom: "20px" }}>
                    <Col span={24}>
                        <Form.Item name="data_str[CompanyType]">
                            <Radio.Group
                                className="ant-radio-button"
                                options={optionsWithDisabled}
                                optionType="button"
                                buttonStyle="solid"
                                size="small"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    {tiersForm['data_str[CompanyType]'] === "ENTREPRISE" ?
                        <Col span={24} >
                            <Form.Item label="Dénomination" name="data_str[Denomination]">
                                <Input placeholder="Dénomination" size="small" />
                            </Form.Item>
                        </Col>
                        :
                        <>
                            <Col span={12} >
                                <Form.Item label="Nom" name="data_str[Nom]">
                                    <Input placeholder="Nom" size="small" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Prénom" name="data_str[Prenom]">
                                    <Input placeholder="Prénom" size="small" />
                                </Form.Item>
                            </Col>
                        </>
                    }
                </Row>
                <Row>
                    <Col span={24}  >
                        <Form.Item label="Téléphone" name="data_str[Telephone]">
                            <Input placeholder="Téléphone" size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={24}  >
                        <Form.Item label="Mail" name="data_str[Mail]">
                            <Input placeholder="Mail" size="small" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name="data_str[Adresse]" label="Adresse">
                            <TextArea allowClear placeholder="Adresse" />
                        </Form.Item>
                        <Row>
                            <Col span={24} className="divided_col" >
                                <Form.Item name="data_str[CodePostal]">
                                    <Input placeholder="Code postal" size="small" />
                                </Form.Item>
                                <Form.Item name="data_str[Ville]">
                                    <Input placeholder="Ville" size="small" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} className="divided_col">
                                <SelectFormComponent
                                    size="small"
                                    name="data_int[IDPays]"
                                    dataOption={countriesList}
                                    value="IDPays"
                                    label="Pays"
                                    clearable={true} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default AddTiersFormComponent;
