import { AutoComplete, Slider } from "antd";
import Col from "antd/lib/col";
import Divider from "antd/lib/divider";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Radio from "antd/lib/radio";
import Row from "antd/lib/row";
import Tabs from "antd/lib/tabs";
import { LatLng } from "leaflet";
import "leaflet-geosearch/dist/geosearch.css";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Circle, MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import { countriesListSelector } from "../../../redux/Authentification/authentication.selector";
import { LoadProprioInfAPI } from "../../../redux/Immobilier/properties/prop.api";
import { updateFiche } from "../../../redux/Immobilier/properties/prop.slice";
import {
	propSecteursSelector,
	propStatutsSelector,
	propTypesSelector,
	userPropSelector,
} from "../../../redux/referentiel/ref.selector";
import SelectFormComponent from "../../select_input_form/antdselect.component";
import InfoPratiqueComponent from "./info_pratique/component";
import ImmoGestPropNotesInternes from "./notes/component";
import "./styles.scss";
import { getCoordinatesFromAddress } from "../../../utils";

const InfoGImmoGestPropComponent = ({ addProprie_trigger, ficheImmoProp }) => {
	/* -----------State Hook------------ */
	/* -----------Selector------------ */
	const countriesList = useSelector(countriesListSelector);
	const typeB = useSelector(propTypesSelector);
	const secteurs = useSelector(propSecteursSelector);
	const listPropr = useSelector(userPropSelector);
	const statutsList = useSelector(propStatutsSelector);
	/* -----------Vars------------ */
	const dispatch = useDispatch();
	const { TextArea } = Input;
	const { TabPane } = Tabs;
	/* -----------Functions------------ */
	const onProprioChange = (val, opt, name) => {
		if (val) {
			dispatch(LoadProprioInfAPI(val));
		}
	};

	// Map
	// DISCLAIMER: If you get "Attempted to load an infite number of tiles", it means that your are setting a value as a string instead of a number/float, or that a value is NaN
	const DEFAULT_LATITUDE = 48.856614;
	const DEFAULT_LONGITUDE = 2.3522219;
	const DEFAULT_RADIUS = 400;
	const DEFAULT_ZOOM = 13;
	const DEBOUNCE_DURATION = 700;

	const [adresses, setAddresses] = useState([]);

	const getCorrectValue = (value, fallback) => {
		return [undefined, "undefined", null, "null", 0, "0", NaN].includes(
			value
		)
			? fallback
			: value;
	};

	const getDefaultMapContent = () => {
		return {
			exactLon: DEFAULT_LONGITUDE,
			exactLat: DEFAULT_LATITUDE,
			approLon: DEFAULT_LATITUDE,
			approLat: DEFAULT_LATITUDE,
			radius: DEFAULT_RADIUS,
			zoom: DEFAULT_ZOOM,
		};
	};

	const getMapContentFromFiche = () => {
		return {
			exactLon: getCorrectValue(
				parseFloat(ficheImmoProp["data_int[Map_Longitude_Origin]"]),
				DEFAULT_LONGITUDE
			),
			exactLat: getCorrectValue(
				parseFloat(ficheImmoProp["data_int[Map_Latitude_Origin]"]),
				DEFAULT_LATITUDE
			),
			approLon: getCorrectValue(
				parseFloat(ficheImmoProp["data_int[Map_Longitude]"]),
				DEFAULT_LONGITUDE
			),
			approLat: getCorrectValue(
				parseFloat(ficheImmoProp["data_int[Map_Latitude]"]),
				DEFAULT_LATITUDE
			),
			radius: getCorrectValue(
				parseInt(ficheImmoProp["data_int[Map_Rayon]"]),
				DEFAULT_RADIUS
			),
			zoom: getCorrectValue(
				parseFloat(ficheImmoProp["data_int[Map_Zoom]"]),
				DEFAULT_ZOOM
			),
		};
	};

	const [mapContent, setMapContent] = useState(getDefaultMapContent());
	const [map, setMap] = useState(null);
	const markerRef = useRef(null);

	const eventHandlers = useMemo(
		() => ({
			dragend() {
				const marker = markerRef.current;
				if (marker != null) {
					setMapContent({
						...mapContent,
						approLon: marker?.getLatLng()?.lng,
						approLat: marker?.getLatLng()?.lat,
					});
					dispatch(
						updateFiche({
							"data_int[Map_Longitude]": marker.getLatLng().lng,
							"data_int[Map_Latitude]": marker.getLatLng().lat,
						})
					);
				}
			},
		}),
		[mapContent]
	);

	const updateZoom = (zoom) => {
		setMapContent({
			...mapContent,
			zoom: getCorrectValue(parseFloat(zoom), DEFAULT_ZOOM),
		});
	};

	const updateRadius = (newRadius, updateFicheForm) => {
		setMapContent({ ...mapContent, radius: newRadius });

		if (!updateFicheForm) {
			dispatch(
				updateFiche({
					"data_int[Map_Rayon]": newRadius,
				})
			);
		}
	};

	const updateMap = () => {
		map?.setView(
			new LatLng(mapContent.approLat, mapContent.approLon),
			mapContent.zoom
		);
		map?.invalidateSize();
	};

	useEffect(() => {
		updateMap();
	}, [mapContent]);

	useEffect(() => {
		setMapContent(getMapContentFromFiche());
	}, [ficheImmoProp]);

	useEffect(() => {
		const getData = setTimeout(() => {
			if (!ficheImmoProp["data_str[Adresse]"]) {
				setAddresses([]);
				return;
			}
			getCoordinatesFromAddress(ficheImmoProp["data_str[Adresse]"]).then(
				(res) => {
					if (res?.length > 0) {
						setAddresses(res);
					}
				}
			);
		}, DEBOUNCE_DURATION);

		return () => clearTimeout(getData);
	}, [
		ficheImmoProp["data_str[Adresse]"],
		ficheImmoProp["data_str[CodePostal]"],
		ficheImmoProp["data_str[Ville]"],
		ficheImmoProp["data_int[IDPays]"],
	]);

	const onAdressSelect = (e) => {
		const selectedAdress = adresses.filter((a) => a.formatted === e)[0];

		if (selectedAdress) {
			setMapContent({
				...mapContent,
				exactLon: selectedAdress.lon,
				exactLat: selectedAdress.lat,
				approLat: selectedAdress.lat,
				approLon: selectedAdress.lon,
			});

			const internalCountry = countriesList.filter(
				(c) =>
					c?.country_code?.toLowerCase() ===
					selectedAdress?.country_code?.toLowerCase()
			)[0];

			dispatch(
				updateFiche({
					"data_int[Map_Longitude]": selectedAdress.lon,
					"data_int[Map_Latitude]": selectedAdress.lat,
					"data_int[Map_Longitude_Origin]": selectedAdress.lon,
					"data_int[Map_Latitude_Origin]": selectedAdress.lat,
					"data_str[CodePostal]": selectedAdress.postcode,
					"data_str[Ville]": selectedAdress.city,
					"data_int[IDPays]": internalCountry?.IDPays,

					// Hidden fields
					"data_str[Country]": selectedAdress.country,
					"data_str[CountryCode]": selectedAdress.country_code,
					"data_str[Suburb]": selectedAdress.suburb,
					"data_str[County]": selectedAdress.county,
					"data_str[State]": selectedAdress.state,
					"data_str[Address_line1]": selectedAdress.address_line1,
				})
			);
		}
	};

	const resetViewToMarker = () => {
		map?.setView(
			new LatLng(mapContent.approLat, mapContent.approLon),
			mapContent.zoom
		);
	};

	return (
		<div className="infoGImmoGestProp">
			<Row
				style={{
					justifyContent: "space-between",
					marginBottom: "20px",
				}}
			>
				<Col md={12} xl={12} className="radioo">
					<Form.Item label="Actif" name="data_int[Actif]">
						<Radio.Group>
							<Radio value={1}>Oui</Radio>
							<Radio value={0}>Non</Radio>
						</Radio.Group>
					</Form.Item>
				</Col>
				<Col md={12} xl={12} style={{ textAlign: "right" }}>
					<SelectFormComponent
						formlabel="Statut"
						name="data_int[IDPropertyStatut]"
						dataOption={statutsList}
						value="IDPropertyStatut"
						label="Libelle_fr"
						clearable={true}
					/>
				</Col>
			</Row>
			<Divider orientation="left" plain>
				IMMOBILIER
			</Divider>
			<Row className="limite">
				<Col md={24} xl={8}>
					<SelectFormComponent
						formlabel="Type de bien"
						name="data_int[IDPropertyType]"
						dataOption={typeB}
						value="IDPropertyType"
						label="Libelle_fr"
						clearable={true}
					/>
				</Col>
				<Col md={12} xl={8}>
					<Form.Item
						label="Dénomination"
						name="data_str[Denomination]"
					>
						<Input size="small" />
					</Form.Item>
				</Col>
				<Col md={8} xl={8}>
					<Form.Item
						label="Numéro de mandat"
						name="data_str[CodeInterne]"
					>
						<Input size="small" />
					</Form.Item>
				</Col>
				<Col md={12} xl={8}>
					<Form.Item
						label="Nom de l'enseigne"
						name="data_str[NomEnseigne]"
					>
						<Input size="small" />
					</Form.Item>
				</Col>
			</Row>
			<Divider orientation="left" plain>
				ADRESSE DU BIEN
			</Divider>
			<Row className="limite">
				<Col md={24} xl={16}>
					<Form.Item label="Adresse" name="data_str[Adresse]">
						<AutoComplete
							allowClear
							options={adresses?.map((address) => ({
								key: address.place_id,
								value: address.formatted,
								label: address.formatted,
							}))}
							style={{ width: "100%" }}
							onSelect={onAdressSelect}
							placeholder="Adresse"
						/>
					</Form.Item>
				</Col>
				<Col md={8} xl={8}>
					<SelectFormComponent
						formlabel="Secteur"
						name="data_int[IDPropertyArea]"
						dataOption={secteurs}
						value="IDPropertyArea"
						label="Libelle_fr"
						clearable={true}
					/>
				</Col>
				<Col md={24} xl={16}>
					<Form.Item
						label="Complément"
						name="data_str[Address_line2]"
					>
						<Input allowClear placeholder="Complément d'adresse" />
					</Form.Item>
				</Col>
				<Col md={24} xl={16}>
					<Col md={24} xl={24} className="divided_col">
						<Form.Item name="data_str[CodePostal]">
							<Input size="small" placeholder="Code postal" />
						</Form.Item>
						<Form.Item name="data_str[Ville]">
							<Input size="large" placeholder="Ville" />
						</Form.Item>
					</Col>
					<Col md={12} xl={8}>
						<SelectFormComponent
							formlabel="Pays"
							name="data_int[IDPays]"
							dataOption={countriesList}
							value="IDPays"
							label="Pays"
							clearable={true}
						/>
					</Col>
				</Col>

				<div className="w-100">
					<hr />
				</div>

				<div className="leaflet-parent">
					<p id="map-disclaimer">
						Le résultat final ne contiendra que le cercle rouge ou
						le point vert si le rayon est à 0.
					</p>
					<MapContainer
						ref={setMap}
						center={[mapContent.exactLat, mapContent.exactLon]}
						zoom={mapContent.zoom}
						scrollWheelZoom={false}
						fadeAnimation={true}
						zoomControl={true}
					>
						<TileLayer
							attribution='&copy; OpenStreetMap France | &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
							url="https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
						/>
						<Marker
							id="excat-location"
							zIndexOffset={0}
							position={[
								mapContent.exactLat,
								mapContent.exactLon,
							]}
						>
							<Popup>Localisation exacte du bien</Popup>
						</Marker>
						<Marker
							position={[
								mapContent.approLat,
								mapContent.approLon,
							]}
							ref={markerRef}
							draggable
							zIndexOffset={1000}
							eventHandlers={eventHandlers}
						>
							<Popup>Localisation approximative du bien</Popup>
						</Marker>
						<Circle
							center={[mapContent.approLat, mapContent.approLon]}
							pathOptions={{
								fillColor: "red",
								color: "none",
								fillOpacity: "0.6",
							}}
							radius={mapContent.radius}
						>
							<Popup>Localisation approximative du bien</Popup>
						</Circle>
						<div class="leaflet-top leaflet-right">
							<div class="leaflet-bar leaflet-control">
								<a
									title="Recentrer la carte"
									role="button"
									aria-label="Recentrer la carte"
									aria-disabled="false"
									onClick={resetViewToMarker}
								>
									<i
										className={`bi bi-house`}
										style={{ fontSize: "21px" }}
									/>
								</a>
							</div>
						</div>
					</MapContainer>
				</div>
				<Col md={12} xl={12}>
					<Form.Item label="Zoom" name="data_int[Map_Zoom]">
						<Slider
							min={1}
							max={20}
							onChange={(e) => updateZoom(e)}
							value={mapContent.zoom}
						/>
					</Form.Item>
				</Col>
				{/* This Form Item has no name because it updates to often and makes everything lag */}
				<Col md={12} xl={12}>
					<Form.Item label="Rayon">
						<Slider
							min={0}
							max={20000}
							step={10}
							onChange={(e) => updateRadius(e, true)}
							value={mapContent.radius}
							onAfterChange={(e) => updateRadius(e, false)}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Divider orientation="left" plain style={{ marginTop: "10px" }}>
				PROPRIETAIRE
			</Divider>
			<Row className="limite">
				<Col md={24} xl={16}>
					<SelectFormComponent
						formlabel="Propriétaire"
						name="data_int[IDProprietaire]"
						dataOption={listPropr}
						value="ID"
						label="libelle"
						//clearable={true}
						onSelect={onProprioChange}
						isDropDownCustom={true}
						textBtnCustom="Ajouter un propriétaire ..."
						actionBtnCustom={addProprie_trigger}
					/>
				</Col>
				<Col md={0} xl={8}></Col>
				<Col md={12} xl={8}>
					<Form.Item name="data_str[proprioTelephone]" label="Tél.">
						<Input size="small" placeholder="Tél." disabled />
					</Form.Item>
				</Col>
				<Col md={12} xl={8}>
					<Form.Item name="data_str[proprioMail]" label="Mail">
						<Input size="small" placeholder="Mail" disabled />
					</Form.Item>
				</Col>
				<Col md={24} xl={16}>
					<Form.Item label="Adresse" name="data_str[proprioAdresse]">
						<TextArea allowClear placeholder="Adresse" disabled />
					</Form.Item>
				</Col>
				<Col md={24} xl={16} className="divided_col">
					<Form.Item name="data_str[proprioCodePostal]">
						<Input
							size="small"
							placeholder="Code postal"
							disabled
						/>
					</Form.Item>
					<Form.Item name="data_str[proprioVille]">
						<Input size="small" placeholder="Ville" disabled />
					</Form.Item>
				</Col>
				<Col md={12} xl={8}>
					<SelectFormComponent
						formlabel="Pays"
						name="data_str[proprioPays]"
						dataOption={countriesList}
						value="IDPays"
						label="Pays"
						clearable={true}
						disabled={true}
					/>
				</Col>
			</Row>
			<div className="submodule">
				<Tabs type="card">
					<TabPane tab="Notes" key="1">
						<div className="tab_container">
							<ImmoGestPropNotesInternes />
						</div>
					</TabPane>
					<TabPane tab="Informations Pratiques" key="2">
						<div className="tab_container">
							<InfoPratiqueComponent />
						</div>
					</TabPane>
				</Tabs>
			</div>
		</div>
	);
};

export default InfoGImmoGestPropComponent;
