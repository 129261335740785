const API_KEY = "c194c2ab6392442f87fc3fac095ec871";

export const getCoordinatesFromAddress = async (address) => {
	const urlSearch = new URLSearchParams({
		text: address,
		format: "json",
		type: "amenity",
		lang: "fr",
		apiKey: API_KEY,
	});
	return await fetch(
		`https://api.geoapify.com/v1/geocode/search?${urlSearch}`
	).then((res) =>
		res.json().then((res) => {
			return res.results ?? [];
		})
	);
};
