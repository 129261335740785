import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
	proprietaires: [],
	propTypes: [],
	propStyles: [],
	propStatuts: [],
	propTags: [],
	propFams: [],
	propCateg: [],
	propEquip: [],
	propPtsForts: [],
	propServices: [],
	propSecteurs: [],
};

const referentiel = createSlice({
	name: "referentiel",
	initialState,
	reducers: {
		loadProprios: (state, { payload }) => {
			state.proprietaires = payload;
		},
		loadPropertiesType: (state, { payload }) => {
			state.propTypes = payload;
		},
		loadPropertiesStyles: (state, { payload }) => {
			state.propStyles = payload;
		},
		loadPropertiesStatuts: (state, { payload }) => {
			state.propStatuts = payload;
		},
		loadPropertiesTags: (state, { payload }) => {
			state.propTags = payload;
		},
		loadPropertiesFams: (state, { payload }) => {
			state.propFams = payload;
		},
		loadPropertiesCateg: (state, { payload }) => {
			state.propCateg = payload;
		},
		loadPropertiesEquip: (state, { payload }) => {
			state.propEquip = payload;
		},
		loadPropertiesPtsForts: (state, { payload }) => {
			state.propPtsForts = payload;
		},
		loadPropertiesServices: (state, { payload }) => {
			state.propServices = payload;
		},
		loadPropertiesSecteurs: (state, { payload }) => {
			state.propSecteurs = payload;
		},
	},
});

export const {
	loadProprios,
	loadPropertiesType,
	loadPropertiesStyles,
	loadPropertiesStatuts,
	loadPropertiesTags,
	loadPropertiesFams,
	loadPropertiesCateg,
	loadPropertiesEquip,
	loadPropertiesPtsForts,
	loadPropertiesServices,
	loadPropertiesSecteurs,
} = referentiel.actions;

export default referentiel.reducer;
