/* -----------Assets------------ */
import React, { useState } from "react";
import Layout from "antd/lib/layout";
import Menu from "antd/lib/menu";
import MENU_ITEMS from "./sidebar.data";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
/* -----------Components------------ */
import SidebarLogo from "../sidebarLogo/component";
/* -----------Selectors------------ */
import { userRolesSelector } from "../../redux/Authentification/authentication.selector";
/* -----------Style Sheet------------ */
import "./styles.scss";



const Sidebar = ({ collapsed }) => {
  /* -----------State Hook------------ */
  const [openedKeys, setopenedKeys] = useState([]);
  /* -----------Vars------------ */
  const history = useHistory();
  const { Sider } = Layout;
  const { SubMenu } = Menu;

  const userRoles = useSelector(userRolesSelector);
  /* -----------Functions------------ */
  const initialSelect = () => {
    let defaut;
    Object.entries(MENU_ITEMS).map((item) => {
      if (item[1].subMenu) {
        item[1].subMenu.map((subitem) => {
          if (subitem.path === history.location.pathname) {
            defaut = [subitem.ID];
          }
        });
      } else {
        if (item[1].path === history.location.pathname) {
          defaut = [item[1].ID];
        }
      }
    });
    return defaut;
  };
  const onOpenChange = (data) => {
    let filtred = data.filter(item => item != openedKeys[0])
    setopenedKeys(filtred)
  }

  /* -----------render------------ */
  return (
    <Sider trigger={null} collapsible collapsed={collapsed} width="240">
      <SidebarLogo collapsed={collapsed} />
      <Menu mode="inline" defaultSelectedKeys={initialSelect()} openKeys={openedKeys} onOpenChange={onOpenChange}>
        {Object.entries(MENU_ITEMS).map((item) => {
          if (item[1].subMenu) {
            return (
              <SubMenu key={item[1].ID} icon={item[1].icon} title={item[1].MenuName}>
                {item[1].subMenu.map((subitem) => {
                  if (userRoles.includes(parseInt(subitem?.ID))) {
                    return (
                      <Menu.Item
                        className="sidebar_submenu_item"
                        key={subitem.ID}
                      >
                        <Link to={subitem.path} title={subitem.MenuName}>{subitem.MenuName}</Link>
                      </Menu.Item>
                    );
                  }
                })}
              </SubMenu>
            );
          } else {
            return (
              <Menu.Item
                className="sidebar_menu_item"
                key={item[1].ID}
                icon={item[1].icon}
              >
                <Link to={item[1].path} title={item[1].MenuName}>{item[1].MenuName}</Link>
              </Menu.Item>
            );
          }

        })}
      </Menu>
    </Sider>
  );
};

export default Sidebar;
