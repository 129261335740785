/* -----------Components------------ */
import InfoGPartnerComponent from "./infoG/component";
/* -----------Assets------------ */
import Avatar from "antd/lib/avatar";
import Col from "antd/lib/col";
import Comment from "antd/lib/comment";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Menu from "antd/lib/menu";
import Row from "antd/lib/row";
import Spin from "antd/lib/spin";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import userImage from "../../assets/images/user.png";
import {
	deleteFromFiche,
	updateFiche,
} from "../../redux/Immobilier/properties/prop.slice";
/* -----------Selector------------ */
import { userDetailsSelector } from "../../redux/Authentification/authentication.selector";
import {
	LoadFichePartnerAPI,
	LoadPartnerSuiviAPI,
	SavePartnerSuiviAPI,
} from "../../redux/WebSIte/partners/partners.api";
import {
	fichePartnerFormSelector,
	loaderfichePartnerSelector,
	partnerSuiviSelector,
} from "../../redux/WebSIte/partners/partners.selector";
/* -----------Style Sheet------------ */
import "./styles.scss";

const PartnerFormComponent = ({ form, onPartnerFormChange }) => {
	/* -----------State Hook------------ */
	const [suiviValue, setSuiviValue] = useState("");
	const [choosedSidemenuKey, setChoosedSidemenuKey] = useState([
		"infG",
		"suivi",
	]);
	/* -----------Selectors------------ */
	const fichePartner = useSelector(fichePartnerFormSelector);
	const loader = useSelector(loaderfichePartnerSelector);
	const partnerSuivi = useSelector(partnerSuiviSelector);
	let LOGGED_USER = useSelector(userDetailsSelector);
	/* -----------Effect Hooks------------ */
	useEffect(() => {
		form.setFieldsValue(fichePartner);
	}, [fichePartner]);

	useEffect(() => {
		if (Object.values(fichePartner).length) {
			dispatch(LoadFichePartnerAPI(fichePartner["data_int[IDPartner]"]));
			dispatch(LoadPartnerSuiviAPI(fichePartner["data_int[IDPartner]"]));
		}
	}, []);

	/* -----------Vars------------ */
	const dispatch = useDispatch();
	const { TextArea } = Input;

	/* -----------Functions------------ */
	const handleSuivi_Change = (e) => {
		let {
			target: { value },
		} = e;
		setSuiviValue(value);
	};

	const handleSuivi_Submit = () => {
		let payload = {
			Objet: "partners",
			IDObjet: fichePartner["data_int[IDPartner]"],
			Event: suiviValue,
		};
		dispatch(
			SavePartnerSuiviAPI(
				payload,
				fichePartner["data_int[IDPartner]"],
				setSuiviValue
			)
		);
	};

	const onGestPartnerForm_change = (editedVal, allVal) => {
		let key = Object.keys(editedVal)[0];
		if (
			key.includes("equipment") ||
			key.includes("pforts") ||
			key.includes("services")
		) {
			if (fichePartner[key]) {
				dispatch(deleteFromFiche({ [key]: "" }));
			} else {
				dispatch(
					updateFiche({ [key]: parseInt(key.match(/\[(.*?)\]/)[1]) })
				);
			}
		} else {
			if (editedVal[key] || editedVal[key] === 0) {
				dispatch(updateFiche(editedVal));
			} else {
				dispatch(updateFiche({ [key]: null }));
			}
		}
	};

	//UI element's
	return (
		<Spin spinning={loader} size="large">
			<div className="partnerFiche">
				<Form form={form} onValuesChange={onGestPartnerForm_change}>
					<Row className="allContainer">
						<Col md={24} xl={16} className="ficheContent bg-white">
							<Menu
								mode="horizontal"
								selectedKeys={choosedSidemenuKey}
								theme="Light"
							>
								<Menu.Item key="infG">
									Informations générales
								</Menu.Item>
							</Menu>
							<div className="maincontent">
								<InfoGPartnerComponent
									addPartnerImgTrigger={onPartnerFormChange}
								/>
							</div>
						</Col>
						<Col md={24} xl={7} className="suiviContent bg-white">
							<div className="fixedArea">
								<Comment
									avatar={
										<Avatar
											src={
												LOGGED_USER?.userAvatar
													? LOGGED_USER?.userAvatar
													: userImage
											}
											alt={LOGGED_USER?.userFirstName}
										/>
									}
									content={
										<div>
											<TextArea
												rows={2}
												onChange={handleSuivi_Change}
												onPressEnter={
													handleSuivi_Submit
												}
												value={suiviValue}
												disabled={
													!fichePartner[
														"data_int[IDPartner]"
													]
												}
												placeholder="Appuyez sur 'Entrée' pour poster un commentaire..."
											/>
										</div>
									}
								/>
							</div>
							<div className="scrollIt">
								{partnerSuivi.length
									? partnerSuivi?.map((suivi, index) => {
											return (
												<Comment
													author={
														<div className="suiviHeader">
															<a>
																{
																	suivi.CreateurDenomination
																}
															</a>
															{suivi.DateCreation}
														</div>
													}
													avatar={
														<Avatar
															src={
																suivi?.Avatar
																	? suivi?.Avatar
																	: userImage
															}
															alt={
																suivi.CreateurDenomination
															}
														/>
													}
													content={
														<p>{suivi.Event}</p>
													}
													key={index}
												/>
											);
									  })
									: null}
							</div>
						</Col>
					</Row>
				</Form>
			</div>
		</Spin>
	);
};

export default PartnerFormComponent;
