/* -----------Assets------------ */
import Divider from "antd/lib/divider";
import Form from "antd/lib/form";
import Select from "antd/lib/select";
import Space from "antd/lib/space";
import React from "react";
/* -----------Style Sheet------------ */
import "./antdselect.styles.scss";

const SelectFormComponent = ({
  dataOption,
  disabled,
  loading,
  mode,
  clearable,
  value,
  label,
  name,
  className,
  formlabel,
  rules,
  onSelect,
  placeholder,
  size,
  isDropDownCustom,
  textBtnCustom,
  actionBtnCustom
}) => {
  return (
    <Form.Item
      name={name}
      className={"antdselect " + className}
      label={formlabel}
      rules={rules}
    >
      <Select
        placeholder={placeholder}
        className="selectComponent"
        showArrow={true}
        loading={loading}
        disabled={disabled}
        mode={mode}
        showSearch
        allowClear={clearable}
        onChange={(val, opt) => (onSelect ? onSelect(val, opt, name) : null)}
        size={size ? size : "small"}
        filterOption={(input, option) => {
          if (option.label) {
            return option.label.toLowerCase().includes(input.toLowerCase());
          }
        }}
        dropdownRender={(menu) => (
          <>
            {menu}
            {isDropDownCustom ?
              <div className="customDropDown">
                <Divider style={{ margin: '8px 0' }} />
                <Space style={{ padding: '0 8px 4px' }} >
                  <a onClick={actionBtnCustom}>{textBtnCustom}</a>
                </Space>
              </div> : null}

          </>
        )}
      >
        {dataOption.map((item, i) => {
          let { isAutres } = item;
          return (
            <Select.Option className={isAutres ? "withAutres" : ""} key={i} value={item[value]} label={item[label]}>
              {item[label]}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default SelectFormComponent;
