/* -----------Assets------------ */
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "antd/lib/input";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { Badge } from "antd";
import { useDropzone } from "react-dropzone";
import {
	updateChoosedImg,
	loadFicheToUpdate,
} from "../../../redux/Immobilier/properties/prop.slice";
/* -----------Selectors------------ */
import { ImmoPropImgToUpdateSelector } from "../../../redux/Immobilier/properties/prop.selector";
/* -----------Styles------------ */
import "./styles.scss";

const ImmoPicFormComponent = ({
	cropperRef,
	aspectRatio,
	maxFileSize = 500000,
}) => {
	/* -----------Selectors------------ */
	const imgToUpdate = useSelector(ImmoPropImgToUpdateSelector);
	/* -----------State------------ */
	const [choosedFile, setChoosedFile] = useState(
		imgToUpdate ? [imgToUpdate] : []
	);
	/* -----------UseEffect------------ */
	/* -----------FCTS------------ */
	const imgValidator = (file) => {
		if (file.size > maxFileSize) {
			return {
				code: "grande taille",
				message: `Votre photo ne doit pas dépasser ${
					maxFileSize / 1000
				} Ko`,
			};
		}
	};
	/* -----------VARS------------ */
	const dispatch = useDispatch();
	const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
		useDropzone({
			accept: {
				"image/*": [],
			},
			multiple: false,
			//maxSize: 500000,
			validator: imgValidator,
			onDrop: (acceptedFiles) => {
				dispatch(
					loadFicheToUpdate({
						mediaLegende_FR: acceptedFiles[0].path,
					})
				);
				setChoosedFile(
					acceptedFiles.map((file) =>
						Object.assign(file, {
							preview: URL.createObjectURL(file),
							mediaLegende_FR:
								acceptedFiles[0].path.split(".")[0],
						})
					)
				);
			},
		});
	const onMedialAlt_Change = (e) => {
		dispatch(updateChoosedImg({ mediaLegende_FR: e.target.value }));
		setChoosedFile([
			{
				...choosedFile[0],
				mediaLegende_FR: e.target.value,
			},
		]);
	};
	/* -----------UI Vars------------ */
	return (
		<div className="immoPicFormContainer">
			<Row style={{ height: "100%" }}>
				<Col xs={24} md={24} xl={16}>
					{imgToUpdate?.ID ? (
						<div className="simpleImg">
							<img
								src={imgToUpdate?.mediaSrc}
								alt={imgToUpdate?.mediaLegende_FR}
							/>
						</div>
					) : choosedFile.length === 0 ? (
						<section className="dropzoneContainer">
							<div {...getRootProps({ className: "dropzone" })}>
								<input {...getInputProps()} />
								<i className="bi bi-upload"></i>Drop the file to
								upload
							</div>
						</section>
					) : (
						<Cropper
							ref={cropperRef}
							style={{ height: 400, width: "100%" }}
							aspectRatio={aspectRatio}
							src={
								choosedFile.length === 0
									? []
									: choosedFile[0].preview
							}
							viewMode={1}
							autoCrop={true}
							minCropBoxHeight={117}
							minCropBoxWidth={343}
							background={false}
							responsive={true}
							checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
							guides={true}
						/>
					)}
				</Col>
				<Col xs={24} md={24} xl={7} className="picForm">
					<div className="formItem">
						<label>Légende:</label>
						<Input
							value={choosedFile[0]?.mediaLegende_FR}
							onChange={onMedialAlt_Change}
						/>
					</div>
					{fileRejections.length !== 0 ? (
						<Badge count={fileRejections.length}>
							<div className="rejection">
								<ul>
									{fileRejections.map((item) => {
										return (
											<li>{item.errors[0].message}</li>
										);
									})}
								</ul>
							</div>
						</Badge>
					) : (
						""
					)}
				</Col>
			</Row>
		</div>
	);
};

export default ImmoPicFormComponent;
