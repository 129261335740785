import styled from "styled-components";

export const BordredIconButtonStyle = styled.button`
  color: ${(props) => props.textcolor};
  background-color: ${(props) => props.color};
  margin-right: 10px;
  cursor:pointer;
  border: none;
  border-radius: 0.25rem;
  padding: 2px 10px;
  text-align: right;
  height: 30px;
  &:hover {
    background-color: ${(props) => props.hovercolor} !important;
    color: ${(props) => props.texthovercolor};
  }
  i{
    padding: ${(props) => props.textfirst === "no" ? "0px" : props.textfirst ? "0px 5px 0px 0px" : "0px 5px 0px 0px"};
  }
  
`;
