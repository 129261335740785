import React from "react";
import { useSelector } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import routes from "./Routes";
import { userDetailsSelector } from '../redux/Authentification/authentication.selector';

const NavigationComponent = ({ user }) => {
  /* -----------Selectors------------ */
  let LOGGED_USER = useSelector(userDetailsSelector);
  return (
    <Switch>
      {routes.map((route, index) => {
        let { routes, path, exact } = route;
        return (
          <Route
            key={index}
            path={path}
            exact={exact}
            render={(props) => (
              // pass the sub-routes down to keep nesting
              LOGGED_USER?.IDUser ?
                (
                  path === "/login" ?
                    <Redirect to="/dashboard" />
                    :
                    <route.component {...props} routes={routes} />
                )
                :
                <Redirect to="/login" />
            )}
          />
        );
      })}
    </Switch>
  );
};

export default NavigationComponent;
