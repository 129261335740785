/* -----------Components------------ */
import AuthentificationContainer from './containers/Authentification/component';
import NavigationComponent from './navigations/navigationComponent';
import Sidebar from './components/sidebar/component';
import UserMenu from './components/userMenu/component';
import SelectFilterComponent from './components/filterselect/component';
/* -----------Assets------------ */
import React, { useState } from "react";
import { HashRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import Layout from "antd/lib/layout";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
/* -----------Selectors------------ */
import { userDetailsSelector, userEntitiesSelector } from './redux/Authentification/authentication.selector';
/* -----------Style Sheet------------ */
import './App.scss';
import "@progress/kendo-theme-bootstrap/dist/bootstrap-4.scss";


function App() {
  /* -----------State Hook------------ */
  const [collapsed, setCollapsed] = useState(false);
  /* -----------Vars------------ */
  const { Header, Content, Footer } = Layout;
  /* -----------Selectors------------ */
  let LOGGED_USER = useSelector(userDetailsSelector);
  let userEntit = useSelector(userEntitiesSelector)
  /* -----------Functions------------ */
  const toggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className="App">
      <HashRouter >
        {!LOGGED_USER?.IDUser ?
          <AuthentificationContainer />
          :
          <Layout>
            <Sidebar collapsed={collapsed} />
            <Layout className="site-layout">
              <Header className="site-layout-background" style={{ padding: 0 }}>
                {React.createElement(
                  collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                  {
                    className: "trigger",
                    onClick: toggle,
                  }
                )}
                {/*<SelectFilterComponent
                  defaultValue={parseInt(LOGGED_USER?.IDSelectedEnt)}
                  dataOption={userEntit}
                  fieldLabel="RaisonSociale"
                  value="IDSociete"
                  width='200px'
                  clearable={false}
                />*/}
                <UserMenu />
              </Header>
              <Content
                style={{
                  margin: "24px 16px",
                  minHeight: 280,
                }}
              >
                <NavigationComponent user={LOGGED_USER?.IDUser} />
              </Content>
              <Footer> © 2023 Neelaps - Logiciels de gestion d'entreprise by
                <a href="https://www.elapida.fr/" target="_blank" rel="noreferrer"> Elapida</a></Footer>
            </Layout>
          </Layout>

        }
      </HashRouter>
    </div>
  );
}

export default App;
