import Col from "antd/lib/col";
import Empty from "antd/lib/empty";
import Row from "antd/lib/row";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ImmoPropImgToUpdateSelector } from "../../../redux/Immobilier/properties/prop.selector";
import { clearImageModal } from "../../../redux/Immobilier/properties/prop.slice";
import { addNewsImgAPI } from "../../../redux/WebSIte/news/news.api";
import { ficheNewsSelector } from "../../../redux/WebSIte/news/news.selector";

import ToolbarButtonComponent from "../../button_toolbar/toolbar_button.component";
import UpdatePicModalComponent from "../../modal_updatePIC/component";

import "./NewsPhoto.scss";

const PhotoNewsComponent = ({ addNewsImgTrigger }) => {
	/* -----------State Hook------------ */
	const [popUpState, setPopUpState] = useState(false);

	/* -----------Selector------------ */
	const ficheNews = useSelector(ficheNewsSelector);
	const imgSelector = useSelector(ImmoPropImgToUpdateSelector);

	/* -----------Vars------------ */
	const dispatch = useDispatch();
	const cropperRef = useRef(null);

	/* -----------Functions------------ */
	const CancelPictureUpdate = () => {
		clearPopUpImage();
	};

	const SavePictureUpdate = async () => {
		let croppedBlob = await new Promise(async (resolve) => {
			cropperRef.current?.cropper.getCroppedCanvas().toBlob((blob) => {
				resolve(blob);
			}, "image/jpeg");
		});
		const formData = new FormData();
		formData.append("file[]", croppedBlob, imgSelector["mediaLegende_FR"]);
		formData.append("category", "news");
		formData.append("idObject", ficheNews?.IDNews);
		formData.append("mediaLegende_FR", imgSelector["mediaLegende_FR"]);
		dispatch(addNewsImgAPI(formData, ficheNews?.IDNews, clearPopUpImage));
	};

	const clearPopUpImage = () => {
		dispatch(clearImageModal());
		addNewsImgTrigger();
		setPopUpState(false);
	};

	const addImage = () => {
		setPopUpState(true);
	};

	const onUpdate = () => {
		setPopUpState(true);
	};

	const displayImage = () => {
		if (!ficheNews?.mediaSrc) {
			return (
				<div className="emtycontainer">
					<Empty />
				</div>
			);
		}
		return (
			<Col className="img-col">
				<div className="img_container">
					<img
						id={ficheNews?.mediaSrc}
						src={ficheNews?.mediaSrc}
						alt={ficheNews?.mediaLegende_FR}
					/>
					<div className="btns_tools">
						<ToolbarButtonComponent
							icon="pencil-square"
							title="Modifier l'image"
							className="k-button-solid-info"
							onclick={() => onUpdate()}
						/>
					</div>
					<div className="defaultcontainer">
						<p>{ficheNews.mediaLegende_FR}</p>
					</div>
				</div>
			</Col>
		);
	};

	return (
		<div className="photosNews">
			<Row className="picsContainer" gutter={[16, 24]}>
				{displayImage()}
			</Row>
			{!ficheNews?.mediaSrc ? (
				<div className="header">
					{ficheNews?.IDNews ? (
						<ToolbarButtonComponent
							icon="plus-circle-dotted"
							text="Ajouter"
							title="Ajouter une image"
							className="k-button-solid-primary"
							onclick={addImage}
							disabled={
								!ficheNews?.IDNews ||
								(ficheNews?.IDNews && ficheNews?.mediaSrc)
							}
						/>
					) : (
						<p>
							Enregistrer l'acutalité pour pouvoir ajouter une
							image
						</p>
					)}
				</div>
			) : null}
			<section className="modals">
				{popUpState ? (
					<UpdatePicModalComponent
						title={
							ficheNews?.mediaSrc
								? "Modifier une image"
								: "Ajouter une image"
						}
						popUpState={popUpState}
						handleOk={SavePictureUpdate}
						handleCancel={CancelPictureUpdate}
						width="70vw"
						cropperRef={cropperRef}
						aspectRatio={942 / 476}
						maxFileSize={300000}
					/>
				) : null}
			</section>
			<div className="consignes">
                <ul>
                    <li>Le poids de la photo ne doit pas être supérieur à 300 Ko</li>
                    <li>Pour une présentation optimale, il est préférable d'utiliser des photos d'une taille minimale de 942x476 pixels</li>
                </ul>
            </div>
		</div>
	);
};

export default PhotoNewsComponent;
