/* -----------Assets------------ */
import Modal from "antd/lib/modal";
import { WarningOutlined } from "@ant-design/icons";
/* -----------Style Sheet------------ */
import "./confirmModal.styles.scss";

const ConfirmModalComponent = ({
  title,
  text,
  textInfo,
  popUpState,
  handleOk,
  handleCancel,
}) => {
  return (
    <Modal
      className="confirm_modal"
      title={title ? title : ""}
      open={popUpState}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <div className="container_confirm_modal">
        <WarningOutlined />
        <div className="mainText">
          <p className="firstPg">{text}</p>
          <p className="secondPg">{textInfo}</p>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModalComponent;
