/* -----------Assets------------ */
import React from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/row";
import Input from "antd/lib/input";
import Form from "antd/lib/form";
/* -----------Selectors------------ */
/* -----------Assets------------ */
import "./styles.scss";



const NotesInternes = () => {
    /* -----------Selectors------------ */
    /* -----------Vars------------ */
    const { TextArea } = Input;
    return (
        <div className="notesinternes">
            <Row className="limite">
                <Col span={24}>
                    <Form.Item name="data_str[Notes]">
                        <TextArea
                            allowClear
                            placeholder="Insérer les notes internes"
                            bordered={true}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    )
}

export default NotesInternes
