import moment from "moment";
import MENU_ITEMS from "../../components/sidebar/sidebar.data";
import { logOut } from "../../redux/Authentification/authentication.slice";
import store from "../../redux/store";

export const setCookie = (cookiename, cookievalue, maxAge) => {
    document.cookie = `${cookiename}=${cookievalue}${maxAge ? `; Max-Age=${maxAge}` : ""}`;
}
export const readCookie = (cookieName, isObject) => {
    var result = document.cookie.match(new RegExp(cookieName + "=([^;]+)"));
    if (result) {
        return isObject ? JSON.parse(result[1]) : result[1];
    } else {
        return result;
    }
};
export const removeCookie = (cookieName) => {
    setCookie(cookieName, "", -1);
}
export const logOutUser = () => {
    store.dispatch(logOut());
}

export const FormatDate = (date, format = "YYYY-MM-DD") => {
    if (!date) return undefined;
    if (typeof date === "string") return moment(String(date), format);
    return moment(String(date)).format(format);
};

export const FormatJsDate = (data) => {
    return data.split('T')[0];
}
export const FormatDDMM = (data) => {
    let month = data.split('-')[1];
    let day = data.split('-')[2].split(" ")[0];
    return day + "/" + month
};

export const ObjetToArr = (data) => {
    let arrayResponse = [];
    if (data)
        for (const [key, value] of Object.entries(data)) {
            arrayResponse.push(value)
        }
    return arrayResponse
}

export const getInterfaceSlug = (history, USER_MENU) => {
    let defaut;
    let slug;
    Object.entries(MENU_ITEMS).map((item) => {
        if (item[1].subMenu) {
            item[1].subMenu.map((subitem) => {
                if (subitem.path === history.location.pathname) {
                    defaut = [subitem.ID];
                }
            });
        } else {
            if (item[1].path === history.location.pathname) {
                defaut = [item[1].ID];
            }
        }
    });
    USER_MENU.map(item => {
        if (parseInt(defaut) === item.IDMenu) {
            slug = item.slug
        }
    })
    return slug;
}

