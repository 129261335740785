import { Editor, EditorTools } from "@progress/kendo-react-editor";
import { Col, Form, Input, Menu } from "antd";
import { useEffect, useState } from "react";
import TypicalSentencesList from "../TypicalSentences/TypicalSentencesList";
import "./richTextEditor.scss";

const {
	Bold,
	Italic,
	Underline,
	Strikethrough,
	Subscript,
	Superscript,
	AlignLeft,
	AlignCenter,
	AlignRight,
	AlignJustify,
	Indent,
	Outdent,
	OrderedList,
	UnorderedList,
	Undo,
	Redo,
	Link,
	Unlink,
	InsertImage,
} = EditorTools;

const RichTextEditor = ({
	content,
	title,
	titleLabels,
	textLabels,
	onTextChange,
	onTitleChange,
	onLanguageChange,
	typicalSentences,
	onTypicalSentenceAdd,
}) => {
	const [choosedSidemenuKey, setChoosedSidemenuKey] = useState("fr");
	const [titleLabel, setTitleLabel] = useState(
		titleLabels[choosedSidemenuKey].label
	);
	const [textLabel, setTextLabel] = useState(textLabels[choosedSidemenuKey]);
	const [labelClassName, setlabelColor] = useState("black");

	useEffect(() => {
		setTitleLabel(titleLabels[choosedSidemenuKey].label);
		setTextLabel(textLabels[choosedSidemenuKey]);
	}, [titleLabels, textLabels]);

	useEffect(() => {
		setTitleLabel(titleLabels[choosedSidemenuKey].label);
		setTextLabel(textLabels[choosedSidemenuKey]);
		setlabelColor(choosedSidemenuKey === "fr" ? "black" : "editor-labels");
	}, [choosedSidemenuKey]);

	const onClick = (val) => {
		let { key } = val;
		setChoosedSidemenuKey(key);
		onLanguageChange(key);
	};

	const _onContentChange = (event) => {
		onTextChange(event.html);
	};

	return (
		<div id="richTextEditor">
			<Col className="ficheContent bg-white">
				<Menu
					mode="horizontal"
					selectedKeys={choosedSidemenuKey}
					theme="Light"
					onClick={onClick}
				>
					<Menu.Item key="fr">Français</Menu.Item>
					<Menu.Item key="en">Anglais</Menu.Item>
				</Menu>

				<div className="maincontent">
					<Col>
						<Form.Item
							className={labelClassName}
							label={titleLabel}
						>
							<Input
								value={title}
								size="small"
								onChange={(e) => onTitleChange(e.target.value)}
							/>
						</Form.Item>
					</Col>
					<Col>
						<Form.Item
							label={textLabel}
							className={labelClassName}
						></Form.Item>
						<Editor
							value={content}
							onChange={(e) => _onContentChange(e)}
							defaultEditMode="div"
							tools={[
								[Bold, Italic, Underline, Strikethrough],
								[Subscript, Superscript],
								[
									AlignLeft,
									AlignCenter,
									AlignRight,
									AlignJustify,
								],
								[Indent, Outdent],
								[OrderedList, UnorderedList],
								[Undo, Redo],
								[Link, Unlink, InsertImage],
							]}
							contentStyle={{
								height: 300,
							}}
						/>
					</Col>
				</div>
				{typicalSentences && typicalSentences.length > 0 && (
					<div id="typicalSentences" className="ms-3 mb-1">
						<div className="submodule_Title">Phrases types</div>
						<TypicalSentencesList
							sentences={typicalSentences}
							onClick={onTypicalSentenceAdd}
						/>
					</div>
				)}
			</Col>
		</div>
	);
};

export default RichTextEditor;
