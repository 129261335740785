import axios from "axios";
import { environment } from "../environment";
import { readCookie } from "../assets/functions";



let api = () => {
  if (readCookie("token", false)) {
    axios.defaults.headers["token"] = readCookie("token", false);
  }
  if (readCookie("IDSelectedEnt", false)) {
    axios.defaults.headers["Idselectedent"] = readCookie("IDSelectedEnt", false);
  }
  return axios.create({
    baseURL: environment.BASE_URL_API,
    headers: {
      Accept: "application/json, */*",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    credentials: "same-origin"
  });
};

export default api;
