import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { loadDataGridUtil, LoadFicherTiersContactUtil, loadFicheTiersUtil, updateFicheContactUtil, updateFicheTiersUtil } from "./contacts.util";

const initialState = {
    dataGrid: [],
    dataGridLoader: false,
    columnNames: [
        { title: "Dénomination", field: "Denomination" },
        { title: "Type", field: "IDType" },
        { title: "Adresse", field: "Adresse" },
        { title: "Code Postal", field: "CodePostal", isNumeric: true },
        { title: "Ville", field: "Ville" },
        { title: "Téléphone", field: "Telephone", isNumeric: true },
        { title: "Mail", field: "Mail" }
    ],
    selectedContactRow: {},
    ficheTiers: {},
    tiersSuivi: [],
    ficheTiersLoader: false,
    formFicheTiers: { 'data_str[CompanyType]': "PARTICULIER", 'data_int[IDPays]': 250 },
    contactList: [],
    contactFiche: {},
    ficheContactLoader: false,
};

const contacts = createSlice({
    name: "contacts",
    initialState,
    reducers: {
        loadDataGrid: (state, { payload }) => {
            state.dataGrid = loadDataGridUtil(payload[0], payload[1]);
        },
        dataGridLoader: (state, { payload }) => {
            state.dataGridLoader = payload;
        },
        setSlectedContactRow: (state, { payload }) => {
            state.selectedContactRow = payload;
        },
        loadFicheTiers: (state, { payload }) => {
            let clonedContacts = _.cloneDeep(state.dataGrid);
            state.ficheTiers = payload;
            state.formFicheTiers = loadFicheTiersUtil(payload, clonedContacts);
            state.contactList = LoadFicherTiersContactUtil(payload)
        },
        updateFicheTiers: (state, { payload }) => {
            let clonedFiche = _.cloneDeep(state.formFicheTiers);
            state.formFicheTiers = updateFicheTiersUtil(payload, clonedFiche);
        },
        initFicheTiers: (state, { payload }) => {
            state.formFicheTiers = initialState.formFicheTiers;
            state.ficheTiers = initialState.ficheTiers;
            state.contactList = initialState.contactList;
            state.tiersSuivi = initialState.tiersSuivi
        },
        ficheTiersLoader: (state, { payload }) => {
            state.ficheTiersLoader = payload;
        },
        loadTiersSuivi: (state, { payload }) => {
            state.tiersSuivi = payload;
        },
        loadFicheContact: (state, { payload }) => {
            state.contactFiche = payload;
        },
        updateFicheContact: (state, { payload }) => {
            let clonedFiche = _.cloneDeep(state.contactFiche);
            state.contactFiche = updateFicheContactUtil(payload, clonedFiche);
        },
        clearFicheContact: (state, { payload }) => {
            state.contactFiche = initialState.contactFiche;
        },
        FicheContactLoader: (state, { payload }) => {
            state.ficheContactLoader = payload;
        }
    },
});

export const { loadDataGrid, dataGridLoader, setSlectedContactRow, loadFicheTiers, clearFicheContact, updateFicheTiers, ficheTiersLoader, initFicheTiers, loadTiersSuivi, loadFicheContact, updateFicheContact, FicheContactLoader } = contacts.actions;

export default contacts.reducer;
