/* -----------Components------------ */
import ToolbarButtonComponent from '../../../components/button_toolbar/toolbar_button.component';
import GenericModalComponent from '../../../components/generic_modal/component';
import HeaderComponent from '../../../components/header/component';
import ModalFooterComponent from '../../../components/modal_Footer/component';
import ImmoConfigFormComponent from '../../../components/modal_config_immo/component';
import ConfirmModalComponent from '../../../components/modal_confirm/confirmModal.component';
import TableComponent from "../../../components/table/component";
/* -----------Assets------------ */
import Form from "antd/lib/form";
import Tabs from "antd/lib/tabs";
import Tag from 'antd/lib/tag';
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import API_ENDPOINT from '../../../api/api_endpoint';
import { LoadImmoConfigAPI, addConfigImmoAPI, deleteConfigImmoAPI, updateConfigImmoAPI } from '../../../redux/Immobilier/config/config.api';
import { initFicheConfigImmo, setSlectedImmoConfigRow } from '../../../redux/Immobilier/config/config.slice';
/* -----------Selectors------------ */
import { columnNamesSelector, columnNamesTypicalSentencesSelector, columnNavNamesSelector, columnRubanNamesSelector, dataGridLoaderSelector, dataGridSelector, ficheImmoConfigFormSelector, selectedImmoConfigSelector } from '../../../redux/Immobilier/config/config.selector';
/* -----------Style Sheet------------ */
import './styles.scss';

const ImmoConfigContainer = () => {
    /* -----------selectors------------ */
    const columnNames = useSelector(columnNamesSelector);
    const columnNavNames = useSelector(columnNavNamesSelector);
    const columnRubanNames = useSelector(columnRubanNamesSelector);
    const columnNamesTypicalSentences = useSelector(columnNamesTypicalSentencesSelector);
    const dataGrid = useSelector(dataGridSelector);
    const dataGridLoader = useSelector(dataGridLoaderSelector);
    const selectedRow = useSelector(selectedImmoConfigSelector);
    const ficheData = useSelector(ficheImmoConfigFormSelector);
    /* -----------State Hook------------ */
    const [modalTitle, setModalTitle] = useState("Type de bien");
    const [modalVisible, setModalVisible] = useState(false);
    const [confirmVisible, setConfirmVisible] = useState(false);
    /* -----------vars------------ */
    const dispatch = useDispatch();
    const { TabPane } = Tabs;
    const [immoConfigForm] = Form.useForm();
    /* -----------Effect Hook------------ */
    useEffect(() => {
        dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_TYPE_BIEN, false))
    }, []);
    useEffect(() => {
        immoConfigForm.setFieldsValue(ficheData)
    }, [ficheData]);

    /* -----------Functions------------ */
    const onTabs_change = (e) => {
        switch (e) {
            case "1":
                setModalTitle("Type de bien")
                dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_TYPE_BIEN, false))
                break;
            case "2":
                dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_FAMILLES, false))
                setModalTitle("Famille")
                break;
            case "3":
                dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_CATEG, false))
                setModalTitle("Catégories")
                break;
            case "4": ;
                dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_SECTEUR, false))
                setModalTitle("Secteur Géographique")
                break;
            case "5":
                dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_PTSFORT, false))
                setModalTitle("Points Forts")
                break;
            case "6":
                dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_EQUIP, false))
                setModalTitle("Equipements")
                break;
            case "7":
                dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_STYLB, false))
                setModalTitle("Styles de bien")
                break;
            case "8":
                dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_SERVICES, false))
                setModalTitle("Services")
                break;
            case "9":
                dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_STATUT, false))
                setModalTitle("Statuts")
                break;
            case "10":
                dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_INFOP, false))
                setModalTitle("Ruban Info Produits")
                break;
            case "11":
                dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_TYPICAL_SENTENCES, false))
                setModalTitle("Phrases Types")
                break;
            default:
                break;
        }
    }
    const oneClickHandler = (e) => {
        if (selectedRow?.ID === e?.ID) {
            dispatch(setSlectedImmoConfigRow({}))
        } else {
            dispatch(setSlectedImmoConfigRow(e))
        }
    }
    const doubleClickHandler = (e) => {
        const { dataItem: { IDPropertyType, IDPropertyFamille, IDPropertyArea, IDPropertyPF, IDPropertyEquipment, IDPropertyStyle, IDPropertyStatut, IDPropertyTag, IDPropertyCategory, IDPropertyService, IDPhrase } } = e
        switch (modalTitle) {
            case "Type de bien":
                dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_TYPE_BIEN + "/" + IDPropertyType, true, true));
                break;
            case "Famille":
                dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_FAMILLES + "/" + IDPropertyFamille, true, true));
                break;
            case "Catégories":
                dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_CATEG + "/" + IDPropertyCategory, true, true));
                break;
            case "Secteur Géographique":
                dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_SECTEUR + "/" + IDPropertyArea, true, true))
                break;
            case "Points Forts":
                dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_PTSFORT + "/" + IDPropertyPF, true, true))
                break;
            case "Equipements":
                dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_EQUIP + "/" + IDPropertyEquipment, true, true))
                break;
            case "Styles de bien":
                dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_STYLB + "/" + IDPropertyStyle, true, true))
                break;
            case "Services":
                dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_SERVICES + "/" + IDPropertyService, true, true));
                break;
            case "Statuts":
                dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_STATUT + "/" + IDPropertyStatut, true, true))
                break;
            case "Ruban Info Produits":
                dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_INFOP + "/" + IDPropertyTag, true, true))
                break;
            case "Phrases Types":
                dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_TYPICAL_SENTENCES + "/" + IDPhrase, true, true));
                break;
            default:
                break;
        }
        setModalVisible(!modalVisible)
    }
    const modal_handleOk = () => {
        if (ficheData['data_int[ID]']) {
            let payload = _.cloneDeep(ficheData)
            switch (modalTitle) {
                case "Type de bien":
                    payload['data_int[IDPropertyType]'] = payload['data_int[ID]'];
                    delete payload['data_int[ID]'];
                    dispatch(updateConfigImmoAPI(API_ENDPOINT.CONFIG_TYPE_BIEN, payload, closeClearFiche, refresh_grid));
                    break;
                case "Famille":
                    payload['data_int[IDPropertyFamille]'] = payload['data_int[ID]'];
                    payload['data_int[OnlineMenu]'] = payload['data_int[OnlineMenu]'] ? 1 : -1;
                    delete payload['data_int[ID]'];
                    dispatch(updateConfigImmoAPI(API_ENDPOINT.CONFIG_FAMILLES, payload, closeClearFiche, refresh_grid));
                    break;
                case "Catégories":
                    payload['data_int[IDPropertyCategory]'] = payload['data_int[ID]'];
                    delete payload['data_int[ID]'];
                    dispatch(updateConfigImmoAPI(API_ENDPOINT.CONFIG_CATEG, payload, closeClearFiche, refresh_grid));
                    break;
                case "Secteur Géographique":
                    payload['data_int[IDPropertyArea]'] = payload['data_int[ID]'];
                    delete payload['data_int[ID]'];
                    dispatch(updateConfigImmoAPI(API_ENDPOINT.CONFIG_SECTEUR, payload, closeClearFiche, refresh_grid));
                    break;
                case "Points Forts":
                    payload['data_int[IDPropertyPF]'] = payload['data_int[ID]'];
                    delete payload['data_int[ID]'];
                    dispatch(updateConfigImmoAPI(API_ENDPOINT.CONFIG_PTSFORT, payload, closeClearFiche, refresh_grid));
                    break;
                case "Equipements":
                    payload['data_int[IDPropertyEquipment]'] = payload['data_int[ID]'];
                    delete payload['data_int[ID]'];
                    dispatch(updateConfigImmoAPI(API_ENDPOINT.CONFIG_EQUIP, payload, closeClearFiche, refresh_grid));
                    break;
                case "Styles de bien":
                    payload['data_int[IDPropertyStyle]'] = payload['data_int[ID]'];
                    delete payload['data_int[ID]'];
                    dispatch(updateConfigImmoAPI(API_ENDPOINT.CONFIG_STYLB, payload, closeClearFiche, refresh_grid));
                    break;
                case "Services":
                    payload['data_int[IDPropertyService]'] = payload['data_int[ID]'];
                    delete payload['data_int[ID]'];
                    dispatch(updateConfigImmoAPI(API_ENDPOINT.CONFIG_SERVICES, payload, closeClearFiche, refresh_grid));
                    break;
                case "Statuts":
                    payload['data_int[IDPropertyStatut]'] = payload['data_int[ID]'];
                    delete payload['data_int[ID]'];
                    dispatch(updateConfigImmoAPI(API_ENDPOINT.CONFIG_STATUT, payload, closeClearFiche, refresh_grid));
                    break;
                case "Ruban Info Produits":
                    payload['data_int[IDPropertyTag]'] = payload['data_int[ID]'];
                    delete payload['data_int[ID]'];
                    dispatch(updateConfigImmoAPI(API_ENDPOINT.CONFIG_INFOP, payload, closeClearFiche, refresh_grid))
                    break;
                case "Phrases Types":
                    payload['data_int[IDPhrase]'] = payload['data_int[ID]'];
                    delete payload['data_int[ID]'];
                    dispatch(updateConfigImmoAPI(API_ENDPOINT.CONFIG_TYPICAL_SENTENCES, payload, closeClearFiche, refresh_grid))
                    break;
                default:
                    break;
            }
        } else {
            let payload = _.cloneDeep(ficheData)
            switch (modalTitle) {
                case "Type de bien":
                    dispatch(addConfigImmoAPI(API_ENDPOINT.CONFIG_TYPE_BIEN, ficheData, closeClearFiche, refresh_grid));
                    break;
                case "Famille":
                    payload['data_int[OnlineMenu]'] = payload['data_int[OnlineMenu]'] ? 1 : -1;
                    dispatch(addConfigImmoAPI(API_ENDPOINT.CONFIG_FAMILLES, payload, closeClearFiche, refresh_grid));
                    break;
                case "Catégories":
                    dispatch(addConfigImmoAPI(API_ENDPOINT.CONFIG_CATEG, payload, closeClearFiche, refresh_grid));
                    break;
                case "Secteur Géographique":
                    dispatch(addConfigImmoAPI(API_ENDPOINT.CONFIG_SECTEUR, payload, closeClearFiche, refresh_grid));
                    break;
                case "Points Forts":
                    dispatch(addConfigImmoAPI(API_ENDPOINT.CONFIG_PTSFORT, payload, closeClearFiche, refresh_grid));
                    break;
                case "Equipements":
                    dispatch(addConfigImmoAPI(API_ENDPOINT.CONFIG_EQUIP, payload, closeClearFiche, refresh_grid));
                    break;
                case "Styles de bien":
                    dispatch(addConfigImmoAPI(API_ENDPOINT.CONFIG_STYLB, payload, closeClearFiche, refresh_grid));
                    break;
                case "Services":
                    dispatch(addConfigImmoAPI(API_ENDPOINT.CONFIG_SERVICES, payload, closeClearFiche, refresh_grid));
                    break;
                case "Statuts":
                    dispatch(addConfigImmoAPI(API_ENDPOINT.CONFIG_STATUT, payload, closeClearFiche, refresh_grid));
                    break;
                case "Ruban Info Produits":
                    dispatch(addConfigImmoAPI(API_ENDPOINT.CONFIG_INFOP, payload, closeClearFiche, refresh_grid))
                    break;
                case "Phrases Types":
                    dispatch(addConfigImmoAPI(API_ENDPOINT.CONFIG_TYPICAL_SENTENCES, payload, closeClearFiche, refresh_grid))
                    break;
                default:
                    break;
            }
        }
    }
    const modal_handleCancel = () => {
        closeClearFiche()
    }
    const addElement_trigger = () => {
        setModalVisible(!modalVisible)
    }
    const deletElement_trigger = () => {
        setConfirmVisible(!confirmVisible)
    }
    const confirm_delete = () => {
        switch (modalTitle) {
            case "Type de bien":
                dispatch(deleteConfigImmoAPI(API_ENDPOINT.CONFIG_TYPE_BIEN + "/" + selectedRow?.IDPropertyType, delete_callback));
                break;
            case "Famille":
                dispatch(deleteConfigImmoAPI(API_ENDPOINT.CONFIG_FAMILLES + "/" + selectedRow?.IDPropertyFamille, delete_callback));
                break;
            case "Catégories":
                dispatch(deleteConfigImmoAPI(API_ENDPOINT.CONFIG_CATEG + "/" + selectedRow?.IDPropertyCategory, delete_callback));
                break;
            case "Secteur Géographique":
                dispatch(deleteConfigImmoAPI(API_ENDPOINT.CONFIG_SECTEUR + "/" + selectedRow?.IDPropertyArea, delete_callback));
                break;
            case "Points Forts":
                dispatch(deleteConfigImmoAPI(API_ENDPOINT.CONFIG_PTSFORT + "/" + selectedRow?.IDPropertyPF, delete_callback));
                break;
            case "Equipements":
                dispatch(deleteConfigImmoAPI(API_ENDPOINT.CONFIG_EQUIP + "/" + selectedRow?.IDPropertyEquipment, delete_callback));
                break;
            case "Styles de bien":
                dispatch(deleteConfigImmoAPI(API_ENDPOINT.CONFIG_STYLB + "/" + selectedRow?.IDPropertyStyle, delete_callback));
                break;
            case "Services":
                dispatch(deleteConfigImmoAPI(API_ENDPOINT.CONFIG_SERVICES + "/" + selectedRow?.IDPropertyService, delete_callback));
                break;
            case "Statuts":
                dispatch(deleteConfigImmoAPI(API_ENDPOINT.CONFIG_STATUT + "/" + selectedRow?.IDPropertyStatut, delete_callback));
                break;
            case "Ruban Info Produits":
                dispatch(deleteConfigImmoAPI(API_ENDPOINT.CONFIG_INFOP + "/" + selectedRow?.IDPropertyTag, delete_callback));
                break;
            case "Phrases Types":
                dispatch(deleteConfigImmoAPI(API_ENDPOINT.CONFIG_TYPICAL_SENTENCES + "/" + selectedRow?.IDPhrase, delete_callback));
                break;
            default:
                break;
        }
    }
    const delete_callback = () => {
        refresh_grid(true)
        setConfirmVisible(!confirmVisible)
        dispatch(setSlectedImmoConfigRow({}))
    }
    const cancel_delete = () => {
        setConfirmVisible(!confirmVisible)
    }
    const closeClearFiche = () => {
        setModalVisible(!modalVisible)
        dispatch(initFicheConfigImmo())
        immoConfigForm.resetFields();
    }
    const refresh_grid = (hideSc, isFiche) => {
        switch (modalTitle) {
            case "Type de bien":
                dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_TYPE_BIEN, hideSc, isFiche));
                break;
            case "Famille":
                dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_FAMILLES, hideSc, isFiche))
                break;
            case "Catégories":
                dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_CATEG, hideSc, isFiche))
                break;
            case "Secteur Géographique":
                dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_SECTEUR, hideSc, isFiche))
                break;
            case "Points Forts":
                dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_PTSFORT, hideSc, isFiche))
                break;
            case "Equipements":
                dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_EQUIP, hideSc, isFiche))
                break;
            case "Styles de bien":
                dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_STYLB, hideSc, isFiche))
                break;
            case "Services":
                dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_SERVICES, hideSc, isFiche))
                break;
            case "Statuts":
                dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_STATUT, hideSc, isFiche))
                break;
            case "Ruban Info Produits":
                dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_INFOP, hideSc, isFiche))
                break;
            case "Phrases Types":
                dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_TYPICAL_SENTENCES, hideSc, isFiche))
                break;
            default:
                break;
        }
    }
    /* -----------UI------------ */
    const gridToolbar = (
        <div className="toolbar_utils">
            <div className="toolbar_icns">
            </div>
            <div className='toolbar_icns2'>
                <ToolbarButtonComponent
                    icon="trash"
                    title="Supprimer un élément"
                    className="k-button-solid-error"
                    onclick={deletElement_trigger}
                    disabled={!selectedRow?.ID}
                />
                <ToolbarButtonComponent
                    icon="arrow-clockwise"
                    title="Actualiser"
                    className="k-button-outline-secondary"
                    onclick={() => { refresh_grid(false) }}
                />
                <ToolbarButtonComponent
                    icon="plus-circle-dotted"
                    text="Ajouter"
                    title="Ajouter un élément"
                    className="k-button-solid-primary"
                    onclick={addElement_trigger}
                />
            </div>
        </div>
    );
    const cellRender = (td, props) => {
        let { field, dataItem } = props;
        let nodeElt;
        if (field && field === "OnlineMenu") {
            nodeElt =
                <Tag color={dataItem[field] === 1 ? "green" : "red"}>
                    {dataItem[field] === 1 ? "OUI" : "NON"}
                </Tag>
            return React.cloneElement(td, td.props, nodeElt);
        } else if (field && ["BgColor", "TextColor"].includes(field)) {
            nodeElt = <span className='customCell' style={{ backgroundColor: dataItem[field] }}></span>
            return React.cloneElement(td, td.props, nodeElt);
        } else if (field && ["Phrase_fr", "Phrase_en"].includes(field)) {
            nodeElt = <span dangerouslySetInnerHTML={{__html: dataItem[field]}} className="typical-sentence-cell"/>
            return React.cloneElement(td, td.props, nodeElt);
        } else {
            return td
        }
    }
    /* -----------render------------ */
    return (
        <div className="immoConfig">
            <section className="header">
                <HeaderComponent title="Immobilier - Configuration" icon="gear" />
            </section>
            <section className="page_container">
                <div className="main_content">
                    <div className="table-responsive">
                        <Tabs tabPosition="left" onChange={onTabs_change}>
                            <TabPane tab="Type de bien" key="1">
                                <TableComponent
                                    handleRowDoubleClick={doubleClickHandler}
                                    gridlist={dataGrid}
                                    columnNames={columnNames}
                                    loader={dataGridLoader}
                                    gridToolBar={gridToolbar}
                                    handleRowClick={oneClickHandler}
                                    selectedID={selectedRow}
                                />
                            </TabPane>
                            <TabPane tab="Famille" key="2">
                                <TableComponent
                                    handleRowDoubleClick={doubleClickHandler}
                                    gridlist={dataGrid}
                                    columnNames={columnNavNames}
                                    loader={dataGridLoader}
                                    gridToolBar={gridToolbar}
                                    handleRowClick={oneClickHandler}
                                    selectedID={selectedRow}
                                    cellRender={cellRender}
                                />
                            </TabPane>
                            <TabPane tab="Catégories" key="3">
                                <TableComponent
                                    handleRowDoubleClick={doubleClickHandler}
                                    gridlist={dataGrid}
                                    columnNames={columnNames}
                                    loader={dataGridLoader}
                                    gridToolBar={gridToolbar}
                                    handleRowClick={oneClickHandler}
                                    selectedID={selectedRow}
                                />
                            </TabPane>
                            <TabPane tab="Secteur Géographique" key="4">
                                <TableComponent
                                    handleRowDoubleClick={doubleClickHandler}
                                    gridlist={dataGrid}
                                    columnNames={columnNames}
                                    loader={dataGridLoader}
                                    gridToolBar={gridToolbar}
                                    handleRowClick={oneClickHandler}
                                    selectedID={selectedRow}
                                />
                            </TabPane>
                            <TabPane tab="Points Forts" key="5">
                                <TableComponent
                                    handleRowDoubleClick={doubleClickHandler}
                                    gridlist={dataGrid}
                                    columnNames={columnNames}
                                    loader={dataGridLoader}
                                    gridToolBar={gridToolbar}
                                    handleRowClick={oneClickHandler}
                                    selectedID={selectedRow}
                                />
                            </TabPane>
                            <TabPane tab="Equipements" key="6">
                                <TableComponent
                                    handleRowDoubleClick={doubleClickHandler}
                                    gridlist={dataGrid}
                                    columnNames={columnNames}
                                    loader={dataGridLoader}
                                    gridToolBar={gridToolbar}
                                    handleRowClick={oneClickHandler}
                                    selectedID={selectedRow}
                                />
                            </TabPane>
                            <TabPane tab="Styles de bien" key="7">
                                <TableComponent
                                    handleRowDoubleClick={doubleClickHandler}
                                    gridlist={dataGrid}
                                    columnNames={columnNames}
                                    loader={dataGridLoader}
                                    gridToolBar={gridToolbar}
                                    handleRowClick={oneClickHandler}
                                    selectedID={selectedRow}
                                />
                            </TabPane>
                            <TabPane tab="Services" key="8">
                                <TableComponent
                                    handleRowDoubleClick={doubleClickHandler}
                                    gridlist={dataGrid}
                                    columnNames={columnNames}
                                    loader={dataGridLoader}
                                    gridToolBar={gridToolbar}
                                    handleRowClick={oneClickHandler}
                                    selectedID={selectedRow}
                                />
                            </TabPane>
                            <TabPane tab="Statuts" key="9">
                                <TableComponent
                                    handleRowDoubleClick={doubleClickHandler}
                                    gridlist={dataGrid}
                                    columnNames={columnNames}
                                    loader={dataGridLoader}
                                    gridToolBar={gridToolbar}
                                    handleRowClick={oneClickHandler}
                                    selectedID={selectedRow}
                                />
                            </TabPane>
                            <TabPane tab="Ruban Info Produits" key="10">
                                <TableComponent
                                    handleRowDoubleClick={doubleClickHandler}
                                    gridlist={dataGrid}
                                    columnNames={columnRubanNames}
                                    loader={dataGridLoader}
                                    gridToolBar={gridToolbar}
                                    handleRowClick={oneClickHandler}
                                    selectedID={selectedRow}
                                    cellRender={cellRender}
                                />
                            </TabPane>
                            <TabPane tab="Phrases Types" key="11">
                                <TableComponent
                                    handleRowDoubleClick={doubleClickHandler}
                                    gridlist={dataGrid}
                                    columnNames={columnNamesTypicalSentences}
                                    loader={dataGridLoader}
                                    gridToolBar={gridToolbar}
                                    handleRowClick={oneClickHandler}
                                    selectedID={selectedRow}
                                    cellRender={cellRender}
                                />
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </section>
            <section className="modals">
                {modalVisible &&
                    <GenericModalComponent
                        title={modalTitle}
                        popUpState={modalVisible}
                        handleOk={modal_handleOk}
                        handleCancel={modal_handleCancel}
                        footer={ModalFooterComponent}
                    >
                        <ImmoConfigFormComponent form={immoConfigForm} ficheData={ficheData} addFormItem={modalTitle} />
                    </GenericModalComponent>
                }
                {confirmVisible ?
                    <ConfirmModalComponent
                        title='Confirmation de suppression'
                        text='Voulez vous supprimer cet élément ?'
                        popUpState={confirmVisible}
                        handleOk={confirm_delete}
                        handleCancel={cancel_delete} /> : null}
            </section>
        </div>
    )
}

export default ImmoConfigContainer
