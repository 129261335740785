/* -----------Components------------ */
import LoginCardComponent from '../../components/LoginCard/component';
/* -----------Assets------------ */
import React from "react";
import { useDispatch } from "react-redux";
import logo from "../../assets/images/logoB.png";
import about_app from "../../assets/images/about_app.PNG";
import { useHistory } from "react-router-dom";
import Form from "antd/lib/form";
import { authenticationAPI } from '../../redux/Authentification/authentication.api';
/* -----------Style Sheet------------ */
import './styles.scss'

const AuthentificationContainer = () => {
    /* -----------Vars------------ */
    const dispatch = useDispatch();
    const history = useHistory();
    const [loginForm] = Form.useForm();
    /* -----------Functions------------ */
    const login_fct = () => {
        loginForm.validateFields().then(data => {
            dispatch(authenticationAPI(data, history))
        })
    }
    /* -----------render------------ */
    return (
        <div className="authComponent">
            <div className="navbar_login">
                <img src={logo} alt="" />
            </div>
            <div className="mainContainer">
                <div className="isoLeft">
                    <div className="content">
                        <section className="title">
                            <h1>Restez connectés</h1>
                            <h4>Où vous voulez, quand vous voulez.</h4>
                            <div>
                                Une solution multiplateforme disponible en version web (pas de téléchargement requis), sur mobile (iOS, Android) et Desktop.
                            </div>
                        </section>
                        <img src={about_app} alt="" />
                    </div>

                </div>
                <div className="isoRight" xs={24} xl={8}>
                    <LoginCardComponent on_login={login_fct} form={loginForm} />
                </div>
            </div>
            <div className="footer_login">
                <div>
                    © 2022 Cobra - Logiciels de gestion d'entreprise by
                    <a href="https://www.elapida.fr/" target="_blank" rel="noreferrer"> Elapida</a>
                </div>
            </div>
        </div>
    )
}

export default AuthentificationContainer
