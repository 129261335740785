import styled from "styled-components";
import Button from "antd/lib/button";

export const ToolbarButtonStyle = styled(Button)`
  font-size: .875rem;
  font-weight: 500;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "")};
  &:hover {
    color: #FFFFFF;
  }
  &:focus{
        box-shadow: none;
      }
`;
