import Col from "antd/lib/col";
import Divider from "antd/lib/divider";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Row from "antd/lib/row";
import Spin from "antd/lib/spin";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { contactFicheLoaderSelector, tiersCompleteFicheSelector } from "../../../redux/contacts/contacts.selector";

import { AutoComplete, Radio } from "antd";
import { countriesListSelector } from "../../../redux/Authentification/authentication.selector";
import { searchForContacts } from "../../../redux/contacts/contacts.api";
import { clearFicheContact } from "../../../redux/contacts/contacts.slice";
import { getCoordinatesFromAddress } from "../../../utils";
import ToolbarButtonComponent from "../../button_toolbar/toolbar_button.component";
import SelectFormComponent from "../../select_input_form/antdselect.component";
import "./styles.scss";
import { contactTypes } from "../contacts/component";

const FicheContact = ({ form, isSelected: isExistingContact = false, onContactsForm_change }) => {
    const loader = useSelector(contactFicheLoaderSelector);
    const currentTiers = useSelector(tiersCompleteFicheSelector);
    const countriesList = useSelector(countriesListSelector);
    const dispatch = useDispatch();

    const { TextArea } = Input;

    const [contactList, setContactList] = useState([])
    const [selectedContact, setSelectedContact] = useState(false)
    const [isCompany, setIsCompany] = useState(false)

    useEffect(() => {
        return () => {
            clearTimeout(timeoutRef.current);
        }
    }, [])

    useEffect(() => {
        if (form.getFieldValue('CompanyType') === contactTypes[0].value) {
            setIsCompany(true)
        }

        onContactsForm_change({
            "CompanyType": form.getFieldValue('CompanyType'),
        })
    }, [form.getFieldValue('CompanyType')])


    const timeoutRef = useRef(null);
    const SEARCH_DEBOUNCE_DURATION = 200;

    const onContactSearch = async(e) => {
        clearTimeout(timeoutRef.current);

        timeoutRef.current = setTimeout(() => {
            const search = e.target.value;
            if (search && search.length < 3) {
                return;
            };
            const currentContactIds = currentTiers && currentTiers.length ? Object.keys(currentTiers?.TiersAsContact) : [];
            currentContactIds.push(currentTiers?.IDTiers.toString() ?? "")
            
            searchForContacts(search).then((res) => {
                setContactList(Object.values(res ?? {})?.filter(c => !currentContactIds.includes(c.IDTiers.toString())) ?? [])
            })
        }, SEARCH_DEBOUNCE_DURATION);
    }

    const onContactSelect = (selectedContactName) => {
        const contact = contactList.filter((c) => c.tiersDenomination === selectedContactName)[0];
        setSelectedContact(true)
        onContactsForm_change({
            "IDContact": contact.IDTiers ?? "",
            "Nom": contact.Nom ?? "",
            "Prenom": contact.Prenom ?? "",
            "Denomination": contact.Denomination ?? "",
            "ContactFonction": "",
            "Telephone": contact.Telephone ?? "",
            "Mail": contact.Mail ?? "",
            "Ville": contact.Ville ?? "",
            "CodePostal": contact.CodePostal ?? "",
            "FormattedAddress": contact.FormatedAddress ?? "",
            "Adresse": contact.Adresse ?? "",
            "IDPays": contact.IDPays ?? "",
            "contactObs": "",
            "CompanyType": contact.CompanyType ?? contactTypes[1].value,
        })
        setIsCompany(contact.CompanyType === contactTypes[0].value)
    }

    const clearContactForm = () => {
        form.resetFields();
        onContactsForm_change({})
        setSelectedContact(false);
        dispatch(clearFicheContact());
        setContactList([]);
        setAddresses([]);
    }

    // Address
    const DEBOUNCE_DURATION = 700;

    const [adresses, setAddresses] = useState([]);

    useEffect(() => {
        const getData = setTimeout(() => {
            const search = form.getFieldValue("FormattedAddress")
            if (!search || search.length < 3) {
                setAddresses([]);
                return;
            }
            getCoordinatesFromAddress(search).then((res) => {
                if (res?.length > 0) {
                    setAddresses(res);
                }
            });
        }, DEBOUNCE_DURATION);

        return () => clearTimeout(getData);
    }, [
        form.getFieldValue("FormattedAddress")
    ]);

    const onAdressSelect = (e) => {
        const selectedAdress = adresses.filter((a) => a.formatted === e)[0];
        if (selectedAdress) {
            const internalCountry = countriesList.filter(
                (c) =>
                    c?.country_code?.toLowerCase() ===
                    selectedAdress?.country_code?.toLowerCase()
            )[0];
            onContactsForm_change({
                "FormattedAddress": selectedAdress.formatted,
                "Adresse": selectedAdress.address_line1,
                "CodePostal": selectedAdress.postcode,
                "Ville": selectedAdress.city,
                "Pays": selectedAdress.country,
                "IDPays": internalCountry?.IDPays,
                "Country_Code": selectedAdress.country_code,
            })
        }
    };

    const onCountrieSelect = (country) => {
        const internalCountry = countriesList.filter((c) => c?.IDPays === country)[0];
        onContactsForm_change({
            "Pays": internalCountry.country,
            "IDPays": internalCountry?.IDPays,
            "Country_Code": internalCountry.country_code,
        })
    }

    return (
        <Spin spinning={loader} size="large">
            <div className="ficheContact">
                <Form form={form} onValuesChange={onContactsForm_change}>
                    {!isExistingContact && 
                        <Row>
                            <Col md={24} xl={12} className="pb-5" >
                                <Form.Item label="Recherche" name="search">
                                    <AutoComplete
                                        options={contactList?.map((contact) => ({
                                            key: contact.IDTiers,
                                            value: contact.tiersDenomination,
                                            label: contact.tiersDenomination,
                                        }))}
                                        style={{ width: "100%" }}
                                        onSelect={onContactSelect}
                                        allowClear={false}
                                    >
                                        <Input
                                            size="small"
                                            label="Chercher un contact"
                                            addonAfter={
                                                <i
                                                    className={`bi bi-search`}
                                                    style={{ fontSize: "14px" }}
                                                />
                                            }
                                            controls={false}
                                            onChange={onContactSearch}
                                        />
                                    </AutoComplete>
                                </Form.Item>
                            </Col>
                            <Col md={24} xl={12}>
                                <ToolbarButtonComponent
                                    icon="trash"
                                    title="Nettoyer le formulaire"
                                    className="k-button-solid-error"
                                    onclick={clearContactForm}
                                />
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col md={12} xl={14}>
                            <Form.Item name="CompanyType" initialValue={contactTypes[1].value} >
                                <Radio.Group
                                    disabled={selectedContact}
                                    className="ant-radio-button"
                                    options={contactTypes}
                                    optionType="button"
                                    buttonStyle="solid"
                                    size="small"
                                    onChange={(e) => setIsCompany(e.target.value === contactTypes[0].value)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        {isCompany ?
                            <Col md={24} xl={12} >
                                <Form.Item label="Denomination" name="Denomination">
                                    <Input size="small" readOnly={selectedContact} />
                                </Form.Item>
                            </Col>
                            :
                            <>
                                <Col md={24} xl={12} >
                                    <Form.Item label="Nom" name="Nom">
                                        <Input size="small" readOnly={selectedContact} />
                                    </Form.Item>
                                </Col>
                                <Col md={24} xl={12} >
                                    <Form.Item label="Prénom" name="Prenom">
                                        <Input size="small" readOnly={selectedContact} />
                                    </Form.Item>
                                </Col>
                            </>
                        }
                    </Row>
                    <Row>
                        <Col md={24} xl={12} >
                            <Form.Item label="Fonction" name="contactFonction">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24} xl={12} >
                            <Form.Item label="Tél." name="Telephone">
                                <Input size="small" readOnly={selectedContact} />
                            </Form.Item>
                        </Col>
                        <Col md={24} xl={12} >
                            <Form.Item label="Mail" name="Mail">
                                <Input size="small" readOnly={selectedContact} />
                            </Form.Item>
                        </Col>
                    </Row>
                    {!(selectedContact) &&
                        <Row>
                            <Col md={24} xl={12} >
                            <Form.Item name="FormattedAddress" label="Adresse">
                                <AutoComplete
                                    options={adresses?.map((address) => ({
                                        key: address.place_id,
                                        value: address.formatted,
                                        label: address.formatted,
                                    }))}
                                    style={{ width: "100%" }}
                                    onSelect={onAdressSelect}
                                    allowClear={false}
                                >
                                    <Input
                                        placeholder="Recherche d'adresse complète"
                                        addonAfter={
                                            <i
                                                className={`bi bi-search`}
                                                style={{ fontSize: "14px" }}
                                            />
                                        }
                                        controls={false}
                                    />
                                </AutoComplete>
                            </Form.Item>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col md={24} xl={12} >
                            <Form.Item label="Adresse" name="Adresse" className="w-100">
                                <Input size="large" readOnly={selectedContact}/>
                            </Form.Item>
                        </Col>
                        <Col md={24} xl={12} >
                            <Form.Item label="Pays" className="w-100">
                                <SelectFormComponent
                                    size="small"
                                    name="IDPays"
                                    onSelect={onCountrieSelect}
                                    dataOption={countriesList}
                                    value="IDPays"
                                    label="Pays"
                                    disabled={selectedContact}
                                    clearable={!selectedContact} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24} xl={12} >
                            <Form.Item label="Code postal" name="CodePostal">
                                <Input  size="small" readOnly={selectedContact} />
                            </Form.Item>
                        </Col>
                        <Col md={24} xl={12} >
                            <Form.Item label="Ville" name="Ville">
                                <Input size="small" readOnly={selectedContact} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider orientation="left" plain >
                        Observations
                    </Divider>
                    <Row className="limite">
                        <Col span={24}>
                            <Form.Item name="contactObs">
                                <TextArea
                                    autoSize={{ minRows: 3, maxRows: 3 }}
                                    placeholder="Observation par rapport au contact"
                                    bordered={false}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Spin>
    );
};

export default FicheContact;
