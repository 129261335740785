import { Checkbox as KendoCheckbox } from "@progress/kendo-react-inputs";
import { useEffect, useState } from "react";

const Checkbox = ({
	handleCheckboxChange,
	property,
	checkedRows = [],
	amountOfRows = 0,
}) => {
	const [checked, setChecked] = useState(false);

	const isChecked = () => {
		if (property.IDProperty) {
			setChecked(
				checkedRows.some(
					(prop) => prop.IDProperty === property.IDProperty
				)
			);
		} else {
			if (checkedRows.length === 0) {
				setChecked(false);
			} else if (checkedRows.length === amountOfRows) {
				setChecked(true);
			} else {
				setChecked(null);
			}
		}
	};

	useEffect(() => {
		isChecked();
	}, [property, checkedRows, amountOfRows]);

	return (
		<td className="d-flex justify-content-center w-100">
			<KendoCheckbox
				value={checked}
				onChange={(e) => handleCheckboxChange(e, property)}
			/>
		</td>
	);
};

export default Checkbox;
