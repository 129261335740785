import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./root-reducer";
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';


const persistConfig = {
  key: 'root',
  storage: storage,
};

export default configureStore({
  // devTools: false,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ immutableCheck: false }),
  reducer: persistReducer(persistConfig, rootReducer),
});
