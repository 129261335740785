import React from 'react'
import Drawer from 'antd/lib/drawer';
import "./styles.scss";


const GenericDrawer = ({
    title,
    size,
    onClose,
    visible,
    extra,
    children,
    handleOk,
    handleCancel,
    handlePrevious,
    handleNext,
    footer
}) => {
    return (
        <Drawer
            className="generic_drawer"
            contentWrapperStyle={{ width: size }}
            headerStyle={{ padding: "10px 20px" }}
            title={title}
            placement="right"
            size={size}
            onClose={handleCancel}
            open={visible}
            footer={footer}
            extra={extra}
        >
            {children}
        </Drawer>
    )
}

export default GenericDrawer
