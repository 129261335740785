import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import {
	loadDataGridUtil,
	loadFicheNewsUtil,
	updateFicheNewsUtil
} from "./news.util";

const initialState = {
	columnNames: [
		{ title: "Statut", field: "Statut" },
		{ title: "Date Publication", field: "DatePublication" },
		{ title: "Titre", field: "Titre_fr" },
	],
	dataGrid: [],
	dataGridLoader: false,
	selectedNewsRow: {},
	ficheNews: {},
	ficheNewsForm: {},
	ficheNewsLoader: false,
	newsSuivi: {}
};

const news = createSlice({
	name: "news",
	initialState,
	reducers: {
		loadDataGrid: (state, { payload }) => {
			state.dataGrid = loadDataGridUtil(payload);
		},
		initDatagrid: (state, { payload }) => {
			state.dataGrid = initialState.dataGrid;
		},
		setSelectedNewsRow: (state, { payload }) => {
			state.selectedNewsRow = payload;
		},
		setDataGrid: (state, { payload }) => {
			state.dataGrid = payload;
		},
		dataGridLoader: (state, { payload }) => {
			state.dataGridLoader = payload;
		},
		loadFicheNews: (state, { payload }) => {
			state.ficheNews = payload;
			state.ficheNewsForm = loadFicheNewsUtil(payload);
		},
		initFicheNews: (state) => {
			state.ficheNews = initialState.ficheNews;
			state.ficheNewsForm = initialState.ficheNewsForm;
			state.newsSuivi = initialState.newsSuivi
		},
		ficheNewsLoader: (state, { payload }) => {
			state.ficheNewsLoader = payload;
		},
		updateFicheNews: (state, { payload }) => {
			let clonedFiche = _.cloneDeep(state.ficheNewsForm);
			state.ficheNewsForm = updateFicheNewsUtil(
				payload,
				clonedFiche
			);
		},
		loadNewsSuivi: (state, { payload }) => {
            state.newsSuivi = payload;
        },
	},
});

export const {
	loadDataGrid,
	initDatagrid,
	setSelectedNewsRow,
	setDataGrid,
	dataGridLoader,
	loadFicheNews,
	initFicheNews,
	ficheNewsLoader,
	updateFicheNews,
	loadNewsSuivi,
} = news.actions;

export default news.reducer;
