export const userPropSelector = (state) => state.referentiel.proprietaires;
export const propTypesSelector = (state) => state.referentiel.propTypes;
export const propStylesSelector = (state) => state.referentiel.propStyles;
export const propStatutsSelector = (state) => state.referentiel.propStatuts;
export const propTagsSelector = (state) => state.referentiel.propTags;
export const propFamsSelector = (state) => state.referentiel.propFams;
export const propCategSelector = (state) => state.referentiel.propCateg;
export const propEquipSelector = (state) => state.referentiel.propEquip;
export const propPtsFortSelector = (state) => state.referentiel.propPtsForts;
export const propServicesSelector = (state) => state.referentiel.propServices;
export const propSecteursSelector = (state) => state.referentiel.propSecteurs;