/* -----------Component------------ */
import BordredIconButtonComponent from "../button_border_icon/bordred_icon_button.component";
/* -----------styles------------ */
import "./styles.scss";

const NextFooterComponent = ({ handleOk_Drawer, handleNext_Drawer, handlePrevious_Drawer, nextCdt, previousCdt, isUpdate, handle_duplic }) => {
    return (
        <div className="next_footer_container">
            <div className="next">
                {
                    isUpdate ?
                        <>
                            <BordredIconButtonComponent
                                titre="Enregistrement précédent"
                                className="k-button-solid-base"
                                clickEvent={handlePrevious_Drawer}
                                icon="chevron-left"
                                textfirst={false}
                                disabled={!previousCdt}
                            />
                            <BordredIconButtonComponent
                                titre="Enregistrement suivant"
                                className="k-button-solid-base"
                                clickEvent={handleNext_Drawer}
                                icon="chevron-right"
                                textfirst={true} disabled={!nextCdt}
                            />
                        </>
                        : null
                }

            </div>
            <div className="regular">
                {/*isUpdate ?
                    <BordredIconButtonComponent text="Dupliquer " clickEvent={handle_duplic} className="k-button-solid-primary" icon="back" textfirst={false} />
                    : null*/}
                <BordredIconButtonComponent
                    className="k-button-solid-primary"
                    text="Enregistrer "
                    clickEvent={handleOk_Drawer}
                    icon="save2"
                    textfirst={false}
                />
            </div>
        </div>
    );
};

export default NextFooterComponent;
