const API_ENDPOINT = {
	/*
	 ** Authentication **
	 */
	AUTHENTICATE: "authenticate",
	/*
	 ** References **
	 */
	LOAD_COUNTRIES: "countries",
	LOAD_SUIVI: "suivi",
	LOAD_CONTACTS_TYPE: "tiers-types",
	LOAD_STATUS: "properties-statuts",
	LOAD_TAGS: "properties-tags",
	LOAD_FAM: "properties-families",
	MEDIAS: "medias",
	/*
	 ** Contacts **
	 */
	LOAD_DATA_GRID_CONTACT: "tiers",
	LOAD_CONTACTS: "tiers-contacts",
	LOAD_ALL_COMPTE: "accounting-chart-of-accounts",
	LOAD_CAT_VENTES: "recipe-category",
	LOAD_CAT_DEPENSES: "expense-category",
	LOAD_BANK_ACCOUNTS: "tiers-accounts",
	/*
	 ** CONFIG Immobilier **
	 */
	CONFIG_TYPE_BIEN: "properties-types",
	CONFIG_FAMILLES: "properties-families",
	CONFIG_CATEG: "properties-categories",
	CONFIG_SECTEUR: "properties-area",
	CONFIG_PTSFORT: "properties-points-forts",
	CONFIG_EQUIP: "properties-equipments",
	CONFIG_STYLB: "properties-styles",
	CONFIG_STATUT: "properties-statuts",
	CONFIG_INFOP: "properties-tags",
	CONFIG_TYPICAL_SENTENCES: "properties-phrases",
	CONFIG_SERVICES: "properties-services",
	LOAD_COLUMNS: "api-columns",
	/*
	 ** PROP Immobilier **
	 */
	LOAD_IMMO_PROP: "properties",
	EXPORT: "export",
	/*
	 ** PARTNERS Website
	 */
	PARTNERS: "partners",
	/*
	 ** NEWS Website
	 */
	NEWS: "news",
};

export default API_ENDPOINT;
