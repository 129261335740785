/* -----------Component------------ */
/* -----------Assets------------ */
import React, { useState } from "react";
import Modal from "antd/lib/modal";
import Draggable from "react-draggable";
/* -----------Style Sheet------------ */
import "./styles.scss";

const GenericModalComponent = ({
  title,
  popUpState,
  handleOk,
  handleCancel,
  width,
  children,
  footer,
  localAction,
  slug
}) => {
  /* -----------State Hook------------ */
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  /* -----------Vars------------ */
  const draggleRef = React.createRef();
  const titre = (
    <div
      style={{
        width: "100%",
        cursor: "move",
      }}
      onMouseOver={() => (disabled ? setDisabled(false) : "")}
      onMouseOut={() => {
        setDisabled(true);
      }}
    >
      {title}
    </div>
  );
  const modalRender = (modal) => (
    <Draggable
      disabled={disabled}
      bounds={bounds}
      onStart={(event, uiData) => onStart(event, uiData)}
    >
      <div ref={draggleRef}>{modal}</div>
    </Draggable>
  );
  /* -----------Functions------------ */
  const onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    });
  };

  return (
    <Modal
      title={titre}
      visible={popUpState}
      onOk={handleOk}
      onCancel={handleCancel}
      centered
      width={width}
      maskClosable={false}
      modalRender={modalRender}
      footer={footer({ handleOk, handleCancel, localAction, slug })}
    >
      {children}
    </Modal>
  );
};

export default GenericModalComponent;
