


export const loadDataGridUtil = (data) => {
    const rubanTagPos = [
        { label: "En haut à gauche", value: "ribbon-top-left" },
        { label: "En haut à droite", value: "ribbon-top-right" },
        { label: "En bas à gauche", value: "ribbon-bottom-left" },
        { label: "En bas à droite", value: "ribbon-bottom-right" }
    ];
    return data[0]?.TagSide ?
        data.map((item, i) => {
            return {
                ...item,
                ID: i + 1,
                TagSide: rubanTagPos.find(x => x.value === item?.TagSide).label
            }
        })
        :
        data.map((item, i) => {
            return {
                ...item,
                ID: i + 1,
            }
        })
}
export const loadFicheConfigImmoUtil = (data) => {
    let fiche = {};

    if (data?.IDPropertyType) fiche['data_int[ID]'] = data?.IDPropertyType;
    if (data?.IDPropertyArea) fiche['data_int[ID]'] = data?.IDPropertyArea;
    if (data?.IDPropertyPF) fiche['data_int[ID]'] = data?.IDPropertyPF;
    if (data?.IDPropertyEquipment) fiche['data_int[ID]'] = data?.IDPropertyEquipment;
    if (data?.IDPropertyStyle) fiche['data_int[ID]'] = data?.IDPropertyStyle;
    if (data?.IDPropertyStatut) fiche['data_int[ID]'] = data?.IDPropertyStatut;
    if (data?.IDPropertyCategory) fiche['data_int[ID]'] = data?.IDPropertyCategory;
    if (data?.IDPropertyService) fiche['data_int[ID]'] = data?.IDPropertyService;
    if (data?.IDPropertyTag) {
        fiche['data_int[ID]'] = data?.IDPropertyTag;
        fiche['data_str[TagSide]'] = data?.TagSide;
        fiche['data_str[TextColor]'] = data?.TextColor;
        fiche['data_str[BgColor]'] = data?.BgColor;
    }
    if (data?.IDPropertyFamille) {
        fiche['data_int[ID]'] = data?.IDPropertyFamille;
        fiche['data_int[OnlineMenu]'] = data?.OnlineMenu === 1 ? true : false;
    }

    fiche['data_str[Libelle_fr]'] = data?.Libelle_fr;
    fiche['data_str[Libelle_en]'] = data?.Libelle_en;

    if (data?.IDPhrase) {   
        fiche['data_int[ID]'] = data?.IDPhrase;
        fiche['data_str[Phrase_title]'] = data?.Phrase_title;
        fiche['data_str[Phrase_fr]'] = data?.Phrase_fr;
        fiche['data_str[Phrase_en]'] = data?.Phrase_en;
    }
    return fiche
}
export const updateFicheImmoConfigUtil = (editedData, fiche) => {
    for (const [key, value] of Object.entries(editedData)) {
        fiche[key] = value;
    }

    return fiche
}
