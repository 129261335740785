
export const columnNamesSelector = (state) => state.immoConfig.columnNames;
export const columnNavNamesSelector = (state) => state.immoConfig.columnNavNames;
export const columnRubanNamesSelector = (state) => state.immoConfig.columnRubanNames;
export const columnNamesTypicalSentencesSelector = (state) => state.immoConfig.columnNamesTypicalSentences;
export const dataGridSelector = (state) => state.immoConfig.dataGrid;
export const dataGridLoaderSelector = (state) => state.immoConfig.dataGridLoader;
export const selectedImmoConfigSelector = (state) => state.immoConfig.selectedImmoConfigRow;
export const ficheImmoConfigFormSelector = (state) => state.immoConfig.ficheConfigImmoForm;
export const loaderficheImmoConfigSelector = (state) => state.immoConfig.ficheConfigImmoLoader;
