import axios from "axios";
import API_ENDPOINT from "../../../api/api_endpoint";
import { deleteApi, getApi, postApi, putApi } from "../../../api/api_methods";
import {
	customerror,
	customsuccess,
} from "../../../components/icon_notification/customNotifications";
import { environment } from "../../../environment";
import {
	dataGridLoader,
	ficheNewsLoader,
	initDatagrid,
	loadDataGrid,
	loadFicheNews,
	loadNewsSuivi,
} from "./news.slice";

export function LoadNewsAPI(hideSccs) {
	return async (dispatch) => {
		dispatch(initDatagrid({}));
		dispatch(dataGridLoader(true));
		getApi(API_ENDPOINT.NEWS)
			.then((res) => {
				let response = res.data ? res.data : [];
				dispatch(dataGridLoader(false));
				dispatch(loadDataGrid(response));
				if (!hideSccs)
					customsuccess("Actualités chargées avec succès!", "");
			})
			.catch((err) => {
				dispatch(dataGridLoader(false));
				dispatch(ficheNewsLoader(false));
				customerror("Erreur de chargement des Actualités", "");
			});
	};
}

export function updateNewsAPI(payload, updateNewsCallback) {
	return async (dispatch) => {
		dispatch(ficheNewsLoader(true));
		putApi(API_ENDPOINT.NEWS, payload)
			.then((res) => {
				updateNewsCallback(res.data);
				dispatch(ficheNewsLoader(false));
				customsuccess(
					"L'enregistrement a été correctement effectué",
					""
				);
			})
			.catch((err) => {
				customerror("Erreur d'enregistrement des données", "");
				dispatch(ficheNewsLoader(false));
			});
	};
}
export function addNewsAPI(payload, addNewsCallback) {
	return async (dispatch) => {
		dispatch(ficheNewsLoader(true));
		postApi(API_ENDPOINT.NEWS, payload)
			.then((res) => {
				addNewsCallback(res?.data);
				dispatch(ficheNewsLoader(false));
				customsuccess(
					"L'enregistrement a été correctement effectué",
					""
				);
			})
			.catch((err) => {
				customerror("Erreur d'enregistrement du partenaire", "");
				dispatch(ficheNewsLoader(false));
			});
	};
}
export function deleteNewsAPI(newsId, callback) {
	return async (dispatch) => {
		deleteApi(API_ENDPOINT.NEWS + "/" + newsId)
			.then((res) => {
				callback();
				customsuccess(
					"La suppresion a été correctement  effectuée",
					""
				);
			})
			.catch((err) => {
				customerror("Erreur de suppresion des données", "");
			});
	};
}

export function LoadNewsSuiviAPI(newsId) {
	return async (dispatch) => {
		dispatch(ficheNewsLoader(true));
		getApi(API_ENDPOINT.LOAD_SUIVI, { object: "news", idobject: newsId })
			.then((res) => {
				let response = res.data ? res.data : [];
				dispatch(loadNewsSuivi(response));
				dispatch(ficheNewsLoader(false));
			})
			.catch((err) => {
				customerror("Erreur de chargement de la liste de suivi", "");
			});
	};
}

export function SaveNewsSuiviAPI(payload, newsId, setSuiviValue) {
	return async (dispatch) => {
		dispatch(ficheNewsLoader(true));
		postApi(API_ENDPOINT.LOAD_SUIVI, payload)
			.then((res) => {
				dispatch(LoadNewsSuiviAPI(newsId));
				setSuiviValue("");
				dispatch(ficheNewsLoader(false));
			})
			.catch((err) => {
				customerror("Erreur d'enregistrement des données", "");
			});
	};
}

export function LoadFicheNewsAPI(newsId) {
	return async (dispatch) => {
		dispatch(ficheNewsLoader(true));
		getApi(API_ENDPOINT.NEWS + "/" + newsId)
			.then((res) => {
				dispatch(ficheNewsLoader(false));
				dispatch(loadFicheNews(res.data));
			})
			.catch((err) => {
				customerror("Erreur de chargement du partenaire", "");
			});
	};
}

export function addNewsImgAPI(formData, payload2, callback) {
	return async (dispatch) => {
		axios
			.post(
				environment.BASE_URL_API +
					"/" +
					API_ENDPOINT.MEDIAS +
					"?action=upload",
				formData
			)
			.then((res) => {
				customsuccess("Opération terminée avec succès!  ", "");
				callback();
				dispatch(LoadFicheNewsAPI(payload2, true));
			})
			.catch((err) => {
				customerror("Erreur d'enregistrement des données" + err, "");
			});
	};
}

export function updateNewsImgAPI(payload, payload2, callback) {
	return async (dispatch) => {
		putApi(API_ENDPOINT.MEDIAS + "?action=upload", payload)
			.then((res) => {
				customsuccess("Opération terminée avec succès!  ", "");
				callback();
				dispatch(LoadFicheNewsAPI(payload2, true));
			})
			.catch((err) => {
				customerror("Erreur d'enregistrement des données", "");
			});
	};
}
