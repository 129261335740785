import API_ENDPOINT from "../../api/api_endpoint";
import { deleteApi, getApi, postApi, putApi } from "../../api/api_methods";
import { ObjetToArr } from "../../assets/functions";
import { customerror, customsuccess } from "../../components/icon_notification/customNotifications";
import { FicheContactLoader, dataGridLoader, ficheTiersLoader, loadDataGrid, loadFicheContact, loadFicheTiers, loadTiersSuivi } from "./contacts.slice";

export function LoadDataGridAPI(payload, contactType, hidemsgSccs) {
    return async (dispatch) => {
        dispatch(dataGridLoader(true));
        getApi(API_ENDPOINT.LOAD_DATA_GRID_CONTACT, payload)
            .then((res) => {
                let response = res.data.result ? [] : res.data;
                dispatch(dataGridLoader(false));
                dispatch(loadDataGrid([ObjetToArr(response), contactType]));
                if (!hidemsgSccs)
                    customsuccess('Contacts chargés avec succès!  ', "")
            })
            .catch((err) => {
                customerror("Erreur de chargement des données", "");
            });
    };
}
export function LoadFicheTiersAPI(payload, hidemsgSccs) {
    return async (dispatch) => {
        dispatch(ficheTiersLoader(true))
        getApi(API_ENDPOINT.LOAD_DATA_GRID_CONTACT + "/" + payload)
            .then((res) => {
                dispatch(ficheTiersLoader(false))
                dispatch(loadFicheTiers(res.data[payload]))
                if (!hidemsgSccs)
                    customsuccess('Fiche chargée avec succès', "")
            })
            .catch((err) => {
                customerror("Erreur de chargement des données", "");
            })
    };
}
export function LoadFicheContactAPI(payload, idTiers) {
    return async (dispatch) => {
        dispatch(FicheContactLoader(true))
        getApi(API_ENDPOINT.LOAD_CONTACTS + "/" + payload + "?idtiers=" + idTiers)
            .then((res) => {
                dispatch(loadFicheContact({...res.data, IDContact: payload}))
                setTimeout(() => {
                    dispatch(FicheContactLoader(false))
                }, 100);
            })
            .catch((err) => {
                customerror("Erreur de chargement du contact", "");
            });
    };
}
export async function searchForContacts(payload) {
    return getApi(API_ENDPOINT.LOAD_DATA_GRID_CONTACT + "?action=search&argument=" + payload?.trim())
        .then((res) => {
            return res.data ?? {};
        })
        .catch((err) => {
            customerror("Erreur de recherche d'un contact", "");
        });
}
export function deleteContactAPI(contactID, tiersID, delete_callback) {
    return async (dispatch) => {
        dispatch(ficheTiersLoader(true))
        deleteApi(API_ENDPOINT.LOAD_CONTACTS + "?idTiers=" + tiersID + "&idContact=" + contactID)
            .then((res) => {
                delete_callback()
                dispatch(LoadFicheTiersAPI(tiersID, true))
                dispatch(ficheTiersLoader(false))
            })
            .catch((err) => {
                customerror("Erreur suppression du contact", "");
            });
    };
}
export function addContactAPI(payload, clearCloseContactForm, idTiers) {
    return async (dispatch) => {
        dispatch(ficheTiersLoader(true))
        postApi(API_ENDPOINT.LOAD_CONTACTS, payload)
            .then((res) => {
                clearCloseContactForm()
                dispatch(LoadFicheTiersAPI(idTiers, true))
                dispatch(ficheTiersLoader(false))
            })
            .catch((err) => {
                dispatch(ficheTiersLoader(false))
                customerror("Erreur d'enregistrement du contact", "");
            });
    };
}
export function updateContactAPI(payload, clearCloseContactForm, idTiers) {
    return async (dispatch) => {
        dispatch(ficheTiersLoader(true))
        putApi(API_ENDPOINT.LOAD_CONTACTS, payload)
            .then((res) => {
                clearCloseContactForm()
                dispatch(LoadFicheTiersAPI(idTiers, true))
                dispatch(ficheTiersLoader(false))
            })
            .catch((err) => {
                customerror("Erreur d'enregistrement du contact", "");
            });
    };
}
export function saveTiersAPI(payload, close_clearDrawer, contactTypeList, isNotReload) {
    return async (dispatch) => {
        postApi(API_ENDPOINT.LOAD_DATA_GRID_CONTACT, payload)
            .then((res) => {
                customsuccess('Opération terminée avec succès!  ', "")
                close_clearDrawer(res);
                if (!isNotReload)
                    dispatch(LoadDataGridAPI({ atype: "all" }, contactTypeList), true);
            })
            .catch((err) => {
                dispatch(dataGridLoader(false));
                customerror("Erreur d'enregistrement des données", "");
            });
    };
}
export function updateTiersAPI(payload, close_clearDrawer, contactTypeList) {
    return async (dispatch) => {
        putApi(API_ENDPOINT.LOAD_DATA_GRID_CONTACT, payload)
            .then((res) => {
                customsuccess('Opération terminée avec succès!  ', "")
                close_clearDrawer();
                dispatch(LoadDataGridAPI({ atype: "all" }, contactTypeList, true));
            })
            .catch((err) => {
                dispatch(dataGridLoader(false));
                customerror("Erreur d'enregistrement des données", "");
            });
    };
}
export function deleteTiersAPI(payload, clearDrawer, contactTypeList) {
    return async (dispatch) => {
        deleteApi(API_ENDPOINT.LOAD_DATA_GRID_CONTACT + "/" + payload)
            .then((res) => {
                clearDrawer()
                dispatch(LoadDataGridAPI({ atype: "all" }, contactTypeList, true));
                customsuccess('Opération terminée avec succès!  ', "")
            })
            .catch((err) => {
                customerror("Erreur de suppression des données", "");
            });
    };
}
export function LoadTiersSuiviAPI(payload) {
    return async (dispatch) => {
        getApi(API_ENDPOINT.LOAD_SUIVI, payload)
            .then((res) => {
                if (res.data.result === "Aucun résultat")
                    dispatch(loadTiersSuivi([]))
                else
                    dispatch(loadTiersSuivi(res.data))
            })
            .catch((err) => {
                customerror("Erreur de chargement de la liste du suivi", "");
            });
    }
}
export function AddTiersSuiviAPI(payload, payload2, setSuiviValue) {
    return async (dispatch) => {
        dispatch(ficheTiersLoader(true))
        postApi(API_ENDPOINT.LOAD_SUIVI, payload)
            .then((res) => {
                dispatch(LoadTiersSuiviAPI(payload2))
                setSuiviValue("")
                dispatch(ficheTiersLoader(false))
            })
            .catch((err) => {
                customerror("Erreur d'enregistrement du Suivi", "");
            });
    };
}
