/* -----------Components------------ */
import BordredIconButtonComponent from '../button_border_icon/bordred_icon_button.component';
/* -----------Assets------------ */
import { EyeInvisibleOutlined, EyeTwoTone, UserOutlined } from '@ant-design/icons';
import Card from "antd/lib/card";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import React from 'react';
/* -----------Style Sheet------------ */
import "./styles.scss";



const LoginCardComponent = ({ on_login, form }) => {

    /* -----------Vars------------ */
    /* -----------Functions------------ */
    /* -----------render------------ */
    return (
        <Card className="loginCardComponent">
            <section className="title">
                SE CONNECTER À VOTRE COMPTE
            </section>
            <Form className="mainForm" form={form}>
                <Form.Item
                    name="login"
                    rules={[{ required: true, message: "Insérer votre nom d'utilisateur" }]}
                >
                    <Input size="large" placeholder="Nom d'utilisateur" prefix={<UserOutlined />} onPressEnter={on_login} />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: "Insérer votre mot de passe" }]}
                >
                    <Input.Password
                        size="large"
                        placeholder="Mot de passe"
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        onPressEnter={on_login}
                    />
                </Form.Item>
            </Form>
            <BordredIconButtonComponent
                text="Se connecter"
                className="k-button-solid-primary"
                clickEvent={on_login}
                textfirst={false}
            />
        </Card>

    )
}

export default LoginCardComponent
