/* -----------Assets------------ */
import React from "react";
/* -----------Style Sheet------------ */
import { BordredIconButtonStyle } from "./bordred_icon_button.styles";

const BordredIconButtonComponent = ({
  color,
  hovercolor,
  textcolor,
  icon,
  text,
  texthovercolor,
  clickEvent,
  loading,
  disabled,
  textfirst,
  className
}) => {
  return (
    <BordredIconButtonStyle
      hovercolor={hovercolor}
      textcolor={textcolor}
      texthovercolor={texthovercolor}
      color={color}
      onClick={clickEvent}
      disabled={disabled}
      style={{ cursor: disabled ? "not-allowed" : "" }}
      className={className}
      textfirst={textfirst}
    >
      {textfirst ? text : null}

      {loading ? (
        <>
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Loading...</span>
        </>
      ) : (
        <i className={`bi bi-${icon}`}></i>
      )}
      {!textfirst ? text : null}
    </BordredIconButtonStyle>
  );
};

export default BordredIconButtonComponent;
