/* -----------Assets------------ */
import logo from "../../assets/images/logoFull.png";
import logo2 from "../../assets/images/logo.png";
/* -----------Style Sheet------------ */
import './styles.scss'

const SidebarLogo = ({ collapsed }) => {

    /* -----------render------------ */
    return (
        <div className="sidebar_logo" style={{ padding: collapsed ? "0 0 0 20px" : "0 10px" }}>
            {collapsed ?
                <img src={logo2} alt="" />
                :
                <img src={logo} alt="" />

            }
        </div>
    );
};

export default SidebarLogo;