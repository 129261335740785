export const loadDataGridUtil = (data) => {
	return data.map((item, i) => {
		return {
			...item,
			ID: i + 1,
		};
	});
};

export const loadFichePartnerUtil = (data) => {
	let fiche = {};
	if (data?.IDPartner) fiche["data_int[IDPartner]"] = data?.IDPartner;
	if (data?.partnerName) fiche["data_str[partnerName]"] = data?.partnerName;
	if (data?.partnerWebsite)
		fiche["data_str[partnerWebsite]"] = data?.partnerWebsite;
	if (data?.mediaSrc) fiche["data_str[mediaSrc]"] = data?.mediaSrc;
	return fiche;
};

export const updateFichePartnerUtil = (editedData, fiche) => {
	for (const [key, value] of Object.entries(editedData)) {
		fiche[key] = value;
	}

	return fiche;
};
