/* -----------Components------------ */
import UpdatePicModalComponent from "../../modal_updatePIC/component";
import ToolbarButtonComponent from "../../button_toolbar/toolbar_button.component";
/* -----------Assets------------ */
import React, { useRef } from 'react';
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Empty from "antd/lib/empty";
import Checkbox from "antd/lib/checkbox";
import _ from "lodash";
/* -----------Selectors------------ */
import { ImmoPropFormPicsSelector, ImmoPropFormSelector, ImmoPropImgToUpdateSelector } from "../../../redux/Immobilier/properties/prop.selector";
/* -----------Style Sheet------------ */
import "./styles.scss";
import { clearImageModal, loadFicheToUpdate, updateFiche } from "../../../redux/Immobilier/properties/prop.slice";
import { addImgImmoAPI, deleteImgImmoAPI, updateImgImmoAPI } from "../../../redux/Immobilier/properties/prop.api";
import ConfirmModalComponent from "../../modal_confirm/confirmModal.component";









const PhotosImmoGestPropComponent = () => {
    /* -----------State Hook------------ */
    const [popUpState, setPopUpState] = useState(false);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);
    /* -----------Selector------------ */
    const imgsList = useSelector(ImmoPropFormPicsSelector);
    const immoPropForm = useSelector(ImmoPropFormSelector);
    const imgToUpdate = useSelector(ImmoPropImgToUpdateSelector);
    /* -----------Selector------------ */
    useEffect(() => {

    }, [])
    /* -----------Vars------------ */
    const dispatch = useDispatch();
    const cropperRef = useRef(null);
    /* -----------Functions------------ */
    const editImg_handler = (file, index) => {
        //setChoosedFile({ ...file, index })
        setPopUpState(true)
    }
    const handleCancel_UpdatePIC = () => {
        clearPopUpImage()
    }
    const handleSave_UpdatePIC = async () => {
        if (!imgToUpdate?.ID) {
            let croppedBlob = await new Promise(async (resolve) => {
                cropperRef.current?.cropper.getCroppedCanvas().toBlob((blob) => {
                    resolve(blob);
                }, "image/jpeg");
            });
            //croppedBlob['name'] = cropperRef['mediaLegende_FR']
            const formData = new FormData();
            formData.append("file[]", croppedBlob, imgToUpdate['mediaLegende_FR']);
            formData.append("category", "property");
            formData.append("idObject", immoPropForm['data_int[IDProperty]']);
            formData.append("mediaLegende_FR", imgToUpdate['mediaLegende_FR']);
            dispatch(addImgImmoAPI(formData, immoPropForm['data_int[IDProperty]'], clearPopUpImage))
        } else {
            let payload = _.cloneDeep(imgToUpdate);
            payload['IDMedia'] = payload.ID;
            delete payload.ID;
            delete payload.mediaSrc;
            dispatch(updateImgImmoAPI(payload, immoPropForm['data_int[IDProperty]'], clearPopUpImage))
        }

    }
    const clearPopUpImage = () => {
        dispatch(clearImageModal())
        setPopUpState(false)
    }
    const addImage_handler = () => {
        setPopUpState(true)
    }
    const mettreEnAvant_handler = (e) => {
        dispatch(updateFiche({ 'data_int[IDImg]': e }))
    }
    const onUpdate_Trigger = (file) => {
        dispatch(loadFicheToUpdate({ ...file }))
        setPopUpState(true)
    }
    const ondelete_Trigger = (id) => {
        setIdToDelete(id)
        setConfirmVisible(!confirmVisible)
    }
    const confirm_delete = () => {
        dispatch(deleteImgImmoAPI(idToDelete, immoPropForm['data_int[IDProperty]']))
        setConfirmVisible(!confirmVisible)
    }
    const cancel_delete = () => {
        setConfirmVisible(!confirmVisible)
    }
    return (
        <div className="photosImmoGestProp">
            <div className="consignes">
                <ul>
                    <li>Vous pouvez stocker 10 photos maximum, la photo de mise en avant sera visible sur le site en premier.</li>
                    <li>Vous pouvez sélectionner votre image de mise en avant au moyen des radios boutons situés sous les images.</li>
                    <li>Le poids de la photo ne doit pas être supérieur à 500 Ko</li>
                    <li>Pour une présentation optimale, il est préférable d'utiliser des photos d'une taille minimale de 943x717 pixels </li>
                </ul>
            </div>
            <div className="header">
                {imgsList.length + 1 > 10 ?
                    <span className="maxPics">Maximum de téléchargement atteint (10)</span>
                    : <div />}
                <ToolbarButtonComponent
                    icon="plus-circle-dotted"
                    text="Ajouter"
                    title="Ajouter une nouvelle image"
                    className="k-button-solid-primary"
                    onclick={addImage_handler}
                    disabled={(imgsList.length + 1 > 10) || !immoPropForm['data_int[IDProperty]']}
                />
            </div>
            <Row className="picsContainer" gutter={[16, 24]}>
                {imgsList.length === 0 ?
                    <div className="emtycontainer">
                        <Empty />
                    </div>
                    :
                    imgsList.map((file, index) => {
                        let { ID, mediaSrc, mediaAlt } = file;
                        return (
                            <Col span={6} >
                                <div className="polaroid">
                                    <div className="img_container">
                                        <img id={ID} src={mediaSrc} alt={mediaAlt} />
                                        <div className="btns_tools">
                                            <ToolbarButtonComponent
                                                icon="pencil-square"
                                                title="Modifier l'image"
                                                className="k-button-solid-info"
                                                onclick={() => { onUpdate_Trigger(file) }}
                                            />
                                            <ToolbarButtonComponent
                                                icon="trash"
                                                title="Supprimer L'image"
                                                className="k-button-solid-error"
                                                onclick={() => { ondelete_Trigger(ID) }}
                                            />
                                        </div>
                                    </div>
                                    <div className="defaultcontainer">
                                        <Checkbox checked={immoPropForm['data_int[IDImg]'] === ID} onChange={() => { mettreEnAvant_handler(ID) }}>Mettre en avant</Checkbox>
                                    </div>
                                </div>
                            </Col>
                        )
                    })}

            </Row>
            <section className="modals">
                {popUpState ?
                    <UpdatePicModalComponent
                        title={imgToUpdate?.ID ? "Modifier une image" : "Ajouter une image"}
                        popUpState={popUpState}
                        handleOk={handleSave_UpdatePIC}
                        handleCancel={handleCancel_UpdatePIC}
                        width="70vw"
                        cropperRef={cropperRef}
                        aspectRatio={16/9}
                    />
                    :
                    null
                }
                {confirmVisible ?
                    <ConfirmModalComponent
                        title='Confirmation de suppression'
                        text='Voulez vous supprimer cette image ?'
                        popUpState={confirmVisible}
                        handleOk={confirm_delete}
                        handleCancel={cancel_delete} /> : null}
            </section>
        </div>
    );
};

export default PhotosImmoGestPropComponent;
