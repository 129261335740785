import API_ENDPOINT from "../../api/api_endpoint";
import { getApi } from "../../api/api_methods";
import { customerror } from "../../components/icon_notification/customNotifications";
import { loadPropertiesCateg, loadPropertiesEquip, loadPropertiesFams, loadPropertiesPtsForts, loadPropertiesSecteurs, loadPropertiesServices, loadPropertiesStatuts, loadPropertiesStyles, loadPropertiesTags, loadPropertiesType, loadProprios } from "./ref.slice";


export function LoadPropriosAPI(payload) {
    return async (dispatch) => {
        getApi(API_ENDPOINT.LOAD_DATA_GRID_CONTACT, payload)
            .then((res) => {
                let response = res.data ? res.data : [];
                dispatch(loadProprios(response));
            })
            .catch((err) => {
                customerror("Erreur de chargement des données", "");
            })
    };
}
export function LoadPropertiesTypeAPI(payload) {
    return async (dispatch) => {
        getApi(API_ENDPOINT.CONFIG_TYPE_BIEN, payload)
            .then((res) => {
                let response = res.data ? res.data : [];
                dispatch(loadPropertiesType(response));
            })
            .catch((err) => {
                customerror("Erreur de chargement des données", "");
            })
    };
}
export function LoadPropertiesStylesAPI(payload) {
    return async (dispatch) => {
        getApi(API_ENDPOINT.CONFIG_STYLB, payload)
            .then((res) => {
                let response = res.data ? res.data : [];
                dispatch(loadPropertiesStyles(response));
            })
            .catch((err) => {
                customerror("Erreur de chargement des données", "");
            })
    };
}

export function LoadPropertiesStatutsAPI(payload) {
    return async (dispatch) => {
        getApi(API_ENDPOINT.LOAD_STATUS, payload)
            .then((res) => {
                let response = res.data ? res.data : [];
                dispatch(loadPropertiesStatuts(response));
            })
            .catch((err) => {
                customerror("Erreur de chargement des données", "");
            })
    };
}

export function LoadPropertiesTagsAPI(payload) {
    return async (dispatch) => {
        getApi(API_ENDPOINT.LOAD_TAGS, payload)
            .then((res) => {
                let response = res.data ? res.data : [];
                dispatch(loadPropertiesTags(response));
            })
            .catch((err) => {
                customerror("Erreur de chargement des données", "");
            })
    };
}
export function LoadPropertiesFamiliesAPI(payload) {
    return async (dispatch) => {
        getApi(API_ENDPOINT.LOAD_FAM, payload)
            .then((res) => {
                let response = res.data ? res.data : [];
                dispatch(loadPropertiesFams(response));
            })
            .catch((err) => {
                customerror("Erreur de chargement des données", "");
            })
    };
}
export function LoadPropertiesCategoriesAPI(payload) {
    return async (dispatch) => {
        getApi(API_ENDPOINT.CONFIG_CATEG, payload)
            .then((res) => {
                let response = res.data ? res.data : [];
                dispatch(loadPropertiesCateg(response));
            })
            .catch((err) => {
                customerror("Erreur de chargement des données", "");
            })
    };
}
export function LoadPropertiesEquipAPI(payload) {
    return async (dispatch) => {
        getApi(API_ENDPOINT.CONFIG_EQUIP, payload)
            .then((res) => {
                let response = res.data ? res.data : [];
                dispatch(loadPropertiesEquip(response));
            })
            .catch((err) => {
                customerror("Erreur de chargement des données", "");
            })
    };
}
export function LoadPropertiesPtsFortsAPI(payload) {
    return async (dispatch) => {
        getApi(API_ENDPOINT.CONFIG_PTSFORT, payload)
            .then((res) => {
                let response = res.data ? res.data : [];
                dispatch(loadPropertiesPtsForts(response));
            })
            .catch((err) => {
                customerror("Erreur de chargement des données", "");
            })
    };
}
export function LoadPropertieServicesAPI(payload) {
    return async (dispatch) => {
        getApi(API_ENDPOINT.CONFIG_SERVICES, payload)
            .then((res) => {
                let response = res.data ? res.data : [];
                dispatch(loadPropertiesServices(response));
            })
            .catch((err) => {
                customerror("Erreur de chargement des données", "");
            })
    };
}
export function LoadPropertieSecteursAPI(payload) {
    return async (dispatch) => {
        getApi(API_ENDPOINT.CONFIG_SECTEUR, payload)
            .then((res) => {
                let response = res.data ? res.data : [];
                dispatch(loadPropertiesSecteurs(response));
            })
            .catch((err) => {
                customerror("Erreur de chargement des données", "");
            })
    };
}