import { createSlice } from "@reduxjs/toolkit";
import { removeCookie, setCookie } from "../../assets/functions";
import { userRolesUtil } from "./authentication.util";

const initialState = {
  userDetails: {},
  userRoles: [],
  userEntities: [],
  userMenu: [],
  countries: [],
  contactTypeList: []
};

const authentication = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    loadUserDetails: (state, { payload: { userMenu, userListeEntreprises, user } }) => {
      state.userDetails = user;
      state.userEntities = userListeEntreprises
      state.userRoles = userRolesUtil(userMenu);
      state.userMenu = userMenu;
    },
    loadCountries: (state, { payload }) => {
      state.countries = payload;
    },
    loadContactType: (state, { payload }) => {
      state.contactTypeList = payload;
    },
    logOut: (state, { payload }) => {
      removeCookie("token");
      state.userDetails = {};
      state.userEntities = {};
      state.userRoles = [];
      state.userMenu = [];
    },
  },
});

export const { loadUserDetails, loadCountries, loadContactType, logOut } = authentication.actions;

export default authentication.reducer;
