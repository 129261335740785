import { ObjetToArr } from "../../../assets/functions";

const DEFAULT_ZOOM = 13;

export const EXPORT_SLUG = {
	Properties: "properties",
	Excel: "excel",
};

export const EXPORT_TYPES = {
	Saved: "saved",
	Auto: "auto",
};

export const loadDataGridUtil = (data) => {
	return data.map((item, i) => {
		return {
			...item,
			ID: i + 1,
			categories: ObjetToArr(item?.categories).map(
				(item) => item.libelleCategory
			),
		};
	});
};
export const loadImmoPropFicheUtil = (data, propstList) => {
	let newData = {};
	for (const [key, value] of Object.entries(data)) {
		switch (key) {
			case "country":
				newData[`data_int[IDPays]`] = value?.countryID;
				break;

			case "C_effectifs":
				newData[`data_str[C_effectifs]`] = value;
				break;

			case "area":
				newData[`data_int[IDPropertyArea]`] = value?.areaID;
				break;

			case "owner":
				let {
					ownerID,
					ownerAdresse,
					ownerCodePostal,
					ownerVille,
					ownerCountry,
					ownerMail,
					ownerTel,
				} = value;
				newData[`data_int[IDProprietaire]`] = ownerID;
				newData[`data_str[proprioAdresse]`] = ownerAdresse;
				newData[`data_str[proprioCodePostal]`] = ownerCodePostal;
				newData[`data_str[proprioVille]`] = ownerVille;
				newData[`data_str[proprioPays]`] = ownerCountry?.ownerCountryID;
				newData[`data_str[proprioMail]`] = ownerMail;
				newData[`data_str[proprioTelephone]`] = ownerTel;
				break;

			case "tag":
				newData[`data_int[IDPropertyTag]`] = value?.tagID;
				break;

			case "type":
				newData[`data_int[IDPropertyType]`] = value?.typeID;
				break;

			case "statut":
				newData[`data_int[IDPropertyStatut]`] = value?.statutID;
				break;

			case "family":
				newData[`data_str[IDPropertyFamille]`] = value?.familyID;
				break;

			case "propertyCategories":
				newData["category"] = ObjetToArr(value).map((item) => item.ID);
				break;

			case "propertyEquipments":
				ObjetToArr(value).map((item) => {
					let { ID } = item;
					newData[`equipment[${ID}]`] = ID;
				});
				break;

			case "propertyServices":
				ObjetToArr(value).map((item) => {
					let { ID } = item;
					newData[`services[${ID}]`] = ID;
				});
				break;

			case "propertyPointsForts":
				ObjetToArr(value).map((item) => {
					let { ID } = item;
					newData[`pforts[${ID}]`] = ID;
				});
				break;

			case "CodePostal":
			case "CodePortail":
			case "CodePorte":
			case "CodeAlarme":
			case "CodeAutre":
				newData[`data_str[${key}]`] = value;
				break;

			case "Country":
				newData[`data_str[Country]`] = value;
				break;
			case "CountryCode":
				newData[`data_str[CountryCode]`] = value;
				break;
			case "Suburb":
				newData[`data_str[Suburb]`] = value;
				break;
			case "County":
				newData[`data_str[County]`] = value;
				break;
			case "State":
				newData[`data_str[State]`] = value;
				break;
			case "Address_line1":
				newData[`data_str[Address_line1]`] = value;
				break;
			case "Address_line2":
				newData[`data_str[Address_line2]`] = value;
				break;

			case "Map_Longitude":
				newData[`data_int[Map_Longitude]`] = value;
				break;

			case "Map_Latitude":
				newData[`data_int[Map_Latitude]`] = value;
				break;

			case "Map_Latitude_Origin":
				newData[`data_int[Map_Latitude_Origin]`] = value;
				break;

			case "Map_Longitude_Origin":
				newData[`data_int[Map_Longitude_Origin]`] = value;
				break;

			case "Map_Zoom":
				newData[`data_int[Map_Zoom]`] =
					value === 0 ? DEFAULT_ZOOM : value;
				break;

			case "Map_Rayon":
				newData[`data_int[Map_Rayon]`] = value;
				break;

			case "Classe_Energie":
				newData[`data_str[Classe_Energie]`] = value;
				break;

			case "Emission_GES":
				newData[`data_str[Emission_GES]`] = value;
				break;

			case "PrixNetVendeur ":
				newData[`data_int[PrixNetVendeur]`] = value;
				break;

			case "Honoraires":
				newData[`data_int[Honoraires]`] = value;
				break;

			case "PrixFAI":
				newData[`data_int[PrixFAI]`] = value;
				break;

			default:
				if (typeof value === "string") {
					newData[`data_str[${key}]`] = value;
				} else if (typeof value === "number") {
					newData[`data_int[${key}]`] = value;
				}
		}
	}
	newData.index = propstList.findIndex(
		(item) => item.IDProperty == data.IDProperty
	);

	return newData;
};
export const loadImmoPropImgsUtil = (data) => {
	let imgs = [];
	if (data?.images) {
		imgs = ObjetToArr(data?.images);
	}
	return imgs;
};
export const loadproprioInfUtil = (fiche, payload) => {
	fiche["data_str[proprioTelephone]"] = payload?.Telephone;
	fiche["data_str[proprioMail]"] = payload?.Mail;
	fiche["data_str[proprioAdresse]"] = payload?.Adresse;
	fiche["data_str[proprioCodePostal]"] = payload?.CodePostal;
	fiche["data_str[proprioVille]"] = payload?.Ville;
	fiche["data_str[proprioPays]"] = payload?.pays.libelle;
	return fiche;
};
export const updateFicheUtil = (fiche, payload) => {
	let keys = Object.keys(payload);
	keys.forEach((key) => {
		fiche[key] = payload[key];
	});
	return fiche;
};
export const deleteFromFicheUtil = (fiche, payload) => {
	for (const [key, value] of Object.entries(payload)) {
		delete fiche[key];
	}
	return fiche;
};
export const updateChoosedImgUtil = (fiche, payload) => {
	for (const [key, value] of Object.entries(payload)) {
		fiche[key] = value;
	}
	return fiche;
};
export const loadImmoPropColsUtil = ({ userColumns, allcolumns }) => {
	let result = {
		userCols: [],
		allCols: [],
	};
	let nbrFormat = [41];
	let nbrAlign = [41, 44];

	result.userCols = userColumns.map((item) => {
		let { IDColumn } = item;
		return {
			...item,
			format: nbrFormat.includes(IDColumn) ? "{0:n2}" : "",
			isNumeric: nbrAlign.includes(IDColumn),
		};
	});
	result.allCols = allcolumns.map((item) => {
		let { IDColumn } = item;
		return {
			...item,
			format: nbrFormat.includes(IDColumn) ? "{0:n2}" : "",
			isNumeric: nbrFormat.includes(IDColumn),
		};
	});

	return result;
};

/**
 * Format the selected properties to be exported
 * @param {[]} properties
 * @param {EXPORT_TYPES} slug
 */
export const formatForExport = (properties, slug = EXPORT_SLUG.Properties, name = null, type = EXPORT_TYPES.Auto) => {
	return properties.reduce(
		(obj, property) => {
			obj[`line[${property.IDProperty}]`] = property.IDProperty;
			return obj;
		},
		{ exportSlug: slug, exportName: name, exportType: type }
	);
};
