export const loadDataGridUtil = (data) => {
	return data.map((item, i) => {
		return {
			...item,
			ID: i + 1,
		};
	});
};

export const loadFicheNewsUtil = (data) => {
	let fiche = {};
	if (data?.IDNews) fiche["data_int[IDNews]"] = data?.IDNews;
	if (data?.DatePublication)
		fiche["data_date[DatePublication]"] = data?.DatePublication;
	if (data?.Statut) fiche["data_str[Statut]"] = data?.Statut;
	if (data?.Titre_fr) fiche["data_str[Titre_fr]"] = data?.Titre_fr;
	if (data?.Titre_en) fiche["data_str[Titre_en]"] = data?.Titre_en;
	if (data?.News_fr) fiche["data_str[News_fr]"] = data?.News_fr;
	if (data?.News_en) fiche["data_str[News_en]"] = data?.News_en;
	if (data?.Author) fiche["data_str[Author]"] = data?.Author;
	if (data?.mediaAlt) fiche["data_str[mediaAlt]"] = data?.mediaAlt;
	if (data?.mediaLegende_FR)
		fiche["data_str[mediaLegende_FR]"] = data?.mediaLegende_FR;
	if (data?.mediaSrc) fiche["data_str[mediaSrc]"] = data?.mediaSrc;
	return fiche;
};

export const updateFicheNewsUtil = (editedData, fiche) => {
	for (const [key, value] of Object.entries(editedData)) {
		fiche[key] = value;
	}

	return fiche;
};
