/* -----------Components------------ */
import InfoGContact from "./infoG/component";
/* -----------Assets------------ */
import Avatar from "antd/lib/avatar";
import Col from "antd/lib/col";
import Comment from "antd/lib/comment";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Menu from "antd/lib/menu";
import Row from "antd/lib/row";
import Spin from "antd/lib/spin";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import userImage from "../../assets/images/user.png";
import { AddTiersSuiviAPI } from "../../redux/contacts/contacts.api";
/* -----------Selector------------ */
import { userDetailsSelector } from "../../redux/Authentification/authentication.selector";
import { loadTiersSuiviSelector, tiersFicheLoaderSelector, tiersFicheSelector } from "../../redux/contacts/contacts.selector";
/* -----------Style Sheet------------ */
import "./styles.scss";

const ContactFicheFormComponent = ({ form, ontiersForm_change }) => {
    /* -----------State Hook------------ */
    const [suiviValue, setSuiviValue] = useState("");
    const [choosedSidemenuKey, setChoosedSidemenuKey] = useState(["infG"]);
    /* -----------Selectors------------ */
    const infoG = useSelector(tiersFicheSelector);
    const ficheLoading = useSelector(tiersFicheLoaderSelector)
    const tiersSuivi = useSelector(loadTiersSuiviSelector);
    let LOGGED_USER = useSelector(userDetailsSelector);
    /* -----------Effect Hooks------------ */
    useEffect(() => {
        form.setFieldsValue(infoG)
    }, [infoG])
    /* -----------Vars------------ */
    const dispatch = useDispatch();
    const { TextArea } = Input;
    /* -----------Functions------------ */
    const handle_OnClick = (val) => {
        let { keyPath } = val;
        keyPath.push("suivi")
        setChoosedSidemenuKey(keyPath);
    };
    const handleSuivi_Change = (e) => {
        let { target: { value } } = e;
        setSuiviValue(value)
    }
    const handleSuivi_Submit = () => {
        let payload = {
            'Objet': "tiers",
            'IDObjet': infoG['data_int[IDTiers]'],
            'Event': suiviValue,
            'EventType': 'note'
        }
        dispatch(AddTiersSuiviAPI(payload, { object: 'tiers', idobject: infoG['data_int[IDTiers]'] }, setSuiviValue))
    }
    //UI element's
    const maincontent = () => {
        let cdt = choosedSidemenuKey.includes("infG");
        switch (cdt) {
            case true:
                return (
                    <InfoGContact />
                );
            default:
                break;
        }
    };
    return (
        <Spin spinning={ficheLoading} size="large">
            <div className="contactFiche">
                <Form form={form} onValuesChange={ontiersForm_change}>
                    <Row className="allContainer">
                        <Col md={24} xl={17} >
                            <div className="ficheContent bg-white">
                                <Menu mode="horizontal" selectedKeys={choosedSidemenuKey} onClick={handle_OnClick}>
                                    <Menu.Item key="infG" >
                                        Informations générales
                                    </Menu.Item>
                                </Menu>
                                <div className="maincontent">
                                    {maincontent()}
                                </div>
                            </div>
                        </Col>
                        <Col md={24} xl={6} >
                            <div className="suiviContent bg-white" >
                                <div className="fixedArea">
                                    <Comment
                                        avatar={<Avatar src={LOGGED_USER?.userAvatar ? LOGGED_USER?.userAvatar : userImage} alt={LOGGED_USER?.userFirstName} />}
                                        content={
                                            <div>
                                                <TextArea rows={2} onChange={handleSuivi_Change} value={suiviValue} onPressEnter={handleSuivi_Submit} placeholder="Appuyez sur 'Entrée' pour poster un commentaire..." disabled={!infoG['data_int[IDTiers]']} />
                                            </div>
                                        }
                                    />
                                </div>
                                <div className="scrollIt">
                                    {tiersSuivi?.map((suivi, index) => {
                                        return (
                                            <Comment
                                                author={<div className="suiviHeader"><a>{suivi.CreateurDenomination}</a>{suivi.DateCreation}</div>}
                                                avatar={<Avatar src={suivi?.Avatar ? suivi?.Avatar : userImage} alt={suivi.CreateurDenomination} />}
                                                content={<p>{suivi.Event}</p>}
                                                key={index}
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Spin>
    );
};

export default ContactFicheFormComponent;
