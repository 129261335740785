/* -----------Component------------ */
import BordredIconButtonComponent from "../button_border_icon/bordred_icon_button.component";
/* -----------styles------------ */
import "./styles.scss";

const DeleteFooterComponent = ({ handle_delete, isUpdate }) => {
    return (
        <div className="contact_footer_container">
            {
                isUpdate ?
                    <BordredIconButtonComponent text="Supprimer " className="k-button-solid-error" clickEvent={handle_delete} icon="trash" textfirst={false} />
                    : null
            }

        </div>
    );
};

export default DeleteFooterComponent;
