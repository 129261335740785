/* -----------Component------------ */
import BordredIconButtonComponent from "../button_border_icon/bordred_icon_button.component";
/* -----------styles------------ */
import "./styles.scss";

const ModalFooterComponent = ({ handleCancel, handleOk, localAction, slug }) => {

    return (
        <div className="footer_ordinal_container">
            {slug === "occupations" ?
                <BordredIconButtonComponent
                    text="Réinitialiser"
                    className="k-button-solid-warning"
                    clickEvent={localAction}
                    textcolor="white"
                    icon="bootstrap-reboot" textfirst={false} /> : null}
            <BordredIconButtonComponent
                className="k-button-solid-primary"
                text="Enregistrer "
                clickEvent={handleOk}
                icon="save2"
                textfirst={false}
            />
            <BordredIconButtonComponent
                text="Fermer"
                className="k-button-solid-error"
                clickEvent={handleCancel}
                icon="x-circle" textfirst={false} />

        </div>
    );
};

export default ModalFooterComponent;
