/* -----------Components------------ */
import PhotoPartnerComponent from "../photos/component";
/* -----------Assets------------ */
import Col from "antd/lib/col";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Row from "antd/lib/row";
import React from "react";
/* -----------Selectors------------ */
/* -----------Style Sheet------------ */
import "./styles.scss";

const InfoGPartnerComponent = ({ addPartnerImgTrigger }) => {
	return (
		<div className="infoGPartner">
			<Row
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
				}}
			>
				<Col md={12} xl={8}>
					<Form.Item
						label="Nom"
						name="data_str[partnerName]"
					>
						<Input size="small" />
					</Form.Item>
				</Col>
				<Col md={12} xl={8}>
					<Form.Item label="Site web" name="data_str[partnerWebsite]">
						<Input size="small" />
					</Form.Item>
				</Col>
			</Row>
			<Row>
				<Col md={12}>
					<Form.Item label="Logo" name="data_str[mediaSrc]">
						<PhotoPartnerComponent addPartnerImgTrigger={addPartnerImgTrigger} />
					</Form.Item>
				</Col>
			</Row>
		</div>
	);
};

export default InfoGPartnerComponent;
