/* -----------Components------------ */
import InfoGImmoGestPropComponent from "./infoG/component";
import CaractImmoGestPropComponent from "./caracteristiques/component";
import GenericModalComponent from "../generic_modal/component";
import ModalFooterComponent from "../modal_Footer/component";
import PhotosImmoGestPropComponent from "./photos/component";
import AddTiersFormComponent from "../modal_formaddTiers/component";
/* -----------Assets------------ */
import Menu from "antd/lib/menu";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Comment from "antd/lib/comment";
import Avatar from "antd/lib/avatar";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spin from "antd/lib/spin";
import userImage from "../../assets/images/user.png";
import { LoadPropertiesCategoriesAPI, LoadPropertieSecteursAPI, LoadPropertiesEquipAPI, LoadPropertieServicesAPI, LoadPropertiesFamiliesAPI, LoadPropertiesPtsFortsAPI, LoadPropertiesStatutsAPI, LoadPropertiesTagsAPI, LoadPropertiesTypeAPI, LoadPropriosAPI } from "../../redux/referentiel/ref.api";
import { LoadProprioInfAPI, SavePropSuiviAPI } from "../../redux/Immobilier/properties/prop.api";
import { deleteFromFiche, loadproprioInf, updateFiche } from "../../redux/Immobilier/properties/prop.slice";
import { initFicheTiers, updateFicheTiers } from "../../redux/contacts/contacts.slice";
import { saveTiersAPI } from "../../redux/contacts/contacts.api";
/* -----------Selector------------ */
import { userDetailsSelector } from "../../redux/Authentification/authentication.selector";
import { ImmoPropFormLoaderSelector, ImmoPropFormSelector, ImmoPropSuiviSelector } from "../../redux/Immobilier/properties/prop.selector";
import { tiersFicheSelector } from "../../redux/contacts/contacts.selector";
/* -----------Style Sheet------------ */
import "./styles.scss";






const ImmoGestPropFormComponent = ({ form }) => {
    /* -----------State Hook------------ */
    const [suiviValue, setSuiviValue] = useState("");
    const [showPopUp, setShowPopUp] = useState(false);
    const [choosedSidemenuKey, setChoosedSidemenuKey] = useState(["infG", "suivi"]);
    /* -----------Selectors------------ */
    const ficheImmoProp = useSelector(ImmoPropFormSelector)
    const tiersFormData = useSelector(tiersFicheSelector)
    const loader = useSelector(ImmoPropFormLoaderSelector)
    const immoSuivi = useSelector(ImmoPropSuiviSelector)
    let LOGGED_USER = useSelector(userDetailsSelector);
    /* -----------Effect Hooks------------ */
    useEffect(() => {
        form.setFieldsValue(ficheImmoProp)
    }, [ficheImmoProp])
    useEffect(() => {
        dispatch(LoadPropriosAPI({ type: "Propriétaire" }))
        dispatch(LoadPropertiesTypeAPI({}))
        dispatch(LoadPropertiesStatutsAPI({}))
        dispatch(LoadPropertiesTagsAPI())
        dispatch(LoadPropertiesFamiliesAPI())
        dispatch(LoadPropertiesCategoriesAPI())
        dispatch(LoadPropertieServicesAPI())
        dispatch(LoadPropertiesPtsFortsAPI())
        dispatch(LoadPropertiesEquipAPI())
        dispatch(LoadPropertieSecteursAPI())
    }, [])
    /* -----------Vars------------ */
    const dispatch = useDispatch();
    const { TextArea } = Input;
    const [tiersForm] = Form.useForm();
    /* -----------Functions------------ */
    const handle_OnClick = (val) => {
        let { keyPath } = val;
        keyPath.push("suivi")
        setChoosedSidemenuKey(keyPath);
    };
    const handleSuivi_Change = (e) => {
        let { target: { value } } = e;
        setSuiviValue(value)
    }
    const handleSuivi_Submit = () => {
        let payload = {
            'Objet': "properties",
            'IDObjet': ficheImmoProp['data_int[IDProperty]'],
            'Event': suiviValue,
            'EventType': 'note'
        }
        dispatch(SavePropSuiviAPI(payload, { object: "properties", idobject: ficheImmoProp['data_int[IDProperty]'] }, setSuiviValue))
    }
    const onGestImmoForm_change = (editedVal, allVal) => {
        let key = Object.keys(editedVal)[0];
        if (key.includes('equipment') || key.includes('pforts') || key.includes('services')) {
            if (ficheImmoProp[key]) {
                dispatch(deleteFromFiche({ [key]: "" }))
            } else {
                dispatch(updateFiche({ [key]: parseInt(key.match(/\[(.*?)\]/)[1]) }))
            }
        } else {
            if (editedVal[key] || editedVal[key] === 0) {
                dispatch(updateFiche(editedVal))
            } else {
                dispatch(updateFiche({ [key]: null }))
            }

        }

    }
    const addProprie_trigger = () => {
        setShowPopUp(!showPopUp)
    }
    const handleOk_addProp = () => {
        dispatch(saveTiersAPI({ ...tiersFormData, 'type[4]': 4 }, callback_addTiers, [], true))
    }
    const handleCancel_addProp = () => {
        dispatch(initFicheTiers({}))
        tiersForm.resetFields();
        setShowPopUp(!showPopUp)
    }
    const callback_addTiers = (res) => {
        let { data } = res;
        let tiers = Object.values(data)[0];
        dispatch(LoadPropriosAPI({ type: "Propriétaire" }))
        dispatch(updateFiche({ 'data_int[IDProprietaire]': tiers?.IDTiers }))
        dispatch(LoadProprioInfAPI(tiers?.IDTiers))
        setShowPopUp(!showPopUp)
        dispatch(initFicheTiers())
        tiersForm.resetFields()
    }
    const onTiersForm_change = (e) => {
        dispatch(updateFicheTiers(e))
    }
    //UI element's
    const maincontent = () => {
        switch (choosedSidemenuKey[0]) {
            case "infG":
                return (
                    <InfoGImmoGestPropComponent addProprie_trigger={addProprie_trigger} ficheImmoProp={ficheImmoProp}/>
                );
            case "caract":
                return (
                    <CaractImmoGestPropComponent />
                );
            case "pics":
                return (
                    <PhotosImmoGestPropComponent />
                );
            default:
                break;
        }
    };
    return (
        <Spin spinning={loader} size="large">
            <div className="immoGestPropFiche">
                <Form form={form} onValuesChange={onGestImmoForm_change}>
                    <Row className="allContainer">
                        <Col md={24} xl={16} className="ficheContent bg-white">
                            <Menu mode="horizontal" selectedKeys={choosedSidemenuKey} onClick={handle_OnClick} theme="Light">
                                <Menu.Item key="infG" >
                                    Informations générales
                                </Menu.Item>
                                <Menu.Item key="caract" >
                                    Caractéristiques
                                </Menu.Item>
                                <Menu.Item key="pics" >
                                    Photos
                                </Menu.Item>
                            </Menu>
                            <div className="maincontent">
                                {maincontent()}
                            </div>
                        </Col>
                        <Col md={24} xl={7} className="suiviContent bg-white">
                            <div className="fixedArea">
                                <Comment
                                    avatar={<Avatar src={LOGGED_USER?.userAvatar ? LOGGED_USER?.userAvatar : userImage} alt={LOGGED_USER?.userFirstName} />}
                                    content={
                                        <div>
                                            <TextArea rows={2} onChange={handleSuivi_Change} onPressEnter={handleSuivi_Submit} value={suiviValue} disabled={!ficheImmoProp['data_int[IDProperty]']} placeholder="Appuyez sur 'Entrée' pour poster un commentaire..." />
                                        </div>
                                    }
                                />
                            </div>
                            <div className="scrollIt">
                                {immoSuivi.map((suivi, index) => {
                                    return (
                                        <Comment
                                            author={<div className="suiviHeader"><a>{suivi.CreateurDenomination}</a>{suivi.DateCreation}</div>}
                                            avatar={<Avatar src={suivi?.Avatar ? suivi?.Avatar : userImage} alt={suivi.CreateurDenomination} />}
                                            content={<p>{suivi.Event}</p>}
                                            key={index}
                                        />
                                    )
                                })}
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
            <section className="modals">
                {showPopUp ?
                    <GenericModalComponent
                        title="Ajouter un propriétaire"
                        width="40%"
                        popUpState={showPopUp}
                        handleCancel={handleCancel_addProp}
                        handleOk={handleOk_addProp}
                        footer={ModalFooterComponent}
                    >
                        <AddTiersFormComponent form={tiersForm} onTiersForm_change={onTiersForm_change} />
                    </GenericModalComponent>
                    : null
                }
            </section>
        </Spin>
    );
};

export default ImmoGestPropFormComponent;
