import { ObjetToArr } from "../../assets/functions";

export const loadDataGridUtil = (data, typeList) => {

    return data.map((item, index) => {
        item.ID = index;
        for (const [key, value] of Object.entries(item)) {
            if (key === "types" && value) {
                let typesIdList = value.map(item => item.IDType)
                let labelTypes = "";
                typeList
                    .filter(xx => { return typesIdList.includes(xx.IDType) })
                    .map(item => labelTypes = labelTypes + "," + item.Libelle)
                item.IDType = labelTypes.slice(1);
            }
        }
        return item
    })
}

export const loadFicheTiersUtil = (data, contactList) => {

    let newData = {};

    for (const [key, value] of Object.entries(data)) {
        if (key === "pays") {
            newData[`data_int[IDPays]`] = value?.ID;
        } else if (key === "typesTiers") {
            let arr = [];
            ObjetToArr(value).map(item => {
                arr.push(item?.ID)
            })
            newData[`data_str[IDType]`] = arr
        } else if (typeof value === "string") {
            newData[`data_str[${key}]`] = value;
        } else if (typeof value === "number") {
            newData[`data_int[${key}]`] = value;
        }
    }
    return newData;
}
export const LoadFicherTiersContactUtil = (data) => {

    let returnData = [];
    if (data?.TiersAsContact)
        returnData = ObjetToArr(data?.TiersAsContact).map((item, index) => {
            return { ...item, ID: index }
        })
    return returnData
}

export const loadFicheContactUtil = (data) => {
    let newData = {};
    for (const [key, value] of Object.entries(data)) {
        if (["ContactPortable", "ContactTelephone"].includes(key)) {
            newData[`data_str[${key}]`] = value;
        } else if ("CodePostal" === key) {
            newData[`data_str[${key}]`] = value;
        } else if (typeof value === "string") {
            newData[`data_str[${key}]`] = value;
        } else if (typeof value === "number") {
            newData[`data_int[${key}]`] = value;
        }
    }
    return newData;
}
export const updateFicheTiersUtil = (data, fiche) => {
    for (const [key, value] of Object.entries(data)) {
        if (key === "data_str[CompanyType]") {
            if (value === "ENTREPRISE") {
                delete fiche['data_str[Nom]'];
                delete fiche['data_str[Prenom]'];
            } else {
                delete fiche['data_str[Denomination]'];
            }
            fiche[key] = value;
        } else if(value) {
            fiche[key] = value;
        } else {
            fiche[key] = null;
        }
	};

    return fiche
}
export const updateFicheContactUtil = (data, fiche) => {
    for (const [key, value] of Object.entries(data)) {
        if(value) {
            fiche[key] = value;
        } else {
            fiche[key] = null;
        }
	};
    return fiche
}