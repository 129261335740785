import qs from "qs";
import { logOutUser } from "../assets/functions";
import { customwarning } from "../components/icon_notification/customNotifications";
import api from "./api";

export const getApi = (url, payload) => {
	const request = api().get(url, {
		params: {
			...payload,
		},
	});
	return verifyRequest(request);
};
export const postApi = (url, payload) => {
	const data = qs.stringify(payload, { indices: false });
	const request = api().post(url, data);
	return verifyRequest(request);
};
export const putApi = (url, payload) => {
	const data = qs.stringify(payload, { indices: false });
	const request = api().put(url, data);
	return verifyRequest(request);
};
export const deleteApi = (url, payload) => {
	const request = api().delete(url, {
		params: {
			...payload,
		},
	});
	return verifyRequest(request);
};

/**
 * The function `verifyRequest` checks if the response from a request contains a specific error message
 * Logs out the user if the request in unauthorized
 * @param request - AxiosResponse
 * @returns AxiosResponse or throws an error
 */
const verifyRequest = (request) => {
	// TODO the back needs to send back correct status code, then we can remove this
	return request.then((res) => {
		// if (res.data.status === 401) {
		if (
			res.data?.length > 0 &&
			typeof res.data === "string" &&
			res?.data.indexOf("la token présentée n'est pas valide !")
		) {
			logOutUser();
			customwarning("Votre session a éxpiré", "", 5);
			throw new Error("401: session expired");
		}
		return request;
	});
};
