/* -----------Components------------ */
/* -----------Assets------------ */
import Checkbox from "antd/lib/checkbox";
import Col from "antd/lib/col";
import Divider from "antd/lib/divider";
import Form from "antd/lib/form";
import InputNumber from "antd/lib/input-number";
import Radio from "antd/lib/radio";
import Row from "antd/lib/row";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
/* -----------Selectors------------ */
/* -----------Style Sheet------------ */
import API_ENDPOINT from "../../../api/api_endpoint";
import { LoadImmoConfigAPI } from "../../../redux/Immobilier/config/config.api";
import { dataGridSelector } from "../../../redux/Immobilier/config/config.selector";
import { ImmoPropFormSelector } from "../../../redux/Immobilier/properties/prop.selector";
import { updateFiche } from "../../../redux/Immobilier/properties/prop.slice";
import {
	propCategSelector,
	propEquipSelector,
	propFamsSelector,
	propPtsFortSelector,
	propServicesSelector,
	propTagsSelector,
} from "../../../redux/referentiel/ref.selector";
import RichTextEditor from "../../rich_text_editor/RichTextEditor";
import SelectFormComponent from "../../select_input_form/antdselect.component";
import "./styles.scss";

const CaractImmoGestPropComponent = () => {
	/* -----------State Hook------------ */
	const [descriptionContent, setDescriptionContent] = useState();
	const [shortDescription, setShortDescription] = useState();
	const [language, setLanguage] = useState("fr");
	const [FAIPrice, setFAIPrice] = useState(0);
	/* -----------Selector------------ */
	const listPtFort = useSelector(propPtsFortSelector);
	const listService = useSelector(propServicesSelector);
	const listEquip = useSelector(propEquipSelector);
	const listTags = useSelector(propTagsSelector);
	const listFams = useSelector(propFamsSelector);
	const listCateg = useSelector(propCategSelector);
	const ficheImmoGestProp = useSelector(ImmoPropFormSelector);
	const typicalSentences = useSelector(dataGridSelector);

	const TE_TITLE_LABELS = {
		en: { label: "Short Description", key: "DescriptionCourte_" },
		fr: { label: "Description courte", key: "DescriptionCourte_" },
	};
	const TE_DESCRIPTION_LABELS = {
		en: "Long Description",
		fr: "Description longue",
	};

	const energyClasses = [
		{
			value: "A",
			label: "A (≤ 50)",
		},
		{
			value: "B",
			label: "B (51 à 90)",
		},
		{
			value: "C",
			label: "C (91 à 150)",
		},
		{
			value: "D",
			label: "D (151 à 230)",
		},
		{
			value: "E",
			label: "E (231 à 330)",
		},
		{
			value: "F",
			label: "F (331 à 450)",
		},
		{
			value: "G",
			label: "G (> 450)",
		},
	];
	const GES = [
		{
			value: "A",
			label: "A (≤ 6)",
		},
		{
			value: "B",
			label: "B (6 à 10)",
		},
		{
			value: "C",
			label: "C (11 à 29)",
		},
		{
			value: "D",
			label: "D (30 à 49",
		},
		{
			value: "E",
			label: "E (50 à 69)",
		},
		{
			value: "F",
			label: "F (70 à 99)",
		},
		{
			value: "G",
			label: "G (> 100)",
		},
	];
	/* -----------Vars------------ */
	const dispatch = useDispatch();

	/* -----------Functions------------ */

	useEffect(() => {
		dispatch(LoadImmoConfigAPI(API_ENDPOINT.CONFIG_TYPICAL_SENTENCES, false))
	}, [])

	useEffect(() => {
		setDescriptionContent(
			ficheImmoGestProp[`data_str[DescriptionLongue_${language}]`] || ""
		);
		setShortDescription(
			ficheImmoGestProp[`data_str[DescriptionCourte_${language}]`] || ""
		);
	}, [ficheImmoGestProp, language]);

	const onTitleChange = (newShortDescription) => {
		updateDescription(
			`data_str[DescriptionCourte_${language}]`,
			newShortDescription
		);
		setShortDescription(newShortDescription);
	};

	const onEditorContentChange = (newLongDescription) => {
		updateDescription(
			`data_str[DescriptionLongue_${language}]`,
			newLongDescription
		);
		setDescriptionContent(newLongDescription);
	};

	const updateDescription = (key, value) => {
		dispatch(
			updateFiche({
				...ficheImmoGestProp,
				[key]: value,
			})
		);
	};

	const formatPrice = (price) => {
		if (price <= 0) {
			return;
		}
		return parseInt(price)
			.toLocaleString("en-US", { useGrouping: true })
			.replace(/,/g, " ");
	};

	const calculateFAI = () => {
		let sellerNetPrice = ficheImmoGestProp["data_int[PrixNetVendeur]"] ?? 0;
		let fees = ficheImmoGestProp["data_int[Honoraires]"] ?? 0;
		let priceIncludingFAI = sellerNetPrice * (fees / 100) + sellerNetPrice;
		setFAIPrice(formatPrice(priceIncludingFAI.toFixed(2)));
		return setTimeout(() => {
			dispatch(
				updateFiche({
					...ficheImmoGestProp,
					"data_int[PrixFAI]": priceIncludingFAI.toFixed(2),
				})
			);
		}, 400);
	};

	useEffect(() => {
		const updateFAIPrice = calculateFAI();
		return () => {
			clearTimeout(updateFAIPrice);
		};
	}, [
		ficheImmoGestProp[`data_int[Honoraires]`],
		ficheImmoGestProp[`data_int[PrixNetVendeur]`],
	]);

	const addSentenceToDescription = (typicalSentence) => {
		setDescriptionContent(
			ficheImmoGestProp[`data_str[DescriptionLongue_${language}]`] +
				typicalSentence[`Phrase_${language}`] +
				"<br/>"
		);
		dispatch(
			updateFiche({
				...ficheImmoGestProp,
				[`data_str[DescriptionLongue_en]`]:
					ficheImmoGestProp[`data_str[DescriptionLongue_en]`] +
					typicalSentence.Phrase_en +
					"<br/>",
				[`data_str[DescriptionLongue_fr]`]:
					ficheImmoGestProp[`data_str[DescriptionLongue_fr]`] +
					typicalSentence.Phrase_fr +
					"<br/>",
			})
		);
	};

	return (
		<div className="caractImmoGestProp">
			<span className="Topinfo">
				Cette partie permet de renseigner les informations de la villa
				afin de la présenter sur votre site web (si cette option a été
				installée).
			</span>
			<Divider orientation="left" plain>
				Site Internet
			</Divider>
			<Row className="limite maxLabel">
				<Col md={12} xl={12} className="radioo">
					<Form.Item
						label="Visible sur internet"
						name="data_int[C_online]"
					>
						<Radio.Group>
							<Radio value={1}>Oui</Radio>
							<Radio value={0}>Non</Radio>
						</Radio.Group>
					</Form.Item>
				</Col>
				<Col md={12} xl={12} className="radioo">
					<Form.Item
						label="Mise en avant"
						name="data_int[C_avant_HomePage]"
					>
						<Radio.Group>
							<Radio value={1}>Oui</Radio>
							<Radio value={0}>Non</Radio>
						</Radio.Group>
					</Form.Item>
				</Col>
				<Col md={12} xl={12}>
					<SelectFormComponent
						formlabel="Bandeau Info Produit"
						name="data_int[IDPropertyTag]"
						dataOption={listTags}
						value="IDPropertyTag"
						label="Libelle_fr"
						clearable={true}
					/>
				</Col>
			</Row>
			<Divider orientation="left" plain>
				Prix
			</Divider>
			<Row className="limite maxLabel">
				<Col xs={12} md={10} lg={9} xl={7}>
					<Form.Item
						label="Prix hors honoraires"
						name="data_int[PrixNetVendeur]"
					>
						<InputNumber
							min={0}
							placeholder={0}
							addonAfter="€"
							controls={false}
							formatter={formatPrice}
						/>
					</Form.Item>
				</Col>
				<Col xs={12} md={10} lg={9} xl={7}>
					<Form.Item label="Honoraires" name="data_int[Honoraires]">
						<InputNumber
							min={0}
							max={100}
							placeholder={0}
							addonAfter="%"
							controls={false}
						/>
					</Form.Item>
				</Col>
				<Col xs={12} md={10} lg={9} xl={7}>
					<Form.Item label="Prix FAI">
						<InputNumber
							min={0}
							placeholder={0}
							readOnly
							addonAfter="€"
							value={FAIPrice}
							controls={false}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Divider orientation="left" plain>
				Informations
			</Divider>
			<Row className="limite inputNbrSlider">
				<Col xs={12} md={10} lg={9} xl={7}>
					<Form.Item label="CA" name="data_int[C_CAHT]">
						<InputNumber
							min={0}
							placeholder={0}
							addonAfter="€"
							controls={false}
						/>
					</Form.Item>
				</Col>
				<Col xs={12} md={10} lg={9} xl={7}>
					<Form.Item
						label="Effectifs"
						name="data_str[C_effectifs]"
						className="inputNumberToChar"
					>
						<InputNumber
							size="small"
							title={ficheImmoGestProp["data_str[C_effectifs]"]}
							min={0}
							placeholder={0}
							addonAfter={
								<i
									className={`bi bi-person`}
									style={{ fontSize: "14px" }}
								/>
							}
							controls={false}
						/>
					</Form.Item>
				</Col>
				<Col xs={12} md={10} lg={9} xl={7}>
					<Form.Item label="EBE*" name="data_int[C_EBE]">
						<InputNumber
							min={0}
							placeholder={0}
							addonAfter="€"
							controls={false}
						/>
					</Form.Item>
				</Col>
				<Col xs={12} md={10} lg={9} xl={7}>
					<Form.Item label="RBE" name="data_int[C_RBE]">
						<InputNumber
							min={0}
							placeholder={0}
							addonAfter="€"
							controls={false}
						/>
					</Form.Item>
				</Col>
				<Col xs={12} md={10} lg={9} xl={7}>
					<Form.Item
						label="Surface du bien"
						name="data_int[C_surfaceBien]"
					>
						<InputNumber
							min={0}
							placeholder={0}
							addonAfter="m²"
							controls={false}
						/>
					</Form.Item>
				</Col>
				<Col xs={12} md={10} lg={9} xl={7}>
					<Form.Item
						label="Nb de couverts"
						name="data_int[C_nbCouverts]"
					>
						<InputNumber
							min={0}
							placeholder={0}
							addonAfter={
								<i
									className={`bi bi-cup-hot`}
									style={{ fontSize: "14px" }}
								/>
							}
							controls={false}
						/>
					</Form.Item>
				</Col>
				<Col xs={12} md={10} lg={9} xl={7}>
					<Form.Item
						label="Place de parking"
						name="data_int[C_nbParking]"
					>
						<InputNumber
							min={0}
							placeholder={0}
							addonAfter={
								<i
									className={`bi bi-p-circle`}
									style={{ fontSize: "14px" }}
								/>
							}
							controls={false}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Divider orientation="left" plain>
				Consommation
			</Divider>
			<Row className="limite inputNbrSlider energyClasses">
				<Col xs={24} md={15} lg={12} xl={10}>
					<SelectFormComponent
						formlabel="Consommation énergétique"
						name="data_str[Classe_Energie]"
						dataOption={energyClasses}
						value="value"
						label="label"
						clearable={true}
						placeholder={energyClasses[0].label}
					/>
				</Col>
				<Col
					xs={24}
					md={15}
					lg={12}
					xl={10}
					className="d-flex flex-row align-items-baseline position-relative"
				>
					<SelectFormComponent
						formlabel="Emissions de gaz à effet de serre (GES)"
						name="data_str[Emission_GES]"
						dataOption={GES}
						value="value"
						label="label"
						clearable={true}
						placeholder={GES[0].label}
					></SelectFormComponent>
					<span
						id="GES-info"
						title="Pour un diagnostic réalisé après le 30 juin 2021"
					>
						<i
							id="ges-info-icon"
							className={`bi bi-question-circle-fill`}
						></i>
					</span>
				</Col>
			</Row>
			<Divider orientation="left" plain style={{ marginTop: "10px" }}>
				Caractéristiques
			</Divider>
			<Row className="limite configImmo">
				<Col md={12} xl={12}>
					<SelectFormComponent
						formlabel="Famille"
						name="data_str[IDPropertyFamille]"
						dataOption={listFams}
						value="IDPropertyFamille"
						label="Libelle_fr"
						clearable={true}
					/>
				</Col>
				<Col md={12} xl={12}>
					<SelectFormComponent
						formlabel="Catégorie"
						name="category"
						dataOption={listCateg}
						value="IDPropertyCategory"
						label="Libelle_fr"
						mode="multiple"
						clearable={true}
					/>
				</Col>
				<div className="configTitle">Points-Forts</div>
				<div className="configBody">
					{listPtFort?.map((item, i) => {
						let { IDPropertyPF, Libelle_fr } = item;
						return (
							<Form.Item
								name={`pforts[${IDPropertyPF}]`}
								valuePropName="checked"
								key={i}
							>
								<Checkbox>
									<span title={Libelle_fr}>{Libelle_fr}</span>
								</Checkbox>
							</Form.Item>
						);
					})}
				</div>
				<div className="configTitle">Équipements</div>
				<div className="configBody">
					{listEquip?.map((item, i) => {
						let { IDPropertyEquipment, Libelle_fr } = item;
						return (
							<Form.Item
								name={`equipment[${IDPropertyEquipment}]`}
								valuePropName="checked"
								key={i}
							>
								<Checkbox>
									<span title={Libelle_fr}>{Libelle_fr}</span>
								</Checkbox>
							</Form.Item>
						);
					})}
				</div>
				<div className="configTitle">Services</div>
				<div className="configBody">
					{listService?.map((item, i) => {
						let { IDPropertyService, Libelle_fr } = item;
						return (
							<Form.Item
								name={`services[${IDPropertyService}]`}
								valuePropName="checked"
								key={i}
							>
								<Checkbox>
									<span title={Libelle_fr}>{Libelle_fr}</span>
								</Checkbox>
							</Form.Item>
						);
					})}
				</div>
			</Row>
			<div className="submodule">
				<div className="submodule_Title">Description</div>
				<RichTextEditor
					content={descriptionContent}
					title={shortDescription}
					titleLabels={TE_TITLE_LABELS}
					textLabels={TE_DESCRIPTION_LABELS}
					onLanguageChange={setLanguage}
					onTextChange={onEditorContentChange}
					onTitleChange={onTitleChange}
					typicalSentences={typicalSentences}
					onTypicalSentenceAdd={addSentenceToDescription}
				/>
			</div>
		</div>
	);
};

export default CaractImmoGestPropComponent;
