import ToolbarButtonComponent from "../button_toolbar/toolbar_button.component";

const TypicalSentence = ({ sentence, onClick }) => {
	return (
		<div className="d-flex align-item-center flex-row justify-content-start">
			<ToolbarButtonComponent
				icon="plus-circle-dotted"
				title="Ajouter la phrase type"
				className="k-button-solid-info"
				onclick={() => onClick(sentence)}
			/>
            <p className="ps-1 my-auto text-center align-items-center text-truncate">
			    {sentence.Phrase_title}
            </p>
		</div>
	);
};

export default TypicalSentence;
