import { Tag } from "antd";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NewsDrawer from "../../../components/NewsDrawer/NewsDrawer";
import ToolbarButtonComponent from "../../../components/button_toolbar/toolbar_button.component";
import DeleteFooterComponent from "../../../components/drawer_contactFooter/component";
import NextFooterComponent from "../../../components/drawer_nextToolBtn/component";
import GenericDrawer from "../../../components/generic_drawer/component";
import HeaderComponent from "../../../components/header/component";
import ConfirmModalComponent from "../../../components/modal_confirm/confirmModal.component";
import TableComponent from "../../../components/table/component";
import {
	LoadNewsAPI,
	LoadNewsSuiviAPI,
	addNewsAPI,
	deleteNewsAPI,
	updateNewsAPI,
} from "../../../redux/WebSIte/news/news.api";
import {
	columnNamesSelector,
	dataGridLoaderSelector,
	dataGridSelector,
	ficheNewsFormSelector,
	selectedNewsSelector,
} from "../../../redux/WebSIte/news/news.selector";
import {
	initFicheNews,
	loadFicheNews,
	setSelectedNewsRow,
} from "../../../redux/WebSIte/news/news.slice";
import "./News.scss";

const News = () => {
	/* -----------selectors------------ */
	const columnNames = useSelector(columnNamesSelector);
	const gridData = useSelector(dataGridSelector);
	const gridLoader = useSelector(dataGridLoaderSelector);
	const selectedRow = useSelector(selectedNewsSelector);
	const infoG = useSelector(ficheNewsFormSelector);
	/* -----------State Hook------------ */
	const [gridDataFiltred, setGridDataFiltred] = useState(gridData);
	const [drawerState, setDrawerState] = useState(false);
	const [confirmVisible, setConfirmVisible] = useState(false);
	const [newsTitle, setNewsTitle] = useState("");
	const [serachValue, setSearchValue] = useState("");

	/* -----------vars------------ */
	const { Search } = Input;
	const dispatch = useDispatch();
	const [newsForm] = Form.useForm();
	const isUpdate =
		infoG["data_int[IDNews]"] || infoG["data_int[IDNews]"] === 0;
	let _export;

	/* -----------Effect Hook------------ */
	useEffect(() => {
		dispatch(LoadNewsAPI(false));
	}, []);

	useEffect(() => {
		setGridDataFiltred(gridData);
	}, [gridData]);

	/* -----------Functions------------ */
	const setExportCsv = (_exp) => {
		_export = _exp;
	};

	const exportExcel = () => {
		_export.save();
	};

	const oneClickHandler = (e) => {
		if (selectedRow?.idNews === e?.idNews) {
			dispatch(setSelectedNewsRow({}));
		} else {
			dispatch(setSelectedNewsRow(e));
		}
	};

	const doubleClickHandler = (e) => {
		const { Titre_fr, IDNews } = e?.dataItem;
		setNewsTitle(Titre_fr);
		dispatch(loadFicheNews(e?.dataItem));
		dispatch(LoadNewsSuiviAPI(IDNews));
		setDrawerState(!drawerState);
	};

	const onSearchFilter = (input) => {
		let {
			target: { value },
		} = input;
		let filterVal = input.target ? value : input;
		let filtredGrid = [];
		if (filterVal) {
			gridData.map((item) => {
				for (const value of Object.values(item)) {
					if (
						value &&
						value
							.toString()
							.toUpperCase()
							.includes(filterVal.toUpperCase())
					) {
						if (filtredGrid.indexOf(item) === -1)
							filtredGrid.push(item);
					}
				}
			});
			setGridDataFiltred(filtredGrid);
		} else {
			setGridDataFiltred(gridData);
		}
		setSearchValue(filterVal);
	};

	const handleCancelDrawer = () => {
		setSearchValue("");
		refreshGrid();
		closeClearDrawer();
	};

	const handleOkCallback = (news) => {
		setNewsTitle(news.Titre_fr);
		dispatch(loadFicheNews(news));
		dispatch(LoadNewsSuiviAPI(news.IDNews));
	};

	const handleDeleteCallback = (news) => {
		setSearchValue("");
		refreshGrid();
		closeClearDrawer();
	};

	const handleOkDrawer = () => {
		let formData = _.cloneDeep(newsForm.getFieldsValue());
		let ficheData = _.cloneDeep(infoG);
		delete ficheData["data_str[Statut]"];
		ficheData = { ...formData, ...ficheData };
		delete ficheData["data_str[mediaSrc]"];
		delete ficheData["data_str[mediaLegende_FR]"];
		delete ficheData["data_str[mediaAlt]"];
		if (ficheData["data_int[IDNews]"]) {
			dispatch(updateNewsAPI(ficheData, handleOkCallback));
		} else {
			dispatch(addNewsAPI(ficheData, handleOkCallback));
		}
	};

	const closeClearDrawer = () => {
		newsForm.resetFields();
		setDrawerState(!drawerState);
	};

	const handleDelete = () => {
		setConfirmVisible(!confirmVisible);
	};

	const addNews = () => {
		dispatch(initFicheNews());
		dispatch(setSelectedNewsRow({}));
		setDrawerState(!drawerState);
		setNewsTitle("Ajouter une actualité");
	};

	const confirmDelete = () => {
		dispatch(
			deleteNewsAPI(infoG["data_int[IDNews]"], handleDeleteCallback)
		);
		setConfirmVisible(!confirmVisible);
		setSearchValue("");
	};

	const cancelDelete = () => {
		setConfirmVisible(!confirmVisible);
	};

	const refreshGrid = () => {
		dispatch(LoadNewsAPI(true));
		setSearchValue("");
	};

	/* -----------UI------------ */
	const gridToolbar = (
		<div className="toolbar_utils">
			<div className="toolbar_btns">
				<Search
					placeholder="Recherche"
					onSearch={onSearchFilter}
					onChange={onSearchFilter}
					style={{ width: 300 }}
					size="middle"
					value={serachValue}
				/>
			</div>
			<div className="toolbar_icns">
				<ToolbarButtonComponent
					icon="arrow-clockwise"
					title="Actualiser"
					className="k-button-outline-secondary"
					onclick={refreshGrid}
				/>
				<ToolbarButtonComponent
					icon="filetype-xlsx"
					title="Exporter Excel"
					className="k-button-outline-success"
					onclick={exportExcel}
				/>
				<ToolbarButtonComponent
					icon="plus-circle-dotted"
					text="Ajouter"
					title="Créer une nouvelle actualité"
					className="k-button-solid-primary"
					onclick={addNews}
				/>
			</div>
		</div>
	);

	const cellRender = (td, props) => {
		let { field, dataItem } = props;
		if (field === "Statut") {
			const statut = (
				<Tag color={dataItem[field] === "draft" ? "orange" : "green"}>
					{dataItem[field] === "draft" ? "Brouillon" : "Publiée"}
				</Tag>
			);
			return React.cloneElement(td, td.props, statut);
		}
		return td;
	};

	/* -----------render------------ */
	return (
		<div className="news">
			<section className="header">
				<HeaderComponent title="Actualités" icon="newspaper" />
			</section>
			<section className="page_container">
				<div className="main_content">
					<div className="table-responsive">
						<TableComponent
							handleRowDoubleClick={doubleClickHandler}
							gridlist={gridDataFiltred}
							columnNames={columnNames}
							loader={gridLoader}
							gridToolBar={gridToolbar}
							handleRowClick={oneClickHandler}
							selectedID={selectedRow}
							cellRender={cellRender}
							setExportCsv={setExportCsv}
							excelFilename="News_list"
						/>
					</div>
				</div>
			</section>
			<section className="drawers">
				{drawerState ? (
					<GenericDrawer
						title={newsTitle}
						size="90vw"
						visible={drawerState}
						handleCancel={handleCancelDrawer}
						extra={NextFooterComponent({
							handleOk_Drawer: handleOkDrawer,
							handleCancel_Drawer: handleCancelDrawer,
							isUpdate,
						})}
						footer={DeleteFooterComponent({
							handle_delete: handleDelete,
							isUpdate,
						})}
					>
						<NewsDrawer
							form={newsForm}
							_onNewsFormChange={refreshGrid}
						/>
					</GenericDrawer>
				) : null}
				{confirmVisible ? (
					<ConfirmModalComponent
						title="Confirmation de suppression"
						text="Voulez vous supprimer cet élément ?"
						popUpState={confirmVisible}
						handleOk={confirmDelete}
						handleCancel={cancelDelete}
					/>
				) : null}
			</section>
		</div>
	);
};

export default News;
