/* -----------Components------------ */
import { AutoComplete } from "antd";
import SelectFormComponent from "../../select_input_form/antdselect.component";
import ContactsComponent from "../contacts/component";
import NotesInternes from "../notesInternes/component";
/* -----------Assets------------ */
import Col from "antd/lib/col";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Radio from "antd/lib/radio";
import Row from "antd/lib/row";
import Tabs from "antd/lib/tabs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFicheTiers } from "../../../redux/contacts/contacts.slice";
/* -----------Selectors------------ */
import { contactTypeSelector, countriesListSelector } from "../../../redux/Authentification/authentication.selector";
import { tiersFicheSelector } from "../../../redux/contacts/contacts.selector";
/* -----------Style Sheet------------ */
import { getCoordinatesFromAddress } from "../../../utils";
import "./styles.scss";

const MainForm = ({ }) => {
    const dispatch = useDispatch(); 
    /* -----------State Hook------------ */
    /* -----------Selector------------ */
    const contacTypeList = useSelector(contactTypeSelector);
    const countriesList = useSelector(countriesListSelector);
    const infoG = useSelector(tiersFicheSelector);
    /* -----------Vars------------ */
    const { TabPane } = Tabs;
    const optionsWithDisabled = [
        { label: 'Entreprise', value: 'ENTREPRISE' },
        { label: 'Particulier', value: 'PARTICULIER' },
    ];

    // Address
    const DEBOUNCE_DURATION = 700;

    const [adresses, setAddresses] = useState([]);

    useEffect(() => {
        const getData = setTimeout(() => {
            if (!infoG["data_str[FormattedAddress]"]) {
                setAddresses([]);
                return;
            }
            getCoordinatesFromAddress(infoG["data_str[FormattedAddress]"]).then((res) => {
                if (res?.length > 0) {
                    setAddresses(res);
                }
            });
        }, DEBOUNCE_DURATION);

        return () => clearTimeout(getData);
    }, [
        infoG["data_str[FormattedAddress]"],
    ]);

    const onAdressSelect = (e) => {
        const selectedAdress = adresses?.filter((a) => a.formatted === e)[0];
        if (selectedAdress) {
            const internalCountry = countriesList.filter(
                (c) =>
                    c?.country_code?.toLowerCase() ===
                    selectedAdress?.country_code?.toLowerCase()
            )[0];

            dispatch(
                updateFicheTiers({
                    "data_str[FormattedAddress]": selectedAdress.formatted,
                    "data_str[Adresse]": selectedAdress.address_line1,
                    "data_str[CodePostal]": selectedAdress.postcode,
                    "data_str[Ville]": selectedAdress.city,
                    "data_str[Pays]": selectedAdress.country,
                    "data_int[IDPays]": internalCountry?.IDPays,

                    // Hidden fields
                    "data_str[Country_Code]": selectedAdress.country_code,
                })
            );
        }
    };

    return (
        <div className="infoGContact">
            <div>
                <Row style={{ justifyContent: "space-between", marginBottom: "20px" }}>
                    <Col md={12} xl={14}>
                        <Form.Item name="data_str[CompanyType]">
                            <Radio.Group
                                className="ant-radio-button"
                                options={optionsWithDisabled}
                                optionType="button"
                                buttonStyle="solid"
                                size="small"
                            />
                        </Form.Item>
                    </Col>

                    <Col md={12} xl={10} style={{ textAlign: "right" }}>
                        <SelectFormComponent
                            formlabel="Type"
                            size="small"
                            name="data_str[IDType]"
                            dataOption={contacTypeList}
                            value="IDType"
                            label="Libelle"
                            mode="multiple"
                            clearable={true} />
                    </Col>

                </Row>
                <Row>
                    {infoG['data_str[CompanyType]'] === "ENTREPRISE" ?
                        <Col md={24} xl={12} >
                            <Form.Item label="Dénomination" name="data_str[Denomination]">
                                <Input size="small" />
                            </Form.Item>
                        </Col>
                        :
                        <>
                            <Col md={12} xl={12} >
                                <Form.Item label="Nom" name="data_str[Nom]">
                                    <Input size="small" />
                                </Form.Item>
                            </Col>
                            <Col md={12} xl={10} >
                                <Form.Item label="Prénom" name="data_str[Prenom]">
                                    <Input size="small" />
                                </Form.Item>
                            </Col>
                        </>
                    }
                </Row>
                <Row>
                    <Col md={24} xl={12}>
                        <Form.Item name="data_str[FormattedAddress]" label="Adresse">
                            <AutoComplete
                                options={adresses?.map((address) => ({
                                    key: address.place_id,
                                    value: address.formatted,
                                    label: address.formatted,
                                }))}
                                style={{ width: "100%" }}
                                onSelect={onAdressSelect}
                                allowClear={false}
                            >
                                <Input
                                    placeholder="Adresse complète"
                                    addonAfter={
                                        <i
                                            className={`bi bi-search`}
                                            style={{ fontSize: "14px" }}
                                        />
                                    }
                                    controls={false}
                                />
                            </AutoComplete>
                        </Form.Item>
                        <Col md={24} xl={24} className="divided_col" >
                            <Form.Item name="data_str[Adresse]" className="w-100">
                                <Input placeholder="Adresse" size="large"/>
                            </Form.Item>
                        </Col> 
                        <Row>
                            <Col md={24} xl={24} className="divided_col" >
                                <Form.Item name="data_str[CodePostal]">
                                    <Input placeholder="Code postal" size="small" />
                                </Form.Item>
                                <Form.Item name="data_str[Ville]">
                                    <Input placeholder="Ville" size="small" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} xl={12} className="divided_col">
                                <SelectFormComponent
                                    size="small"
                                    name="data_int[IDPays]"
                                    dataOption={countriesList}
                                    value="IDPays"
                                    label="Pays"
                                    clearable={true} />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={24} xl={10}>
                        <Form.Item label="Téléphone" name="data_str[Telephone]">
                            <Input size="small" />
                        </Form.Item>
                        <Form.Item label="Mail" name="data_str[Mail]">
                            <Input size="small" />
                        </Form.Item>
                        <Form.Item label="Site Web" name="data_str[SiteWeb]">
                            <Input placeholder="Ex: https://monsite.com" size="small" />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
            <div className="submodule">
                <Tabs type="card">
                    <TabPane tab="Contacts" key="1">
                        <div className="tab_container">
                            <ContactsComponent />
                        </div>
                    </TabPane>
                    <TabPane tab="Notes internes" key="2">
                        <div className="tab_container">
                            <NotesInternes />
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        </div>
    );
};

export default MainForm;
