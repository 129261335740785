export const tiersColumnsSelector = (state) => state.contacts.columnNames;
export const tiersDataGridSelector = (state) => state.contacts.dataGrid;
export const tiersGridloaderSelector = (state) => state.contacts.dataGridLoader;
export const tiersSelectedRowSelector = (state) => state.contacts.selectedContactRow;
export const tiersCompleteFicheSelector = (state) => state.contacts.ficheTiers;
export const tiersFicheSelector = (state) => state.contacts.formFicheTiers;
export const tiersFicheLoaderSelector = (state) => state.contacts.ficheTiersLoader;
export const contactGridSelector = (state) => state.contacts.contactList;
export const contactFicheSelector = (state) => state.contacts.contactFiche;
export const contactFicheLoaderSelector = (state) => state.contacts.ficheContactLoader;
export const loadTiersSuiviSelector = (state) => state.contacts.tiersSuivi;
