/* -----------Components------------ */
import ToolbarButtonComponent from "../../../components/button_toolbar/toolbar_button.component";
import NextFooterComponent from "../../../components/drawer_nextToolBtn/component";
import GenericDrawer from "../../../components/generic_drawer/component";
import HeaderComponent from "../../../components/header/component";
import ConfirmModalComponent from "../../../components/modal_confirm/confirmModal.component";
import TableComponent from "../../../components/table/component";
/* -----------Assets------------ */
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Tag from "antd/lib/tag";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	LoadPartnerSuiviAPI,
	LoadPartnersAPI,
	addPartnerAPI,
	deletePartnerAPI,
	updatePartnerAPI,
} from "../../../redux/WebSIte/partners/partners.api";
import {
	initFichePartner,
	loadFichePartner,
	setSelectedPartnerRow,
	setSlectedPartnerRow,
} from "../../../redux/WebSIte/partners/partners.slice";
/* -----------Selectors------------ */
import {
	columnNamesSelector,
	dataGridLoaderSelector,
	dataGridSelector,
	fichePartnerFormSelector,
	selectedPartnerSelector,
} from "../../../redux/WebSIte/partners/partners.selector";
/* -----------Style Sheet------------ */
import _ from "lodash";
import DeleteFooterComponent from "../../../components/drawer_contactFooter/component";
import PartnerFormComponent from "../../../components/drawer_partners/component";
import "./styles.scss";

const PartnersContainer = () => {
	/* -----------selectors------------ */
	const columnNames = useSelector(columnNamesSelector);
	const gridData = useSelector(dataGridSelector);
	const gridLoader = useSelector(dataGridLoaderSelector);
	const selectedRow = useSelector(selectedPartnerSelector);
	const infoG = useSelector(fichePartnerFormSelector);
	/* -----------State Hook------------ */
	const [gridDataFiltred, setGridDataFiltred] = useState(gridData);
	const [drawerState, setDrawerState] = useState(false);
	const [confirmVisible, setConfirmVisible] = useState(false);
	const [partnerName, setPartnerName] = useState("");
	const [serachValue, setSearchValue] = useState("");

	/* -----------vars------------ */
	const { Search } = Input;
	const dispatch = useDispatch();
	const [partnerForm] = Form.useForm();
	const isUpdate =
		infoG["data_int[IDPartner]"] || infoG["data_int[IDPartner]"] === 0;
	let _export;

	/* -----------Effect Hook------------ */
	useEffect(() => {
		dispatch(LoadPartnersAPI(false));
	}, []);

	useEffect(() => {
		setGridDataFiltred(gridData);
	}, [gridData]);

	/* -----------Functions------------ */
	const setExportCsv = (_exp) => {
		_export = _exp;
	};

	const handle_exportExcel = () => {
		_export.save();
	};

	const oneClickHandler = (e) => {
		if (selectedRow?.IDPartner === e?.IDPartner) {
			dispatch(setSlectedPartnerRow({}));
		} else {
			dispatch(setSlectedPartnerRow(e));
		}
	};

	const doubleClickHandler = (e) => {
		const { partnerName, IDPartner } = e?.dataItem;
		setPartnerName(partnerName);
		dispatch(loadFichePartner(e?.dataItem));
		dispatch(LoadPartnerSuiviAPI(IDPartner));
		setDrawerState(!drawerState);
	};

	const onSearch_Filter = (input) => {
		let {
			target: { value },
		} = input;
		let filterVal = input.target ? value : input;
		let filtredGrid = [];
		if (filterVal) {
			gridData.map((item) => {
				for (const value of Object.values(item)) {
					if (
						value &&
						value
							.toString()
							.toUpperCase()
							.includes(filterVal.toUpperCase())
					) {
						if (filtredGrid.indexOf(item) === -1)
							filtredGrid.push(item);
					}
				}
			});
			setGridDataFiltred(filtredGrid);
		} else {
			setGridDataFiltred(gridData);
		}
		setSearchValue(filterVal);
	};

	const handleCancel_Drawer = () => {
		setSearchValue("");
		refreshGrid();
		closeClearDrawer();
	};

	const handleOkCallback = (partner) => {
		setPartnerName(partner.partnerName);
		dispatch(loadFichePartner(partner));
		dispatch(LoadPartnerSuiviAPI(partner.IDPartner));
	};

	const handleDeleteCallback = () => {
		setSearchValue("");
		refreshGrid();
		closeClearDrawer();
	};

	const handleOk_Drawer = () => {
		let formData = _.cloneDeep(partnerForm.getFieldsValue());
		formData["data_int[IDPartner]"] = infoG["data_int[IDPartner]"];
		if (formData["data_int[IDPartner]"]) {
			dispatch(updatePartnerAPI(formData, handleOkCallback));
		} else {
			dispatch(addPartnerAPI(formData, handleOkCallback));
		}
	};

	const closeClearDrawer = () => {
		partnerForm.resetFields();
		setDrawerState(!drawerState);
	};

	const handle_delete = () => {
		setConfirmVisible(!confirmVisible);
	};

	const addPartner_trigger = () => {
		dispatch(initFichePartner());
		dispatch(setSelectedPartnerRow({}));
		setDrawerState(!drawerState);
		setPartnerName("Ajouter un partenaire");
	};

	const confirm_delete = () => {
		dispatch(
			deletePartnerAPI(infoG["data_int[IDPartner]"], handleDeleteCallback)
		);
		setConfirmVisible(!confirmVisible);
		setSearchValue("");
	};

	const cancel_delete = () => {
		setConfirmVisible(!confirmVisible);
	};

	const refreshGrid = () => {
		dispatch(LoadPartnersAPI(true));
		setSearchValue("");
	};

	/* -----------UI------------ */
	const gridToolbar = (
		<div className="toolbar_utils">
			<div className="toolbar_btns">
				<Search
					placeholder="Recherche"
					onSearch={onSearch_Filter}
					onChange={onSearch_Filter}
					style={{ width: 300 }}
					size="middle"
					value={serachValue}
				/>
			</div>
			<div className="toolbar_icns">
				<ToolbarButtonComponent
					icon="arrow-clockwise"
					title="Actualiser"
					className="k-button-outline-secondary"
					onclick={refreshGrid}
				/>
				<ToolbarButtonComponent
					icon="filetype-xlsx"
					title="Exporter Excel"
					className="k-button-outline-success"
					onclick={handle_exportExcel}
				/>
				<ToolbarButtonComponent
					icon="plus-circle-dotted"
					text="Ajouter"
					title="Créer un nouveau partenaire"
					className="k-button-solid-primary"
					onclick={addPartner_trigger}
				/>
			</div>
		</div>
	);

	const cellRender = (td, props) => {
		let { field, dataItem } = props;
		if (field === "mediaSrc") {
			let statut = (
				<Tag color={dataItem[field] === null ? "orange" : "green"}>
					{dataItem[field] === null ? "Non" : "Oui"}
				</Tag>
			);
			return React.cloneElement(td, td.props, statut);
		}
		return td;
	};

	/* -----------render------------ */
	return (
		<div className="partners">
			<section className="header">
				<HeaderComponent title="Partenaires" icon="people-fill" />
			</section>
			<section className="page_container">
				<div className="main_content">
					<div className="table-responsive">
						<TableComponent
							handleRowDoubleClick={doubleClickHandler}
							gridlist={gridDataFiltred}
							columnNames={columnNames}
							loader={gridLoader}
							gridToolBar={gridToolbar}
							handleRowClick={oneClickHandler}
							selectedID={selectedRow}
							cellRender={cellRender}
							setExportCsv={setExportCsv}
							excelFilename="Partner_list"
						/>
					</div>
				</div>
			</section>
			<section className="drawers">
				{drawerState ? (
					<GenericDrawer
						title={partnerName}
						size="90vw"
						visible={drawerState}
						handleCancel={handleCancel_Drawer}
						extra={NextFooterComponent({
							handleOk_Drawer,
							handleCancel_Drawer,
							isUpdate,
						})}
						footer={DeleteFooterComponent({
							handle_delete,
							isUpdate,
						})}
					>
						<PartnerFormComponent
							form={partnerForm}
							onPartnerFormChange={refreshGrid}
						/>
					</GenericDrawer>
				) : null}
				{confirmVisible ? (
					<ConfirmModalComponent
						title="Confirmation de suppression"
						text="Voulez vous supprimer cet élément ?"
						popUpState={confirmVisible}
						handleOk={confirm_delete}
						handleCancel={cancel_delete}
					/>
				) : null}
			</section>
		</div>
	);
};

export default PartnersContainer;
