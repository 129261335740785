import { DownOutlined } from "@ant-design/icons";
import { Divider, Dropdown } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getExportDetails,
	getLastExports,
	getSavedExports,
} from "../../redux/Immobilier/properties/prop.api";
import {
	lastSelectionsSelector,
	savedSelectionsSelector,
} from "../../redux/Immobilier/properties/prop.selector";
import ToolbarButtonComponent from "../button_toolbar/toolbar_button.component";

const SELECT_VALUES = {
	SAVE_TITLE: "Sauvegarder la sélection en cours",
	SAVED_SELECTION: "Sélections sauvegardées",
	LAST_SELECTION: "Dernières sélections",
};

const ToolBarExportPicker = ({ onSelectionSave, onExportSelection }) => {
	const saved = useSelector(savedSelectionsSelector);
	const last = useSelector(lastSelectionsSelector);
	const [open, setOpen] = useState(false);
	const [savedSelections, setSavedSelections] = useState([]);
	const [lastSelections, setLastSelections] = useState([]);
	const [canSaveSelection, setCanSaveSelection] = useState(false);
	const inputRef = useRef(<input></input>);

	const dispatch = useDispatch();

	useEffect(() => {
		getExports();
	}, []);

	useEffect(() => {
		setSavedSelections(saved);
		setLastSelections(last);
	}, [saved, last]);

	const getExports = () => {
		dispatch(getSavedExports());
		dispatch(getLastExports());
	};

	const handleOpenChange = () => {
		setOpen((old) => !old);
	};

	const saveSelection = () => {
		onSelectionSave(inputRef.current.value, getExports);
	};

	const loadExport = (selection) => {
		getExportDetails(selection.IDExport).then((res) => {
			onExportSelection(res.data);
		});
	};

	const renderSavedSelections = () => {
		return savedSelections?.map((selection, index) => ({
			label: (
				<ToolbarButtonComponent
					className="k-button-outline-secondary w-100"
					onclick={() => {
						loadExport(selection);
					}}
					text={selection.exportName}
				/>
			),
		}));
	};

	const renderLastSelections = () => {
		return lastSelections?.map((selection, index) => ({
			label: (
				<ToolbarButtonComponent
					className="k-button-outline-secondary w-100"
					onclick={() => {
						loadExport(selection);
					}}
					text={selection.exportDate}
				/>
			),
		}));
	};

	const items = [
		{
			label: <p className="m-0 fw-bold">{SELECT_VALUES.SAVE_TITLE}</p>,
		},
		{
			label: (
				<input
					ref={inputRef}
					onChange={(e) =>
						setCanSaveSelection(e.currentTarget.value.length > 0)
					}
					maxLength={50}
					placeholder="Nom de la sélection"
					className="w-100"
				></input>
			),
		},
		{
			label: (
				<ToolbarButtonComponent
					className="k-button-outline-secondary w-100"
					onclick={saveSelection}
					text={"Sauvegarder"}
					disabled={!canSaveSelection}
				/>
			),
		},
		savedSelections.length > 0 && {
			label: <Divider className="m-0" />,
		},
		savedSelections.length > 0 && {
			label: <p className="m-0 fw-bold">Sélections sauvegardées</p>,
		},
		...renderSavedSelections(),
		lastSelections.length > 0 && {
			label: <Divider className="m-0" />,
		},
		lastSelections.length > 0 && {
			label: <p className="m-0 fw-bold">Dernières sélections</p>,
		},
		...renderLastSelections(),
	];

	return (
		<Dropdown
			menu={{
				items: items.map((item, index) => {
					return { ...item, key: index + 1 };
				}),
			}}
			onOpenChange={handleOpenChange}
			open={open}
			trigger={["click"]}
		>
			<button className="k-button k-button-sm k-button-rectangle k-button-solid k-rounded-md h-100 ">
				Sélections
				<DownOutlined />
			</button>
		</Dropdown>
	);
};

export default ToolBarExportPicker;
