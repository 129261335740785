import axios from "axios";
import API_ENDPOINT from "../../../api/api_endpoint";
import { deleteApi, getApi, postApi, putApi } from "../../../api/api_methods";
import {
	customerror,
	customsuccess,
} from "../../../components/icon_notification/customNotifications";
import {
	dataGridLoader,
	fichePartnerLoader,
	initDatagrid,
	loadDataGrid,
	loadFichePartner,
	loadPartnerSuivi,
} from "./partners.slice";
import { environment } from "../../../environment";

export function LoadPartnersAPI(hideSccs) {
	return async (dispatch) => {
		dispatch(initDatagrid({}));
		dispatch(dataGridLoader(true));
		getApi(API_ENDPOINT.PARTNERS)
			.then((res) => {
				let response = res.data ? res.data : [];
				dispatch(dataGridLoader(false));
				dispatch(loadDataGrid(response));
				if (!hideSccs)
					customsuccess("Partenaires chargées avec succès!", "");
			})
			.catch((err) => {
				dispatch(dataGridLoader(false));
				dispatch(fichePartnerLoader(false));
				customerror("Erreur de chargement des partenaires", "");
			});
	};
}

export function updatePartnerAPI(payload, updateFicheCallback) {
	return async (dispatch) => {
		dispatch(fichePartnerLoader(true));
		putApi(API_ENDPOINT.PARTNERS, payload)
			.then((res) => {
				updateFicheCallback(res.data);
				dispatch(fichePartnerLoader(false));
				customsuccess(
					"L'enregistrement a été correctement effectué",
					""
				);
			})
			.catch((err) => {
				customerror("Erreur d'enregistrement des données", "");
				dispatch(fichePartnerLoader(false));
			});
	};
}
export function addPartnerAPI(payload, addFicheCallback) {
	return async (dispatch) => {
		dispatch(fichePartnerLoader(true));
		postApi(API_ENDPOINT.PARTNERS, payload)
			.then((res) => {
				addFicheCallback(res.data);
				dispatch(fichePartnerLoader(false));
				customsuccess(
					"L'enregistrement a été correctement effectué",
					""
				);
			})
			.catch((err) => {
				customerror("Erreur d'enregistrement du partenaire", "");
				dispatch(fichePartnerLoader(false));
			});
	};
}
export function deletePartnerAPI(partnerId, callback) {
	return async (dispatch) => {
		deleteApi(API_ENDPOINT.PARTNERS + "/" + partnerId)
			.then((res) => {
				callback();
				customsuccess(
					"La suppresion a été correctement  effectuée",
					""
				);
			})
			.catch((err) => {
				customerror("Erreur de suppresion des données", "");
			});
	};
}

export function LoadPartnerSuiviAPI(partnerId) {
	return async (dispatch) => {
		getApi(API_ENDPOINT.LOAD_SUIVI, { object: "partners", idobject: partnerId })
			.then((res) => {
				let response = res.data ? res.data : [];
				dispatch(loadPartnerSuivi(response));
			})
			.catch((err) => {
				customerror("Erreur de chargement de la liste de suivi", "");
			});
	};
}

export function SavePartnerSuiviAPI(payload, partnerId, setSuiviValue) {
    return async (dispatch) => {
        dispatch(fichePartnerLoader(true));
        postApi(API_ENDPOINT.LOAD_SUIVI, payload)
            .then((res) => {
                dispatch(LoadPartnerSuiviAPI(partnerId))
                setSuiviValue("")
                dispatch(fichePartnerLoader(false));
            })
            .catch((err) => {
                customerror("Erreur d'enregistrement des données", "");
            });
    };
}

export function LoadFichePartnerAPI(partnerId) {
	return async (dispatch) => {
		dispatch(fichePartnerLoader(true));
		getApi(API_ENDPOINT.PARTNERS + "/" + partnerId)
			.then((res) => {
				dispatch(fichePartnerLoader(false));
				dispatch(loadFichePartner(res.data));
			})
			.catch((err) => {
				customerror("Erreur de chargement du partenaire", "");
			});
	};
}

export function addPartnerImgAPI(formData, payload2, callback) {
    return async (dispatch) => {
		axios.post(environment.BASE_URL_API + "/" + API_ENDPOINT.MEDIAS + "?action=upload", formData)
            .then((res) => {
                customsuccess('Opération terminée avec succès!  ', "")
                callback()
                dispatch(LoadFichePartnerAPI(payload2, true))
            })
            .catch((err) => {
                customerror("Erreur d'enregistrement des données", "");
            });
    };
}

export function updatePartnerImgAPI(payload, payload2, callback) {
    return async (dispatch) => {
        putApi(API_ENDPOINT.MEDIAS + "?action=upload", payload)
            .then((res) => {
                customsuccess('Opération terminée avec succès!  ', "")
                callback()
                dispatch(LoadFichePartnerAPI(payload2, true))
            })
            .catch((err) => {
                customerror("Erreur d'enregistrement des données", "");
            });
    };
}