import AuthentificationContainer from "../containers/Authentification/component";
import ContactsContainer from "../containers/Contacts/component";
import DashboardContainer from "../containers/DashBoard/component";
import ImmoConfigContainer from "../containers/Immobilier/configuration/component";
import ImmoPropContainer from "../containers/Immobilier/properties/component";
import NewsContainer from "../containers/WebSite/News/News";
import PartnersContainer from "../containers/WebSite/partners/component";

const routes = [
  {
    path: "/login",
    exact: true,
    component: AuthentificationContainer,
  },
  {
    path: "/dashboard",
    exact: true,
    component: DashboardContainer,
  },
  {
    path: "/contacts",
    exact: true,
    component: ContactsContainer,
  },
  {
    path: "/immobilier/configuration",
    exact: true,
    component: ImmoConfigContainer,
  },
  {
    path: "/immobilier/properties",
    exact: true,
    component: ImmoPropContainer,
  },
  {
    path: "/website/partners",
    exact: true,
    component: PartnersContainer,
  },
  {
    path: "/website/news",
    exact: true,
    component: NewsContainer,
  }
];

export default routes;
