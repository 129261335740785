import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import {
	loadDataGridUtil,
	loadFichePartnerUtil,
	updateFichePartnerUtil,
} from "./partners.util";

const initialState = {
	columnNames: [
		{ title: "Denomination", field: "partnerName" },
		{ title: "Site web", field: "partnerWebsite" },
		{ title: "Logo", field: "mediaSrc" },
	],
	dataGrid: [],
	dataGridLoader: false,
	selectedPartnerRow: {},
	fichePartner: {},
	fichePartnerForm: {},
	fichePartnerLoader: false,
	partnerSuivi: {}
};

const partners = createSlice({
	name: "partners",
	initialState,
	reducers: {
		loadDataGrid: (state, { payload }) => {
			state.dataGrid = loadDataGridUtil(payload);
		},
		initDatagrid: (state, { payload }) => {
			state.dataGrid = initialState.dataGrid;
		},
		setSelectedPartnerRow: (state, { payload }) => {
			state.selectedSaisonRow = payload;
		},
		setDataGrid: (state, { payload }) => {
			state.dataGrid = payload;
		},
		dataGridLoader: (state, { payload }) => {
			state.dataGridLoader = payload;
		},
		setSlectedPartnerRow: (state, { payload }) => {
			state.selectedPartnerRow = payload;
		},
		loadFichePartner: (state, { payload }) => {
			state.fichePartner = payload;
			state.fichePartnerForm = loadFichePartnerUtil(payload);
		},
		initFichePartner: (state) => {
			state.fichePartner = initialState.fichePartner;
			state.fichePartnerForm = initialState.fichePartnerForm;
			state.partnerSuivi = initialState.partnerSuivi
		},
		fichePartnerLoader: (state, { payload }) => {
			state.fichePartnerLoader = payload;
		},
		updateFichePartner: (state, { payload }) => {
			let clonedFiche = _.cloneDeep(state.fichePartnerForm);
			state.fichePartnerForm = updateFichePartnerUtil(
				payload,
				clonedFiche
			);
		},
		loadPartnerSuivi: (state, { payload }) => {
            state.partnerSuivi = payload;
        },
	},
});

export const {
	loadDataGrid,
	initDatagrid,
	setSelectedPartnerRow,
	setDataGrid,
	dataGridLoader,
	setSlectedPartnerRow,
	loadFichePartner,
	initFichePartner,
	fichePartnerLoader,
	updateFichePartner,
	loadPartnerSuivi,
} = partners.actions;

export default partners.reducer;
