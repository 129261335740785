import { getApi, postApi } from "../../api/api_methods";
import API_ENDPOINT from "../../api/api_endpoint";
import { customerror, customsuccess } from "../../components/icon_notification/customNotifications";
import { loadContactType, loadCountries, loadUserDetails } from "./authentication.slice";
import { setCookie } from "../../assets/functions";

export function authenticationAPI(payload, history) {
  // The token has a 10h life span
  const TOKEN_TTL = 10 * 3600;

  return async (dispatch) => {
    postApi(API_ENDPOINT.AUTHENTICATE, payload)
      .then((res) => {
        let { user: { token, IDSelectedEnt } } = res.data;
        let { location: { pathname } } = history;
        if (res.status === 200) {
          setCookie("token", token, TOKEN_TTL);
          setCookie("IDSelectedEnt", IDSelectedEnt, TOKEN_TTL);
          dispatch(loadUserDetails(res.data));
          dispatch(LoadCountriesAPI({}))
          dispatch(LoadTiersTypeAPI({}))
          customsuccess("Connecté avec succès", "");
          history.push("/dashboard")
        } else {
          customerror("Erreur d'authentification !", "");
        }
      })
      .catch((err) => {
        customerror("Erreur d'authentification !", "");
      });
  };
}
export function LoadTiersTypeAPI(payload) {
  return async (dispatch) => {
    getApi(API_ENDPOINT.LOAD_CONTACTS_TYPE, payload)
      .then((res) => {
        dispatch(loadContactType(res.data))
      })
      .catch((err) => {
        customerror("Erreur de chargement des types de contact", "");
      });
  };
}
export function LoadCountriesAPI(payload) {
  return async (dispatch) => {
    getApi(API_ENDPOINT.LOAD_COUNTRIES, payload)
      .then((res) => {
        dispatch(loadCountries(res.data))
      })
      .catch((err) => {
        customerror("Erreur de chargement des pays", "");
      });
  };
}