/* -----------Components---------------- */
import ToolbarButtonComponent from "../button_toolbar/toolbar_button.component";
/* -----------Assets---------------- */
import * as React from "react";


export const MyCommandCell = props => {
    /* -----------Vars------------ */
    const { dataItem } = props;
    const inEdit = dataItem[props.editField];
    /* -----------render------------ */
    return (
        inEdit ?
            <td className="k-command-cell">
                <ToolbarButtonComponent
                    icon="check-square"
                    title="Enregistrer"
                    className="k-button-solid-primary"
                    onclick={() => dataItem.IDSaison ? props.update(dataItem) : props.add(dataItem)}
                />
                <ToolbarButtonComponent
                    icon="x-square"
                    title="Annuler"
                    className="k-button-solid-error"
                    onclick={() => dataItem.IDSaison ? props.cancel(dataItem) : props.discard(dataItem)}
                />
            </td>
            :
            <td className="k-command-cell">
                <ToolbarButtonComponent
                    icon="pencil-square"
                    title="Modifier"
                    className="k-button-solid-primary"
                    onclick={() => props.edit(dataItem)}
                />
                <ToolbarButtonComponent
                    icon="trash"
                    title="Supprimer"
                    className="k-button-solid-error"
                    onclick={() => props.remove(dataItem)}
                />
            </td>);
};