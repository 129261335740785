import openNotificationWithIcon from "./icon_notification.component";

export function customsuccess(title, text) {
	openNotificationWithIcon("success", title, text, 2);
}
export function customerror(title, text) {
	openNotificationWithIcon("error", title, text, 5);
}
export function custominfo(title, text) {
	openNotificationWithIcon("info", title, text, 5);
}
export function customwarning(title, text, duration) {
	openNotificationWithIcon("warning", title, text, duration);
}
