/* -----------Components------------ */
import ToolbarButtonComponent from '../../../components/button_toolbar/toolbar_button.component';
import ImmoGestPropFormComponent from '../../../components/drawer_ImmoPropForm/component';
import DeleteFooterComponent from '../../../components/drawer_contactFooter/component';
import NextFooterComponent from '../../../components/drawer_nextToolBtn/component';
import GenericDrawer from '../../../components/generic_drawer/component';
import GenericModalComponent from '../../../components/generic_modal/component';
import HeaderComponent from '../../../components/header/component';
import ModalFooterComponent from '../../../components/modal_Footer/component';
import SettingsFormComponent from '../../../components/modal_ImmoPropColSettings/component';
import ConfirmModalComponent from '../../../components/modal_confirm/confirmModal.component';
import TableComponent from "../../../components/table/component";
/* -----------Assets------------ */
import Form from "antd/lib/form";
import Input from 'antd/lib/input';
import Tag from 'antd/lib/tag';
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadDataGridAPI, LoadImmoPropColsAPI, LoadImmoPropFicheAPI, LoadPropSuiviAPI, SaveImmoPropColsAPI, addImmoAPI, deleteImmoAPI, exportSelectedProperties, updateImmoAPI } from '../../../redux/Immobilier/properties/prop.api';
import { initFicheImmoProp, setImmoPropSelectedCols, setSlectedContactRow } from '../../../redux/Immobilier/properties/prop.slice';
/* -----------Selectors------------ */
import { ImmoPropFormSelector, allColsSelector, dataGridLoaderSelector, dataGridSelector, immoPropSelectedRowSelector, selectedColsSelector, userColsSelector } from '../../../redux/Immobilier/properties/prop.selector';
/* -----------Style Sheet------------ */
import ToolBarExportPicker from '../../../components/ToolBarExportPicker/ToolBarExportPicker';
import { custominfo } from '../../../components/icon_notification/customNotifications';
import { EXPORT_SLUG, EXPORT_TYPES, formatForExport } from '../../../redux/Immobilier/properties/prop.util';
import './styles.scss';

const ImmoPropContainer = () => {
    /* -----------selectors------------ */
    const userCols = useSelector(userColsSelector);
    const allCols = useSelector(allColsSelector);
    const gridData = useSelector(dataGridSelector);
    const selectedRow = useSelector(immoPropSelectedRowSelector);
    const gridLoader = useSelector(dataGridLoaderSelector);
    const immoPropForm = useSelector(ImmoPropFormSelector);
    const selectedCols = useSelector(selectedColsSelector);
    /* -----------State Hook------------ */
    const [gridDataFiltered, setGridDataFiltered] = useState(gridData);
    const [drawerState, setDrawerState] = useState(false);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [popUpState, setPopUpState] = useState(false);
    const [proprName, setProprName] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [selectedProperties, setSelectedProperties] = useState([]);
    /* -----------vars------------ */
    const { Search } = Input;
    const dispatch = useDispatch();
    const [immoPropF] = Form.useForm();
    const nextCdt = gridData.length > immoPropForm?.index + 1;
    const previousCdt = immoPropForm?.index > 0;
    const isUpdate = immoPropForm?.index || immoPropForm?.index === 0;
    let _exportCSV;
    /* -----------Effect Hook------------ */
    useEffect(() => {
        dispatch(LoadDataGridAPI({}))
        dispatch(LoadImmoPropColsAPI({ slug: "properties" }))
    }, []);
    useEffect(() => {
        setGridDataFiltered(gridData)
    }, [gridData]);
    /* -----------Functions------------ */
    const setExportCsv = (_exp) => {
        _exportCSV = _exp;
    };

    const handle_exportExcel = () => {
        if (hasSelectedPropertiesToExport()) {
            _exportCSV.save();
            exportSelection()
        }
    };

    const handle_exportLeBonCoin = () => {
        exportSelection()
    };

    const exportSelection = () => {
        exportSelectedPropertiesToAPI(formatForExport(selectedProperties))

    }

    const saveSelection = (selectionName) => {
        exportSelectedPropertiesToAPI(formatForExport(selectedProperties, EXPORT_SLUG.Properties, selectionName, EXPORT_TYPES.Saved))
    }

    const exportSelectedPropertiesToAPI = (formattedData) => {
        if (hasSelectedPropertiesToExport()) {
            dispatch(exportSelectedProperties(formattedData))
        }
    }

    const loadExport = (_export) => {
        const exportedPropIds = _export.map(prop => prop.IDObject)
        setSelectedProperties(gridData.filter(prop => exportedPropIds.includes(prop.IDProperty)))
    }

    const hasSelectedPropertiesToExport = () => {
        if (selectedProperties.length === 0) {
            custominfo("Sélection vide", "Veuillez sélectionner des propriétés à exporter")
        }
        return selectedProperties.length > 0;
    }

    const oneClickHandler = (e) => {
        if (selectedRow?.IDProperty === e?.IDProperty) {
            dispatch(setSlectedContactRow({}))
        } else {
            dispatch(setSlectedContactRow(e))
        }
    }
    const doubleClickHandler = (e) => {
        const { dataItem: { IDProperty, Denomination } } = e;
        dispatch(LoadImmoPropFicheAPI(IDProperty))
        dispatch(LoadPropSuiviAPI({ object: "properties", idobject: IDProperty }));
        setProprName(Denomination)
        setDrawerState(!drawerState);
    }
    const onSearch_Filter = (input) => {
        let { target: { value } } = input;
        let filterVal = input.target ? value : input;
        let filtredGrid = [];
        if (filterVal) {
            gridData.map(item => {
                for (const [key, value] of Object.entries(item)) {
                    if (value && value.toString().toUpperCase().includes(filterVal.toUpperCase())) {
                        if (filtredGrid.indexOf(item) === -1)
                            filtredGrid.push(item);
                    }
                }
            })
            setGridDataFiltered(filtredGrid)
        } else {
            setGridDataFiltered(gridData)
        }
        setSearchValue(filterVal)
    }
    const handleCancel_Drawer = () => {
        closeClearDrawer()
    }
    const handleOk_Callback = () => {
        setSearchValue("")
        closeClearDrawer()
    }
    const handleOk_Drawer = () => {
        let formData = _.cloneDeep(immoPropForm);
        if (formData['category']) {
            formData['category'].map(item => {
                formData[`category[${item}]`] = item;
            })
        }
        for (const [key, value] of Object.entries(formData)) {
            if (key.includes('proprio') || ['index', 'category'].includes(key)) {
                delete formData[key];
            }
        }
        if (formData['data_int[IDProperty]']) {
            dispatch(updateImmoAPI(formData, handleOk_Callback))
        } else {
            dispatch(addImmoAPI(formData, handleOk_Callback))
        }

    }
    const closeClearDrawer = () => {
        immoPropF.resetFields()
        dispatch(initFicheImmoProp({}))
        setDrawerState(!drawerState)
    }
    const handleNext_Drawer = () => {
        let nextID = gridData[immoPropForm.index + 1]?.IDProperty
        dispatch(LoadImmoPropFicheAPI(nextID))
        dispatch(LoadPropSuiviAPI({ object: "properties", idobject: nextID }));
        setProprName(gridData[immoPropForm.index + 1]?.Denomination)
    }
    const handlePrevious_Drawer = () => {
        let previousID = gridData[immoPropForm.index - 1]?.IDProperty
        dispatch(LoadPropSuiviAPI({ object: "properties", idobject: previousID }));
        dispatch(LoadImmoPropFicheAPI(previousID))
        setProprName(gridData[immoPropForm.index - 1]?.Denomination)
    }
    const handle_delete = () => {
        setConfirmVisible(!confirmVisible)
    }
    const handle_duplic = () => {
        let formData = _.cloneDeep(immoPropForm);
        if (formData['category']) {
            formData['category'].map(item => {
                formData[`category[${item}]`] = item;
            })
        }
        for (const [key, value] of Object.entries(formData)) {
            if (key.includes('proprio') || ['index', 'category', 'data_int[IDProperty]'].includes(key)) {
                delete formData[key];
            }
        }
        dispatch(addImmoAPI(formData, handleOk_Callback))
    }
    const addImmoProp_trigger = () => {
        setProprName("Ajouter une propriété")
        setDrawerState(!drawerState)
    }
    const confirm_delete = () => {
        dispatch(deleteImmoAPI(immoPropForm['data_int[IDProperty]'], handleOk_Callback))
        setConfirmVisible(!confirmVisible)
        setSearchValue('')
    }
    const cancel_delete = () => {
        setConfirmVisible(!confirmVisible)
    }
    const refresh_grid = () => {
        dispatch(LoadDataGridAPI({}))
        setSearchValue('')
    }
    const handleOk_ColSettings = () => {
        let payload = {}
        selectedCols.map((id, index) => {
            payload[`column[${id}][IDColumn]`] = id;
            payload[`column[${id}][displayOrder]`] = index + 1;
        })
        dispatch(SaveImmoPropColsAPI(payload))
        setPopUpState(!popUpState)
    }
    const handleCancel_ColSettings = () => {
        setPopUpState(!popUpState)
    }
    const handleRest_Cols = () => {
        let clonedCols = _.cloneDeep(allCols);
        let colIds = [], payload = {};
        colIds = clonedCols
            .filter(item => item.isDefault)
            .map(item => item.IDColumn);
        colIds.map((id, index) => {
            payload[`column[${id}][IDColumn]`] = id;
            payload[`column[${id}][displayOrder]`] = index + 1;
        })
        dispatch(SaveImmoPropColsAPI(payload))
        setPopUpState(!popUpState)
    }
    const handleCheckBoxChange = (isChecked, property) => {
        if (isChecked) {
            setSelectedProperties(old => verifyUnicity(...old, property))
        } else {
            setSelectedProperties(old => verifyUnicity(...old.filter(prop => prop.IDProperty !== property.IDProperty)))
        }
    }
    const verifyUnicity = (...values) => {
        const uniqIds = new Set()
        const uniqValues = []
        for (const value of values) {
            if (!uniqIds.has(value.IDProperty)) {
                uniqValues.push(value)
            }
            uniqIds.add(value.IDProperty)
        }
        return uniqValues;
    }
    /* -----------UI------------ */
    const gridToolbar = (
        <div className="toolbar_utils">
            <div className="toolbar_btns">
                <Search placeholder="Recherche" onSearch={onSearch_Filter} onChange={onSearch_Filter} style={{ width: 300 }} size="middle" value={searchValue} />
            </div>
            <div className="toolbar_icns">
                <ToolBarExportPicker
                    onSelectionSave={saveSelection}
                    onExportSelection={loadExport}
                />
                <ToolbarButtonComponent
                    icon="arrow-clockwise"
                    title="Actualiser"
                    className="k-button-outline-secondary"
                    onclick={refresh_grid}
                />
                <ToolbarButtonComponent
                    icon="wrench-adjustable"
                    title="Paramétrer les colonnes"
                    className="k-button-outline-primary"
                    onclick={() => { setPopUpState(!popUpState) }}
                />
                <ToolbarButtonComponent
                    icon="box"
                    title="Exporter Le Bon Coin"
                    id="lbc-export-btn"
                    onclick={handle_exportLeBonCoin}
                />
                <ToolbarButtonComponent
                    icon="filetype-xlsx"
                    title="Exporter Excel"
                    className="k-button-outline-success"
                    onclick={handle_exportExcel}
                />
                <ToolbarButtonComponent
                    icon="plus-circle-dotted"
                    text="Ajouter"
                    title="Créer un nouveau contact"
                    className="k-button-solid-primary"
                    onclick={addImmoProp_trigger}
                />
            </div>
        </div>
    );
    const cellRender = (td, props) => {
        let { field, dataItem } = props;
        if (field && field === "categories") {
            let typetiers = dataItem[field].map((item, i) => {

                return <Tag key={i}> {item}</Tag>
            })
            return React.cloneElement(td, td.props, typetiers);
        } else if (field && field === "statut") {
            let statut =
                <Tag
                    color={dataItem[field] === "À vendre" ? 'green' : 'orange'}>
                    {dataItem[field]}
                </Tag>
            return React.cloneElement(td, td.props, statut);
        }
        return td
    }
    /* -----------render------------ */
    return (
        <div className="immoProp">
            <section className="header">
                <HeaderComponent title="Gestion des propriétés" icon="people-fill" />
            </section>
            <section className="page_container">
                <div className="main_content">
                    <div className="table-responsive">
                        <TableComponent
                            handleRowDoubleClick={doubleClickHandler}
                            gridlist={gridDataFiltered}
                            columnNames={userCols}
                            loader={gridLoader}
                            gridToolBar={gridToolbar}
                            handleRowClick={oneClickHandler}
                            selectedID={selectedRow}
                            setExportCsv={setExportCsv}
                            excelFilename="Tiers_list"
                            cellRender={cellRender}
                            rowHasCheckbox
                            handleCheckboxChange={handleCheckBoxChange}
                            selectedProperties={selectedProperties}
                        />
                    </div>
                </div>
            </section>
            <section className="drawers">
                {drawerState ?
                    <GenericDrawer
                        title={proprName}
                        size="90vw"
                        visible={drawerState}
                        handleOk={handleOk_Drawer}
                        handleCancel={handleCancel_Drawer}
                        extra={NextFooterComponent({ handleOk_Drawer, handleCancel_Drawer, handleNext_Drawer, handlePrevious_Drawer, nextCdt, previousCdt, isUpdate, handle_duplic })}
                        footer={DeleteFooterComponent({ handle_delete, isUpdate })}
                    >
                        <ImmoGestPropFormComponent form={immoPropF} />
                    </GenericDrawer>
                    :
                    null
                }
                {confirmVisible ?
                    <ConfirmModalComponent
                        title='Confirmation de suppression'
                        text='Voulez vous supprimer cet élément ?'
                        popUpState={confirmVisible}
                        handleOk={confirm_delete}
                        handleCancel={cancel_delete} /> : null}
                {popUpState ?
                    <GenericModalComponent
                        title="Paramètrage des colonnes"
                        width="40%"
                        popUpState={popUpState}
                        handleCancel={handleCancel_ColSettings}
                        handleOk={handleOk_ColSettings}
                        footer={ModalFooterComponent}
                        localAction={handleRest_Cols}
                        slug="occupations"
                    >
                        <SettingsFormComponent selectedkeys={allCols}
                            targetkeys={userCols} setSelectedCols={setImmoPropSelectedCols} />
                    </GenericModalComponent>
                    : null
                }
            </section>
        </div>
    )
}

export default ImmoPropContainer
