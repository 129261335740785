import notification from 'antd/lib/notification';



const openNotificationWithIcon = (type, message, description, duration) => {
    notification[type]({
        message,
        description,
        duration: duration ? duration : 1,
        placement: 'bottomRight'
    });
};

export default openNotificationWithIcon;