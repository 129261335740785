/* -----------Components------------ */
import ToolbarButtonComponent from "../../button_toolbar/toolbar_button.component";
import UpdatePicModalComponent from "../../modal_updatePIC/component";
/* -----------Assets------------ */
import Col from "antd/lib/col";
import Empty from "antd/lib/empty";
import Row from "antd/lib/row";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
/* -----------Selectors------------ */
import { clearImageModal } from "../../../redux/Immobilier/properties/prop.slice";
import { addPartnerImgAPI } from "../../../redux/WebSIte/partners/partners.api";
import { fichePartnerFormSelector } from "../../../redux/WebSIte/partners/partners.selector";
/* -----------Style Sheet------------ */
import "./styles.scss";

const PhotoPartnerComponent = ({ addPartnerImgTrigger }) => {
	/* -----------State Hook------------ */
	const [popUpState, setPopUpState] = useState(false);

	/* -----------Selector------------ */
	const fichePartner = useSelector(fichePartnerFormSelector);

	/* -----------Vars------------ */
	const dispatch = useDispatch();
	const cropperRef = useRef(null);

	/* -----------Functions------------ */
	const CancelPictureUpdate = () => {
		clearPopUpImage();
	};

	const SavePictureUpdate = async () => {
		let croppedBlob = await new Promise(async (resolve) => {
			cropperRef.current?.cropper.getCroppedCanvas().toBlob((blob) => {
				resolve(blob);
			}, "image/jpeg");
		});
		const formData = new FormData();
		formData.append(
			"file[]",
			croppedBlob,
			fichePartner["data_str[partnerName]"] + "-logo"
		);
		formData.append("category", "partner");
		formData.append("idObject", fichePartner["data_int[IDPartner]"]);
		formData.append(
			"mediaLegende_FR",
			fichePartner["data_str[partnerName]"] + "-logo"
		);
		dispatch(
			addPartnerImgAPI(
				formData,
				fichePartner["data_int[IDPartner]"],
				clearPopUpImage
			)
		);
	};

	const clearPopUpImage = () => {
		dispatch(clearImageModal());
		addPartnerImgTrigger();
		setPopUpState(false);
	};

	const addImage = () => {
		setPopUpState(true);
	};

	const onUpdate = () => {
		setPopUpState(true);
	};

	const displayImageAddButton = () => {
		if (!fichePartner["data_str[mediaSrc]"]) {
			return (
				<div className="emtycontainer">
					<Empty />
				</div>
			);
		}

		return (
			<Col className="img-col">
				<div className="img_container">
					<img
						id={fichePartner["data_str[mediaSrc]"]}
						src={fichePartner["data_str[mediaSrc]"]}
						alt={fichePartner["data_str[partnerName]"] + " logo"}
					/>
					<div className="btns_tools">
						<ToolbarButtonComponent
							icon="pencil-square"
							title="Modifier l'image"
							className="k-button-solid-info"
							onclick={() => onUpdate()}
						/>
					</div>
				</div>
			</Col>
		);
	};

	return (
		<div className="photosPartner">
			<Row className="picsContainer" gutter={[16, 24]}>
				{displayImageAddButton()}
			</Row>
			{!fichePartner["data_str[mediaSrc]"] ? (
				<div className="header">
					{fichePartner["data_int[IDPartner]"] ? (
						<ToolbarButtonComponent
							icon="plus-circle-dotted"
							text="Ajouter"
							title="Ajouter une image"
							className="k-button-solid-primary"
							onclick={addImage}
							disabled={
								!fichePartner["data_int[IDPartner]"] ||
								(fichePartner["data_int[IDPartner]"] &&
									fichePartner["data_str[mediaSrc]"])
							}
						/>
					) : (
						<p>
							Enregistrer le partenaire pour pouvoir ajouter un logo
						</p>
					)}
				</div>
			) : null}
			<section className="modals">
				{popUpState ? (
					<UpdatePicModalComponent
						title={
							fichePartner["data_str[mediaSrc]"]
								? "Modifier une image"
								: "Ajouter une image"
						}
						popUpState={popUpState}
						handleOk={SavePictureUpdate}
						handleCancel={CancelPictureUpdate}
						width="70vw"
						cropperRef={cropperRef}
						aspectRatio={170 / 65}
					/>
				) : null}
			</section>
			<div className="consignes">
                <ul className="pb-0">
                    <li>Le poids de la photo ne doit pas être supérieur à 500 Ko</li>
                    <li>Pour une présentation optimale, il est préférable d'utiliser des photos d'une taille minimale de 170x65 pixels</li>
                </ul>
            </div>
		</div>
	);
};

export default PhotoPartnerComponent;
