import { combineReducers } from "redux";
/* -----------Reducers------------ */
import authenticationSlice from "./Authentification/authentication.slice";
import storage from 'redux-persist/lib/storage';
import contactsSlice from "./contacts/contacts.slice";
import immoConfigSlice from "./Immobilier/config/config.slice";
import immoPropSlice from "./Immobilier/properties/prop.slice";
import referentielSlice from "./referentiel/ref.slice";
import partnersSlice from "./WebSIte/partners/partners.slice";
import newsSlice from "./WebSIte/news/news.slice";
/* -----------COMBINE------------ */
const appReducer = combineReducers({
  authentication: authenticationSlice,
  contacts: contactsSlice,
  immoConfig: immoConfigSlice,
  immoProp: immoPropSlice,
  referentiel: referentielSlice,
  partners: partnersSlice,
  news: newsSlice
});

const rootReducer = (state, action) => {
  if (action?.type === "authentication/logOut") {
    storage.removeItem('persist:root');
    return appReducer(undefined, action);
  } else {
    return appReducer(state, action)
  }
}
export default rootReducer;
