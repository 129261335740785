import {
  BarChartOutlined,
  TeamOutlined,
  GlobalOutlined,
  HomeOutlined
} from "@ant-design/icons";
const MENU_ITEMS = {
  dashboard: {
    ID: "0001",
    icon: <BarChartOutlined />,
    path: "/dashboard",
    MenuName: "Tableau de bord",
  },
  contacts: {
    ID: 1,
    icon: <TeamOutlined />,
    path: "/contacts",
    MenuName: "Contacts",
  },
  immobilier: {
    ID: 2,
    icon: <HomeOutlined />,
    MenuName: "Immobiliers",
    subMenu: [
      {
        ID: 7,
        MenuName: "Propriétés",
        path: "/immobilier/properties",
      },
      {
        ID: 8,
        MenuName: "Configuration",
        path: "/immobilier/configuration",
      }
    ],
  },
  website: {
    ID: 9,
    icon: <GlobalOutlined />,
    MenuName: "Site Web",
    subMenu: [
      {
        ID: 10,
        MenuName: "Actualités",
        path: "/website/news",
      },
      {
        ID: 11,
        MenuName: "Partenaires",
        path: "/website/partners",
      }
    ],
  }
};

export default MENU_ITEMS;
