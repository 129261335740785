/* -----------Components------------ */
import ToolbarButtonComponent from '../../components/button_toolbar/toolbar_button.component';
import DeleteFooterComponent from '../../components/drawer_contactFooter/component';
import ContactFicheFormComponent from '../../components/drawer_contactForm/component';
import NextFooterComponent from '../../components/drawer_nextToolBtn/component';
import GenericDrawer from '../../components/generic_drawer/component';
import HeaderComponent from '../../components/header/component';
import ConfirmModalComponent from '../../components/modal_confirm/confirmModal.component';
import TableComponent from "../../components/table/component";
/* -----------Assets------------ */
import Form from "antd/lib/form";
import Input from 'antd/lib/input';
import Tag from 'antd/lib/tag';
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadDataGridAPI, LoadFicheTiersAPI, LoadTiersSuiviAPI, deleteTiersAPI, saveTiersAPI, updateTiersAPI } from '../../redux/contacts/contacts.api';
import { initFicheTiers, setSlectedContactRow, updateFicheTiers } from '../../redux/contacts/contacts.slice';
/* -----------Selectors------------ */
import { contactTypeSelector } from '../../redux/Authentification/authentication.selector';
import { tiersColumnsSelector, tiersCompleteFicheSelector, tiersDataGridSelector, tiersFicheSelector, tiersGridloaderSelector, tiersSelectedRowSelector } from '../../redux/contacts/contacts.selector';
/* -----------Style Sheet------------ */
import './styles.scss';

const ContactsContainer = () => {
    /* -----------selectors------------ */
    const columnNames = useSelector(tiersColumnsSelector);
    const gridData = useSelector(tiersDataGridSelector);
    const selectedRow = useSelector(tiersSelectedRowSelector);
    const contactType = useSelector(contactTypeSelector);
    const gridLoader = useSelector(tiersGridloaderSelector);
    const infoG = useSelector(tiersFicheSelector);
    const tiersFicheData = useSelector(tiersCompleteFicheSelector);
    const contactTypeList = useSelector(contactTypeSelector)
    /* -----------State Hook------------ */
    const [gridDataFiltred, setGridDataFiltred] = useState(gridData);
    const [drawerState, setDrawerState] = useState(false);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [contactName, setContactName] = useState("");
    const [serachValue, setSerachValue] = useState("");
    /* -----------vars------------ */
    const { Search } = Input;
    const dispatch = useDispatch();
    const [contactForm] = Form.useForm();
    const nextCdt = gridData.length > infoG?.index + 1;
    const previousCdt = infoG?.index > 0;
    const isUpdate = infoG?.index || infoG?.index === 0;
    let _export;
    /* -----------Effect Hook------------ */
    useEffect(() => {
        dispatch(LoadDataGridAPI({ atype: "all" }, contactTypeList))
    }, []);
    useEffect(() => {
        setGridDataFiltred(gridData)
    }, [gridData]);
    /* -----------Functions------------ */
    const setExportCsv = (_exp) => {
        _export = _exp;
    };
    const handle_exportExcel = () => {
        _export.save();
    };
    const oneClickHandler = (e) => {
        if (selectedRow?.IDTiers === e?.IDTiers) {
            dispatch(setSlectedContactRow({}))
        } else {
            dispatch(setSlectedContactRow(e))
        }
    }
    const doubleClickHandler = (e) => {
        const { dataItem: { IDTiers, Denomination } } = e;
        setContactName(Denomination)
        dispatch(LoadFicheTiersAPI(IDTiers));
        dispatch(LoadTiersSuiviAPI({ object: "tiers", idobject: IDTiers }));
        setDrawerState(!drawerState);
    }
    const onSearch_Filter = (input) => {
        let { target: { value } } = input;
        let filterVal = input.target ? value : input;
        let filtredGrid = [];
        if (filterVal) {
            gridData.map(item => {
                for (const [key, value] of Object.entries(item)) {
                    if (value && value.toString().toUpperCase().includes(filterVal.toUpperCase())) {
                        if (filtredGrid.indexOf(item) === -1)
                            filtredGrid.push(item);
                    }
                }
            })
            setGridDataFiltred(filtredGrid)
        } else {
            setGridDataFiltred(gridData)
        }
        setSerachValue(filterVal)
    }
    const handleCancel_Drawer = () => {
        closeClearDrawer()
    }
    const handleOk_Callback = () => {
        setSerachValue("")
        closeClearDrawer()
    }
    const handleOk_Drawer = () => {
        let formData = _.cloneDeep(infoG);
        if (formData['data_str[IDType]']) {
            formData['data_str[IDType]'].map(item => {
                formData[`type[${item}]`] = item;
            })
        }
        delete formData.index;
        delete formData['data_str[IDType]'];
        if (formData['data_int[IDTiers]']) {
            dispatch(updateTiersAPI(formData, handleOk_Callback, contactTypeList))
        } else {
            dispatch(saveTiersAPI(formData, handleOk_Callback, contactTypeList))
        }

    }
    const closeClearDrawer = () => {
        contactForm.resetFields()
        dispatch(initFicheTiers({}))
        setDrawerState(!drawerState)
    }
    const handleNext_Drawer = () => {
        let nextID = gridData[infoG.index + 1]?.IDTiers
        dispatch(LoadFicheTiersAPI(nextID))
        dispatch(LoadTiersSuiviAPI({ object: "tiers", idobject: nextID }));
        setContactName(gridData[infoG.index + 1]?.Denomination)
    }
    const handlePrevious_Drawer = () => {
        let previousID = gridData[infoG.index - 1]?.IDTiers
        dispatch(LoadFicheTiersAPI(previousID))
        dispatch(LoadTiersSuiviAPI({ object: "tiers", idobject: previousID }));
        setContactName(gridData[infoG.index - 1]?.Denomination)
    }
    const handle_delete = () => {
        setConfirmVisible(!confirmVisible)
    }
    const handle_duplic = () => {
        let formData = _.cloneDeep(infoG);
        if (formData['data_str[IDType]']) {
            formData['data_str[IDType]'].map(item => {
                formData[`type[${item}]`] = item;
            })
        }
        delete formData.index;
        delete formData['data_str[IDType]'];
        delete formData['data_int[IDTiers]'];
        dispatch(saveTiersAPI(formData, handleOk_Callback, contactTypeList))
    }
    const addContact_trigger = () => {
        setDrawerState(!drawerState)
        setContactName("Ajouter un contact")
    }
    const ontiersForm_change = (e) => {
        dispatch(updateFicheTiers(e))
    }
    const confirm_delete = () => {
        dispatch(deleteTiersAPI(tiersFicheData?.IDTiers, handleOk_Callback, contactTypeList))
        setConfirmVisible(!confirmVisible)
        setSerachValue('')
    }
    const cancel_delete = () => {
        setConfirmVisible(!confirmVisible)
    }
    const refresh_grid = () => {
        dispatch(LoadDataGridAPI({ atype: "all" }, contactTypeList))
        setSerachValue('')
    }
    /* -----------UI------------ */
    const gridToolbar = (
        <div className="toolbar_utils">
            <div className="toolbar_btns">
                <Search placeholder="Recherche" onSearch={onSearch_Filter} onChange={onSearch_Filter} style={{ width: 300 }} size="middle" value={serachValue} />
            </div>
            <div className="toolbar_icns">
                <ToolbarButtonComponent
                    icon="arrow-clockwise"
                    title="Actualiser"
                    className="k-button-outline-secondary"
                    onclick={refresh_grid}
                />
                <ToolbarButtonComponent
                    icon="filetype-xlsx"
                    title="Exporter Excel"
                    className="k-button-outline-success"
                    onclick={handle_exportExcel}
                />
                <ToolbarButtonComponent
                    icon="plus-circle-dotted"
                    text="Ajouter"
                    title="Créer un nouveau contact"
                    className="k-button-solid-primary"
                    onclick={addContact_trigger}
                />
            </div>
        </div>
    );
    const cellRender = (td, props) => {
        let { field, dataItem } = props;
        if (field && field === "IDType") {
            let typetiers = dataItem[field]?.split(",").map(item => {
                let typeIndex = contactType.findIndex(type => type.Libelle === item)
                if (typeIndex !== -1 && contactType)
                    return <Tag /*color={contactType[typeIndex].Color}*/ color="magenta" key={contactType[typeIndex].IDType}> {contactType[typeIndex].Libelle}</Tag>
            })
            return React.cloneElement(td, td.props, typetiers);
        }
        return td
    }

    /* -----------render------------ */
    return (
        <div className="contacts">
            <section className="header">
                <HeaderComponent title="Contacts" icon="people-fill" />
            </section>
            <section className="page_container">
                <div className="main_content">
                    <div className="table-responsive">
                        <TableComponent
                            handleRowDoubleClick={doubleClickHandler}
                            gridlist={gridDataFiltred}
                            columnNames={columnNames}
                            loader={gridLoader}
                            gridToolBar={gridToolbar}
                            handleRowClick={oneClickHandler}
                            selectedID={selectedRow}
                            cellRender={cellRender}
                            setExportCsv={setExportCsv}
                            excelFilename="Tiers_list"
                        />
                    </div>
                </div>
            </section>
            <section className="drawers">
                {drawerState ?
                    <GenericDrawer
                        title={contactName}
                        size="90vw"
                        visible={drawerState}
                        handleOk={handleOk_Drawer}
                        handleCancel={handleCancel_Drawer}
                        extra={NextFooterComponent({ handleOk_Drawer, handleCancel_Drawer, handleNext_Drawer, handlePrevious_Drawer, nextCdt, previousCdt, isUpdate, handle_duplic })}
                        footer={DeleteFooterComponent({ handle_delete, isUpdate })}
                    >
                        <ContactFicheFormComponent form={contactForm} ontiersForm_change={ontiersForm_change} />
                    </GenericDrawer>
                    :
                    null
                }
                {confirmVisible ?
                    <ConfirmModalComponent
                        title='Confirmation de suppression'
                        text='Voulez vous supprimer cet élément ?'
                        popUpState={confirmVisible}
                        handleOk={confirm_delete}
                        handleCancel={cancel_delete} /> : null}
            </section>
        </div>
    )
}

export default ContactsContainer
