/* -----------Components------------ */
/* -----------Assets------------ */
import React from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
/* -----------Selectors------------ */

/* -----------Style Sheet------------ */
import "./styles.scss";


const InfoPratiqueComponent = () => {
    /* -----------State Hook------------ */

    /* -----------Vars------------ */
    /* -----------Functions------------ */

    return (
        <div className="infoPratique_immoGestProp">
            <Row className="limite">
                <Col md={24} xl={12} >
                    <Form.Item label="Code Porte" name="data_str[CodePorte]">
                        <Input size="small" />
                    </Form.Item>
                </Col>
                <Col md={24} xl={12} >
                    <Form.Item label="Code Portail" name="data_str[CodePortail]">
                        <Input size="small" />
                    </Form.Item>
                </Col>
                <Col md={24} xl={12} >
                    <Form.Item label="Code Alarme" name="data_str[CodeAlarme]">
                        <Input size="small" />
                    </Form.Item>
                </Col>
                <Col md={24} xl={12} >
                    <Form.Item label="Autre" name="data_str[CodeAutre]">
                        <Input size="small" />
                    </Form.Item>
                </Col>

            </Row>
        </div>
    );
};

export default InfoPratiqueComponent;
