/* -----------Component------------ */
import GenericModalComponent from "../../../components/generic_modal/component";
import ModalFooterComponent from "../../modal_Footer/component";
import ConfirmModalComponent from "../../modal_confirm/confirmModal.component";
import TableComponent from "../../table/component";
import FicheContact from "../ficheContact/component";
/* -----------Assets------------ */
import Form from "antd/lib/form";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadFicheContactAPI, addContactAPI, deleteContactAPI, updateContactAPI } from "../../../redux/contacts/contacts.api";
import { loadFicheContact, updateFicheContact } from "../../../redux/contacts/contacts.slice";
/* -----------Selectors------------ */
import { contactFicheSelector, contactGridSelector, tiersCompleteFicheSelector, tiersFicheSelector } from "../../../redux/contacts/contacts.selector";
/* -----------Style Sheet------------ */
import ToolbarButtonComponent from "../../button_toolbar/toolbar_button.component";
import "./styles.scss";

export const contactTypes = [
    { label: 'Entreprise', value: 'ENTREPRISE' },
    { label: 'Particulier', value: 'PARTICULIER' },
];

const ContactsComponent = () => {
    /* -----------State Hook------------ */
    const [selectedRow, setSelectedRow] = useState({});
    const [popUpState, setPopUpState] = useState(false);
    const [isSelectedContact, setIsSelectedContact] = useState(false);
    const [confirmVisible, setCcnfirmVisible] = useState(false);
    /* -----------Selector------------ */
    const ficheContact = useSelector(contactFicheSelector);
    const gridData = useSelector(contactGridSelector);
    const tiersFiche = useSelector(tiersCompleteFicheSelector);
    const infoG = useSelector(tiersFicheSelector);
    /* -----------Vars------------ */
    const columnNames = [
        { title: "Dénomination", field: "contactDenomination" },
        { title: "Fonction", field: "contactFonction" },
        { title: "Tél.", field: "contactTelephone" },
        { title: "Mail", field: "contactMail" },
        { title: "Adresse", field: "contactAdresse" },
    ];
    const [contactForm] = Form.useForm();
    const dispatch = useDispatch();
    /* -----------Effect Hooks------------ */
    useEffect(() => {
        contactForm.setFieldsValue(ficheContact)
    }, [ficheContact])
    /* -----------Functions------------ */
    const oneClickHandler = (e) => {
        if (selectedRow?.ID === e?.ID) {
            setSelectedRow({})
        } else {
            setSelectedRow(e)
        }
    }
    const doubleClickHandler = (e) => {
        const { dataItem: { IDContact } } = e
        dispatch(LoadFicheContactAPI(IDContact, infoG["data_int[IDTiers]"]))
        setPopUpState(!popUpState)
        setIsSelectedContact(true)
    }
    const handleCancel_PopUp = () => {
        clearCloseContactForm();
    }
    const handleOk_PopUp = () => {
        let formData = _.cloneDeep(ficheContact);
        formData['IDTiers'] = tiersFiche?.IDTiers;
        if (formData['CompanyType'] === contactTypes[0].value) {
            formData['Nom'] = null
            formData['Prenom'] = null
        } else {
            formData['Denomination'] = null
        }
        if (isSelectedContact && ficheContact['IDContact']) {
            dispatch(updateContactAPI(formData, clearCloseContactForm, tiersFiche?.IDTiers))
        } else {
            dispatch(addContactAPI(formData, clearCloseContactForm, tiersFiche?.IDTiers))
        }
    }
    const trigger_addContact = () => {
        setPopUpState(!popUpState);
        setIsSelectedContact(false)
    }
    const clearCloseContactForm = () => {
        setPopUpState(!popUpState);
        dispatch(loadFicheContact({}));
        contactForm.resetFields();
        setIsSelectedContact(false)
    }
    const onContactsForm_change = (e) => {
        dispatch(updateFicheContact({
            ...e,
            "IDTiers": tiersFiche?.IDTiers
        }))
    }
    const delete_Contact = () => {
        setCcnfirmVisible(!confirmVisible)
    }
    const confirm_delete = () => {
        dispatch(deleteContactAPI(selectedRow?.IDContact, tiersFiche?.IDTiers, delete_callback))
    }
    const delete_callback = () => {
        setCcnfirmVisible(!confirmVisible)
        setSelectedRow({})
    }
    const cancel_delete = () => {
        setCcnfirmVisible(!confirmVisible)
    }
    //UI element (grid toolbar)
    const gridToolbar = (
        <div className="tool_buttons">
            <ToolbarButtonComponent
                icon="trash"
                title="Supprimer Contact"
                className="k-button-solid-error"
                onclick={delete_Contact}
                disabled={!selectedRow?.IDContact}
            />
            <ToolbarButtonComponent
                icon="plus-circle-dotted"
                text="Ajouter"
                title="Ajouter Contact"
                className="k-button-solid-primary"
                onclick={trigger_addContact}
                disabled={!tiersFiche?.IDTiers}
            />
        </div>
    );
    return (
        <>
            <div className="table_container_contact_tiers">
                <TableComponent
                    handleRowDoubleClick={doubleClickHandler}
                    gridlist={gridData}
                    columnNames={columnNames}
                    loader={false}
                    gridToolBar={gridToolbar}
                    handleRowClick={oneClickHandler}
                    selectedID={selectedRow}
                    notpageable={true}
                />
            </div>
            <section className="modals">
                {popUpState &&
                    <GenericModalComponent
                        title="Contacts"
                        width="50%"
                        popUpState={popUpState}
                        handleCancel={handleCancel_PopUp}
                        handleOk={handleOk_PopUp}
                        footer={ModalFooterComponent}
                    >
                        <FicheContact form={contactForm} isSelected={isSelectedContact} onContactsForm_change={onContactsForm_change} />
                    </GenericModalComponent>
                }
            </section>
            <section className="modals">
                {confirmVisible ?
                    <ConfirmModalComponent
                        title='Confirmation de suppression'
                        text='Voulez vous supprimer ce contact?'
                        popUpState={confirmVisible}
                        handleOk={confirm_delete}
                        handleCancel={cancel_delete} /> : null}
            </section>
        </>
    );
};

export default ContactsComponent;
