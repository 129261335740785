import Avatar from "antd/lib/avatar";
import Col from "antd/lib/col";
import Comment from "antd/lib/comment";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Menu from "antd/lib/menu";
import Row from "antd/lib/row";
import Spin from "antd/lib/spin";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import userImage from "../../assets/images/user.png";
import { userDetailsSelector } from "../../redux/Authentification/authentication.selector";
import {
	deleteFromFiche,
	updateFiche,
} from "../../redux/Immobilier/properties/prop.slice";
import {
	LoadFicheNewsAPI,
	LoadNewsSuiviAPI,
	SaveNewsSuiviAPI,
} from "../../redux/WebSIte/news/news.api";
import {
	ficheNewsFormSelector,
	loaderficheNewsSelector,
	newsSuiviSelector,
} from "../../redux/WebSIte/news/news.selector";
import "./NewsDrawer.scss";
import NewsForm from "./NewsForm/NewsForm";

const NewsDrawer = ({ form, _onNewsFormChange }) => {
	/* -----------State Hook------------ */
	const [suiviValue, setSuiviValue] = useState("");
	const [choosedSidemenuKey, setChoosedSidemenuKey] = useState([
		"news",
		"suivi",
	]);

	/* -----------Selectors------------ */
	const newsFiche = useSelector(ficheNewsFormSelector);
	const loader = useSelector(loaderficheNewsSelector);
	const newsSuivi = useSelector(newsSuiviSelector);
	let LOGGED_USER = useSelector(userDetailsSelector);

	/* -----------Effect Hooks------------ */
	useEffect(() => {
		form.setFieldsValue(newsFiche);
	}, [newsFiche]);

	useEffect(() => {
		if (Object.values(newsFiche).length) {
			dispatch(LoadFicheNewsAPI(newsFiche["data_int[IDNews]"]));
			dispatch(LoadNewsSuiviAPI(newsFiche["data_int[IDNews]"]));
		}
	}, []);

	/* -----------Vars------------ */
	const dispatch = useDispatch();
	const { TextArea } = Input;

	/* -----------Functions------------ */
	const handleSuiviChange = (e) => {
		let {
			target: { value },
		} = e;
		setSuiviValue(value);
	};

	const handleSuiviSubmit = () => {
		let payload = {
			Objet: "news",
			IDObjet: newsFiche["data_int[IDNews]"],
			Event: suiviValue,
		};
		dispatch(
			SaveNewsSuiviAPI(
				payload,
				newsFiche["data_int[IDNews]"],
				setSuiviValue
			)
		);
	};

	const onNewsFormChange = (editedVal, allVal) => {
		let key = Object.keys(editedVal)[0];
		if (
			key.includes("equipment") ||
			key.includes("pforts") ||
			key.includes("services")
		) {
			if (newsFiche[key]) {
				dispatch(deleteFromFiche({ [key]: "" }));
			} else {
				dispatch(
					updateFiche({ [key]: parseInt(key.match(/\[(.*?)\]/)[1]) })
				);
			}
		} else {
			if (editedVal[key] || editedVal[key] === 0) {
				dispatch(updateFiche(editedVal));
			} else {
				dispatch(updateFiche({ [key]: null }));
			}
		}
	};

	return (
		<Spin spinning={loader} size="large">
			<div className="news-drawer">
				<Form form={form} onValuesChange={onNewsFormChange}>
					<Row className="allContainer">
						<Col md={24} xl={16} className="ficheContent bg-white">
							<Menu
								mode="horizontal"
								selectedKeys={choosedSidemenuKey}
								theme="Light"
							>
								<Menu.Item key="news">Actualité</Menu.Item>
							</Menu>
							<div className="maincontent">
								<NewsForm
									news={newsFiche}
									addNewsImgTrigger={_onNewsFormChange}
								/>
							</div>
						</Col>
						<Col md={24} xl={7} className="suiviContent bg-white">
							<div className="fixedArea">
								<Comment
									avatar={
										<Avatar
											src={
												LOGGED_USER?.userAvatar
													? LOGGED_USER?.userAvatar
													: userImage
											}
											alt={LOGGED_USER?.userFirstName}
										/>
									}
									content={
										<div>
											<TextArea
												rows={2}
												onChange={handleSuiviChange}
												onPressEnter={handleSuiviSubmit}
												value={suiviValue}
												disabled={
													!newsFiche[
														"data_int[IDNews]"
													]
												}
												placeholder="Appuyez sur 'Entrée' pour poster un commentaire..."
											/>
										</div>
									}
								/>
							</div>
							<div className="scrollIt">
								{newsSuivi.length
									? newsSuivi?.map((suivi, index) => {
											return (
												<Comment
													author={
														<div className="suiviHeader">
															<a>
																{
																	suivi.CreateurDenomination
																}
															</a>
															{suivi.DateCreation}
														</div>
													}
													avatar={
														<Avatar
															src={
																suivi?.Avatar
																	? suivi?.Avatar
																	: userImage
															}
															alt={
																suivi.CreateurDenomination
															}
														/>
													}
													content={
														<p>{suivi.Event}</p>
													}
													key={index}
												/>
											);
									  })
									: null}
							</div>
						</Col>
					</Row>
				</Form>
			</div>
		</Spin>
	);
};

export default NewsDrawer;
