import { createSlice } from "@reduxjs/toolkit";
import { loadDataGridUtil, loadFicheConfigImmoUtil, updateFicheImmoConfigUtil } from "./config.util";
import _ from "lodash";


const initialState = {
    columnNames: [
        { title: "Libellé FR", field: "Libelle_fr" },
        { title: "Libellé EN", field: "Libelle_en" },
    ],
    columnNavNames: [
        { title: "Libellé FR", field: "Libelle_fr" },
        { title: "Libellé EN", field: "Libelle_en" },
        { title: "Navigation/Menu", field: "OnlineMenu" },
    ],
    columnRubanNames: [
        { title: "Libellé FR", field: "Libelle_fr" },
        { title: "Libellé EN", field: "Libelle_en" },
        { title: "Position", field: "TagSide" },
        { title: "Couleur du fond", field: "BgColor" },
        { title: "Couleur du texte", field: "TextColor" },
    ],
    columnNamesTypicalSentences: [
        { title: "Titre", field: "Phrase_title" },
        { title: "Phrase FR", field: "Phrase_fr" },
        { title: "Phrase EN", field: "Phrase_en" },
    ],
    dataGrid: [],
    dataGridLoader: false,
    selectedImmoConfigRow: {},
    ficheConfigImmo: {},
    ficheConfigImmoForm: {},
    ficheConfigImmoLoader: false,
};

const immoConfig = createSlice({
    name: "immoConfig",
    initialState,
    reducers: {
        loadDataGrid: (state, { payload }) => {
            state.dataGrid = loadDataGridUtil(payload);
        },
        initDatagrid: (state, { payload }) => {
            state.dataGrid = initialState.dataGrid;
        },
        setSelectedSaisonRow: (state, { payload }) => {
            state.selectedSaisonRow = payload;
        },
        setDataGrid: (state, { payload }) => {
            state.dataGrid = payload;
        },
        dataGridLoader: (state, { payload }) => {
            state.dataGridLoader = payload;
        },
        setSlectedImmoConfigRow: (state, { payload }) => {
            state.selectedImmoConfigRow = payload;
        },
        loadFicheConfigImmo: (state, { payload }) => {
            state.ficheConfigImmo = payload;
            state.ficheConfigImmoForm = loadFicheConfigImmoUtil(payload);
        },
        initFicheConfigImmo: (state, { payload }) => {
            state.ficheConfigImmo = initialState.ficheConfigImmo;
            state.ficheConfigImmoForm = initialState.ficheConfigImmoForm;
        },
        ficheConfigImmoLoader: (state, { payload }) => {
            state.ficheConfigImmoLoader = payload;
        },
        updateFicheImmoConfig: (state, { payload }) => {
            let clonedFiche = _.cloneDeep(state.ficheConfigImmoForm);
            state.ficheConfigImmoForm = updateFicheImmoConfigUtil(payload, clonedFiche);
        }
    },
});

export const { loadDataGrid, initDatagrid, setSelectedSaisonRow, setDataGrid, dataGridLoader, setSlectedImmoConfigRow, loadFicheConfigImmo, initFicheConfigImmo, ficheConfigImmoLoader, updateFicheImmoConfig } = immoConfig.actions;

export default immoConfig.reducer;
