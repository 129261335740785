/* -----------Componenets------------ */
/* -----------Assets------------ */
import React from "react";
import Popover from "antd/lib/popover";
import userImage from "../../assets/images/user.png";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../redux/Authentification/authentication.slice";
import { useHistory } from "react-router-dom";
/* -----------Selectors------------ */
import { userDetailsSelector } from "../../redux/Authentification/authentication.selector";
/* -----------Style Sheet------------ */
import "./styles.scss";


const UserMenu = () => {
  /* -----------State Hook------------ */
  /* -----------Selectors------------ */
  let LOGGED_USER = useSelector(userDetailsSelector);

  /* -----------Vars------------ */
  const dispatch = useDispatch();
  const history = useHistory();
  /* -----------Functions------------ */
  const handle_logout = () => {
    dispatch(logOut())
    history.push("/login")
  }
  /* -----------UI------------ */
  const userMenuContent = (
    <>
      <p className="isdropdowUserMenu">
        {LOGGED_USER.userLastName ? LOGGED_USER.userLastName + " " + LOGGED_USER.userFirstName : "-- --"}
      </p>
      {/* <p
        className="isdropdowUserMenu"
      >
        Editer mon profil
      </p> */}
      <p
        className="isdropdowUserMenu"
        onClick={handle_logout}
      >
        Déconnexion
      </p>
    </>
  );
  /* -----------render------------ */
  return (
    <div className="utils">
      <Popover placement="bottomLeft" content={userMenuContent} trigger="click">
        <div className="userMenu">
          <img src={LOGGED_USER?.userAvatar ? LOGGED_USER?.userAvatar : userImage} alt="user" />
          <span className="online"></span>
        </div>
      </Popover>
    </div>
  );
};

export default UserMenu;
