/* -----------Components------------ */
import HeaderComponent from '../../components/header/component';
/* -----------Assets------------ */
import React from 'react';
/* -----------Style Sheet------------ */
import './styles.scss';


const DashboardContainer = () => {

    /* -----------render------------ */
    return (
        <div className="dashboard">
            <section className="header">
                <HeaderComponent title="Tableau de bord" />
            </section>
            <section className="page_container">
                <div className="main_content">
                </div>
            </section>
        </div>
    )
}

export default DashboardContainer
