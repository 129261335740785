import { DatePicker, Select } from "antd";
import locale from "antd/es/date-picker/locale/fr_FR";
import Col from "antd/lib/col";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Row from "antd/lib/row";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateFicheNews } from "../../../redux/WebSIte/news/news.slice";
import RichTextEditor from "../../rich_text_editor/RichTextEditor";
import NewsPhotoComponent from "../NewsPhotos/NewsPhoto";
import "./NewsForm.scss";

const NewsForm = ({ news, addNewsImgTrigger }) => {
	const dispatch = useDispatch();

	const [editorContent, setEditorContent] = useState();
	const [contentTitle, setContentTitle] = useState();
	const [language, setLanguage] = useState("fr");

	const TE_TITLE_LABELS = {
		en: { label: "Title", key: "Titre" },
		fr: { label: "Titre", key: "Titre" },
	};
	const TE_NEWS_LABELS = { en: "News", fr: "Texte de l'actualité" };

	const DEFAULT_DATE =
		dayjs().add(1, "day").format("YYYY-MM-DD").toString() + " 12:00:00";

	// Date Picker
	useEffect(() => {
		dispatch(
			updateFicheNews({
				...news,
				"data_date[DatePublication]": DEFAULT_DATE,
			})
		);
	}, []);

	const onDateChange = (event) => {
		dispatch(
			updateFicheNews({
				...news,
				"data_date[DatePublication]": moment(event).format(
					"YYYY-MM-DD HH:mm:ss"
				),
			})
		);
	};

	const onAuthorChange = (event) => {
		dispatch(
			updateFicheNews({
				...news,
				"data_str[Author]": event.target.value,
			})
		);
	};

	// Rich Text Editor
	useEffect(() => {
		setEditorContent(news[`data_str[News_${language}]`] || "");
		setContentTitle(news[`data_str[Titre_${language}]`] || "");
	}, [news, language]);

	const onTitleChange = (newTitle) => {
		updateNews(`data_str[Titre_${language}]`, newTitle);
		setContentTitle(newTitle);
	};

	const onEditorContentChange = (newContent) => {
		updateNews(`data_str[News_${language}]`, newContent);
		setEditorContent(newContent);
	};

	const updateNews = (key, value) => {
		dispatch(
			updateFicheNews({
				...news,
				[key]: value,
			})
		);
	};

	return (
		<Row gutter={16} className="news-form">
			<Col md={16} id="content-part">
				<RichTextEditor
					onTextChange={onEditorContentChange}
					onTitleChange={onTitleChange}
					onLanguageChange={setLanguage}
					titleLabels={TE_TITLE_LABELS}
					textLabels={TE_NEWS_LABELS}
					content={editorContent}
					title={contentTitle}
				/>
				<Col md={16}>
					<Form.Item label="Auteur" name="data_str[Author]">
						<Input size="small" onChange={onAuthorChange} />
					</Form.Item>
				</Col>
			</Col>

			<Col md={8}>
				<Form.Item label="Date de publication" valuePropName={"date"}>
					<DatePicker
						defaultValue={moment(
							news["data_date[DatePublication]"] || DEFAULT_DATE
						)}
						onChange={onDateChange}
						format="YYYY-MM-DD HH:mm:ss"
						showTime={true}
						locale={locale}
					/>
				</Form.Item>
				<Form.Item
					initialValue={"draft"}
					label="Statut"
					name="data_str[Statut]"
				>
					<Select
						style={{ width: 120 }}
						options={[
							{ value: "published", label: "Publié" },
							{ value: "draft", label: "Brouillon" },
						]}
					/>
				</Form.Item>
				<NewsPhotoComponent addNewsImgTrigger={addNewsImgTrigger} />
			</Col>
		</Row>
	);
};

export default NewsForm;
